import React, { Component } from 'react'
import { Row, Col, Form, Select, InputNumber, Button, Typography, Card, Table, Tag, PageHeader, Avatar, Statistic, DatePicker, Image, message, Modal } from 'antd';
import { URL, WEB_NAME } from '../../../../Util/Config';
import { TransactionOutlined } from '@ant-design/icons';
import { Column } from '@ant-design/charts';
import Balance from '../balance';
import DateTime from 'node-datetime';
import moment from 'moment';
import axios from 'axios';
import x0p from 'x0popup';

const { Title } = Typography;
const { Meta } = Card;
const { Option } = Select;
const { RangePicker } = DatePicker;

const dateFormat = 'YYYY/MM/DD';
var dateOffset30 = DateTime.create();
dateOffset30.offsetInDays(-30)
var dateStart = dateOffset30.format('Y/m/d');
var dateEnd = DateTime.create().format('Y/m/d');

const DescriptionItem = ({ title, content }) => (
    <div className="site-description-item-profile-wrapper">
        <p className="site-description-item-profile-p-label">{title}:</p>
        {content}
    </div>
);

export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            columns: [
                {
                    title: 'ลำดับ',
                    dataIndex: 'row_num',
                    defaultSortOrder: 'descend',
                    sorter: (a, b) => a.row_num - b.row_num,
                },
                {
                    title: 'บัญชีโอน (ระบบ)',
                    dataIndex: 'income_channel',
                    render: (text, record) => (
                        <Image src={`/images/logo_bank/${text}.png`} fallback="/images/image-not.webp" width={30} style={{ backgroundColor: 'black' }} alt="My Happy SVG" />
                    )
                },
                {
                    title: 'จำนวน',
                    dataIndex: 'income_amount',
                    align: 'right',
                    render: (text, record) => (
                        <Tag color='red'>
                            {text} บาท
                        </Tag>
                    )
                },
                {
                    title: 'หมายเหตุ',
                    dataIndex: 'income_note',
                },
                {
                    title: 'วัน/เวลาที่ทำรายการ',
                    dataIndex: 'created_at',
                },
            ],
            config_chart2: {
                data: [],
                xField: 'datetime',
                yField: 'amount',
                seriesField: 'channel',
                xAxis: {
                    mask: 'DD-MM-YYYY',
                },
                yAxis: {
                    label: {
                        formatter: function formatter(v) {
                            return ''.concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
                                return ''.concat(s, ',');
                            });
                        },
                    },
                },
                isGroup: 'true',
                color:['#522882', '#F58320', '#028740'],
                columnStyle: {
                    radius: [20, 20, 0, 0],
                    padding: 0
                },
            },
            summary: {
                scb: {
                    amount: 0
                },
                kbank: {
                    amount: 0
                },
                truewallet: {
                    amount: 0
                },
            },
            bankMiddle: {},
            visableModalBank: false,
            visableModalTransfer: false,
            fields: {},
            errors: [],
            form_transfer: {
                auto: 1,
                amount: 0,
                channel: 'SCB'
            },
            BankAuto: []
        };
    }

    componentDidMount() {
        const user = JSON.parse(localStorage.getItem('user'))
        this.setState({ admin_type: user.user.type, admin_token: user.accessToken, admin_id: user.user.id, admin_name: user.user.name })

        this.getAutoBank()

        this.setData(dateStart, dateEnd)
        this.getAccountMiddle()
        this.getBank()
    }

    setData(startDate, endDate) {
        var dt = DateTime.create(startDate);
        startDate = dt.format('Y-m-d');
        dt = DateTime.create(endDate);
        endDate = dt.format('Y-m-d');

        this.getData(startDate, endDate)
    }

    getAccountMiddle() {
        fetch(`${URL}/api/v1/bank/middle`)
            .then((response) => response.json())
            .then((json) => this.setState({ bankMiddle: json.result }))
            // .then(() => console.log(this.state.summary))
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    }

    getData(startDate, endDate) {
        fetch(`${URL}/api/v1/dashboard/income/start/${startDate}/end/${endDate}`)
            .then((response) => response.json())
            .then((json) => this.setState({ data: json.result.data, config_chart2: { ...this.state.config_chart2, data: json.result.chart }, summary: json.result.summary }))
            // .then(() => console.log(this.state.summary))
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    }

    // GET BANK ดึงข้อมูลธนาคาร
    async getBank() {
        await axios.get(URL + "/api/v1/bank/all")
            .then(res => {
                const data = res.data
                if (data.status) {
                    this.setState({ banklist: data.result })
                    this.handleChange(data.result[0].bank_id, "bankid")
                } else {
                    // alert(data.status + " : " + data.message)
                    console.log('ข้อมูลไม่ถูกต้อง getBank')
                    console.log(data)
                }
            })
            .catch(error => {
                console.log(error);
                message.error('การเชื่อมต่อผิดพลาด Get Bank!!');
            })
    }

    async getAutoBank() {
        fetch(`${URL}/api/v1/scb/account/receive`, {
          headers: {
            'Authorization': `Bearer ${this.state.admin_token}`,
          }
        })
          .then((response) => response.json())
          .then((json) => {
            if (json.status) {
              this.setState({ BankAuto: json.result })
            }
          })
          .catch((error) => {
            console.log('getAccSCBBalance', error);
          });
    }

    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        //account bank
        if (!fields["banknumber"] && fields["banknumber"] !== '') {
            formIsValid = false;
            errors["banknumber"] = "กรุณากรอกข้อมูลเลขบัญชีธนาคาร";
        }

        if (typeof fields["banknumber"] !== "undefined" && fields["banknumber"] !== '') {
            if (!fields["banknumber"].match(/\d+/)) {
                formIsValid = false;
                errors["banknumber"] = "กรุณากรอกเลขบัญชีธนาคารให้ถูกต้อง กรอกได้เฉพาะตัวเลข (0-9) เท่านั้น";
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    contactSubmit() {
        if (this.handleValidation()) {
            this.setState({ loading1: true })
            // console.log('success')
            this.postUpdateAccount()
        } else {
            message.error('กรุณาตรวจสอบข้อมูลให้ถูกต้อง!!');
        }
    }

    handleChange(value, field) {
        let fields = this.state.fields;
        fields[field] = value;
        this.setState({ fields });
    }

    // Select รายชื่อธนาคาร
    bankList() {
        const bankList = this.state.banklist
        let list = bankList.map((item, index) =>
            <option key={index} value={item.bank_id}>{item.bank_name_th}</option>
        );
        return (list)
    }

    async postUpdateAccount() {
        const fields = this.state.fields
        const body = {
            bank_id: fields["bankid"],
            bank_number: fields["banknumber"],
        }
        await axios.post(URL + "/api/v1/bank/middle/update", body, {
            headers: {
                'Authorization': `Bearer ${this.state.admin_token}`,
            }
        }).then(res => {
            var data = res.data
            if (data.status) {
                this.getAccountMiddle()
                message.success('ทำรายการเสร็จสิ้น!!');
            } else {
                message.error(data.message);
            }
        }).catch(error => {
            console.log(error)
            console.log('การเชื่อมต่อผิดพลาด Update')
            this.setState({ loading1: false })
        })
    }

    // โอนเงินเข้าบัญชีกลาง
    async postTransfer() {
        const { auto, amount, channel } = this.state.form_transfer
        if (amount < 1) return message.error('กรุณาระบุจำนวนเงินใหม่');

        let title = 'ยืนยัน'
        let text = `ต้องการโอนเงินจาก ${channel} จำนวน ${amount}บาท`

        if (channel === 'TWL') {
            title = ''
            text = `True Wallet\nเงินเข้าบัญชีผู้รับภายใน 2 ชั่วโมง\nระหว่างเวลาทำการ 2:00-23:00 น ทุกวัน และมีค่าธรรมเนียม 20 บาท\nจำนวนโอน ${amount}บาท`
        }

        x0p('ยืนยันรหัสผ่าน', null, 'input')
            .then(async (alertInput) => {
            x0p({
                title: title,
                text: text,
                icon: 'info',
                buttons: [
                    {
                        type: 'cancel',
                        text: 'ยกเลิก',
                    },
                    {
                        type: 'info',
                        text: 'ยืนยัน',
                        showLoading: true
                    }
                ]
            }).then(async (alert) => {
                if (alert.button === 'info') {
                    const body = {
                        amount,
                        channel,
                        password: alertInput.text
                    }
                    await axios.post(URL + `/api/v1/bank/middle/transfer/id/${auto}`, body, {
                        headers: {
                            'Authorization': `Bearer ${this.state.admin_token}`,
                        }
                    }).then(res => {
                        var data = res.data
                        if (data.status) {
                            this.setData(dateStart, dateEnd)
                            x0p(data.message, null, 'ok', false);
                            this.setState({ visableModalTransfer: false })
                        } else {
                            x0p('', data.message, 'error', false);
                        }
                    }).catch(error => {
                        x0p('การเชื่อมต่อผิดพลาด middle/transfer', null, 'error', false);
                    })
                }
            })
        })

    }

    // Select รายชื่อธนาคาร
    bankList2() {
        let list = this.state.BankAuto.map((item, index) =>
            <option key={index} value={item.bank_auto_id} >{item.accountName} ({item.accountNo})</option>
        );
        return (list)
    }

    render() {
        const routes = [
            {
                breadcrumbName: WEB_NAME,
            },
            {
                breadcrumbName: 'สรุปรายได้',
            },
        ]
        return (
            <>
                <PageHeader
                    className="site-page-header"
                    title="สรุปรายได้"
                    breadcrumb={{ routes }}
                // subTitle="This is a subtitle"
                />

                <Balance />
                
                <br />
                <Row gutter={[16, 16]} className="align-items-end">
                    {/* <Col span={12}>
                        <Card>
                            <Title level={5}>บัญชีระบบ</Title>
                            <Row>
                                <Col span={4}>
                                    <Image
                                        width={50}
                                        src="/images/logo_bank/SCB.png"
                                    />
                                </Col>
                                <Col span={20}>
                                    <Row>
                                        <Col span={12}>
                                            <DescriptionItem title="ธนาคาร" content="ไทยพาณิชย์" />
                                        </Col>
                                        <Col span={12}>
                                            <DescriptionItem title="เลขบัญชี" content={``} />
                                        </Col>
                                        <Col span={24}>
                                            <DescriptionItem title="ชื่อบัญชี" content={``} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col span={4}>
                                    <Image
                                        width={50}
                                        src="/images/logo_bank/KBANK.png"
                                    />
                                </Col>
                                <Col span={20}>
                                    <Row>
                                        <Col span={12}>
                                            <DescriptionItem title="ธนาคาร" content="กสิกรไทย" />
                                        </Col>
                                        <Col span={12}>
                                            <DescriptionItem title="เลขบัญชี" content={``} />
                                        </Col>
                                        <Col span={24}>
                                            <DescriptionItem title="ชื่อบัญชี" content={``} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col span={4}>
                                    <Image
                                        width={50}
                                        src="/images/logo_bank/TWL.png"
                                    />
                                </Col>
                                <Col span={20}>
                                    <Row>
                                        <Col span={12}>
                                            <DescriptionItem title="เบอร์" content={``} />
                                        </Col>
                                        <Col span={24}>
                                            <DescriptionItem title="ชื่อ" content={``} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                    </Col> */}
                    <Col span={12}>
                        <Card>
                            <Title level={5}>บัญชีกลาง</Title>
                            <Row>
                                <Col span={4}>
                                    <Image
                                        width={50}
                                        src={`/images/logo_bank/${this.state.bankMiddle.bank_abbrev_en}.png`}
                                    />
                                </Col>
                                <Col span={20}>
                                    <Row>
                                        <Col span={12}>
                                            <DescriptionItem title="ธนาคาร" content={this.state.bankMiddle.bank_abbrev_th} />
                                        </Col>
                                        <Col span={12}>
                                            <DescriptionItem title="เลขบัญชี" content={this.state.bankMiddle.bank_auto_number} />
                                        </Col>
                                        <Col span={24}>
                                            <DescriptionItem title="ชื่อบัญชี" content={this.state.bankMiddle.bank_auto_account} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <br />
                            <Button type='primary' ghost block onClick={() => this.setState({ visableModalBank: true })} disabled={this.state.admin_type === 'M' ? false : true}>แก้ไขบัญชี</Button>
                            <br />
                            <br />
                            <Button type='primary' block icon={<TransactionOutlined />} onClick={() => this.setState({ visableModalTransfer: true })}>โอนเงินเข้าบัญชีกลาง</Button>
                        </Card>
                    </Col>
                    <Col span={12}>

                    </Col>
                </Row>
                <br />

                <Card>
                    <RangePicker
                        style={{ width: '100%', marginBottom: 10, marginTop: 10 }}
                        defaultValue={[moment(dateStart, dateFormat), moment(dateEnd, dateFormat)]}
                        format={dateFormat}
                        onChange={(e) => this.setData(e[0]._d, e[1]._d)} />

                    <Column {...this.state.config_chart2} />
                    <br />
                    <Row gutter={[16, 16]}>
                        <Col span={6}>
                            <Card loading={this.state.loading} style={{ maxHeight: 103.71 }}>
                                <Meta
                                    description={<Statistic
                                        title="ยอดโอนรวม"
                                        value={this.state.summary.scb.amount + this.state.summary.kbank.amount + this.state.summary.truewallet.amount}
                                        valueStyle={{ color: '#1B91FF' }}
                                        suffix={'บาท'}
                                    />}
                                />
                            </Card>
                        </Col>

                        <Col span={6}>
                            <Card>
                                <Meta
                                    avatar={<Avatar src="/images/logo_bank/SCB.png" style={{ width: 50, height: 50 }} />}
                                    // title="Card title"
                                    description={<Statistic
                                        title="ยอดโอน SCB"
                                        value={this.state.summary.scb.amount}
                                        valueStyle={{ color: "#522882" }}
                                        suffix={'บาท'}
                                    />}
                                />
                            </Card>
                        </Col>

                        <Col span={6}>
                            <Card>
                                <Meta
                                    avatar={<Avatar src="/images/logo_bank/KBANK.png" style={{ width: 50, height: 50 }} />}
                                    // title="Card title"
                                    description={<Statistic
                                        title="ยอดโอน KBANK"
                                        value={this.state.summary.kbank.amount}
                                        valueStyle={{ color: "#028740" }}
                                        suffix={'บาท'}
                                    />}
                                />
                            </Card>
                        </Col>

                        <Col span={6}>
                            <Card>
                                <Meta
                                    avatar={<Avatar src="/images/logo_bank/TWL.png" style={{ width: 50, height: 50 }} />}
                                    // title="Card title"
                                    description={<Statistic
                                        title="ยอดโอน True Wallet"
                                        value={this.state.summary.truewallet.amount}
                                        valueStyle={{ color: "#F58320" }}
                                        suffix={'บาท'}
                                    />}
                                />
                            </Card>
                        </Col>
                    </Row>
                    <br />

                    <Title level={5}>ประวัติการโอนเงินเข้าบัญชีกลาง</Title>
                    <Table dataSource={this.state.data} columns={this.state.columns} pagination={{ pageSize: 20 }} scroll={{ x: 800 }} />
                </Card>

                {/* แก้ไขบัญชีธนาคาร */}
                {this.state.visableModalBank ?
                    <Modal
                        title="แก้ไขข้อมูลบัญชีธนาคาร"
                        open={this.state.visableModalBank}
                        onCancel={() => this.setState({ visableModalBank: false })}
                        footer={[
                            <Button key="back" onClick={() => this.setState({ visableModalBank: false })}>
                                ยกเลิก
                            </Button>,
                            <Button key="submit" type="primary" onClick={(e) => this.contactSubmit()}>
                                ยืนยันแก้ไข
                            </Button>,
                        ]}
                    >
                        <div className="col-12">
                            <label for="banknumber" className="form-label">ธนาคาร</label>
                            <select className="form-select" aria-label="Default select example" name="bank_id" onChange={(e) => this.handleChange(e.target.value, "bankid")}>
                                {this.bankList()}
                            </select>
                        </div>

                        <div className="col-12" style={{ marginTop: 10 }}>
                            <label for="banknumber" className="form-label">เลขบัญชีธนาคาร</label>
                            <div className="input-group has-validation">
                                <span className="input-group-text"><i className="material-icons">account_balance</i></span>
                                <input type="text" className="form-control" pattern="[0-9]" id="banknumber" name="banknumber" onKeyUp={(e) => this.handleChange(e.target.value, "banknumber")} />
                                <div className="invalid-feedback">
                                    กรุณากรอกเลขบัญชีธนาคารให้ถูกต้อง
                                </div>
                            </div>
                            <div className="error form-text text-danger">{this.state.errors["banknumber"]}</div>
                        </div>
                    </Modal>
                    : null
                }

                {/* โอนเข้าบัญชีกลาง */}
                { this.state.visableModalTransfer ?
                    <Modal
                        title="โอนเงินเข้าบัญชีกลาง"
                        open={this.state.visableModalTransfer}
                        onCancel={() => this.setState({ visableModalTransfer: false })}
                        onOk={() => this.postTransfer()}
                    >
                        <Form layout={'vertical'}>
                            <Form.Item
                                label="เลือกบัญชีโอน"
                                name="auto"
                                rules={[{ required: true, message: 'กรุณาระบุจำนวนเงิน!' }]}
                            >
                                <Select defaultValue={this.state.form_transfer.auto} style={{ width: '100%' }} onChange={(value) => this.setState({ form_transfer: { ...this.state.form_transfer, auto: value } })} allowClear>
                                    {this.bankList2()}
                                </Select>
                            </Form.Item>  
                            
                            <Form.Item
                                label="เลือกบัญชีในระบบ"
                                name="bank"
                                rules={[{ required: true, message: 'กรุณาระบุจำนวนเงิน!' }]}
                            >
                                <Select defaultValue={this.state.form_transfer.channel} style={{ width: '100%' }} onChange={(value) => this.setState({ form_transfer: { ...this.state.form_transfer, channel: value } })} allowClear>
                                    <Option value="SCB">SCB</Option>
                                    <Option value="KBank">KBank</Option>
                                    <Option value="TWL">True Wallet</Option>
                                </Select>
                            </Form.Item>

                            <Form.Item label="ระบุจำนวนเงิน" name="amount" rules={[{ required: true, message: 'กรุณาระบุจำนวนเงิน!' }]}>
                                <InputNumber addonAfter={'บาท'} style={{ width: '100%' }} onChange={(value) => this.setState({ form_transfer: { ...this.state.form_transfer, amount: value } })} />
                            </Form.Item>
                        </Form>
                    </Modal>
                    : <></>
                }
            </>
        )
    }
}
