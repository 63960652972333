import React, { Component } from 'react'
import { Statistic, Card, Row, Col, Avatar, Button, Modal, Image, Tag, Divider, Spin } from 'antd';
import { URL } from '../../../Util/Config';
import x0p from 'x0popup';

const { Meta } = Card;

const DescriptionItem = ({ title, content }) => (
  <div className="site-description-item-profile-wrapper">
    <p className="site-description-item-profile-p-label">{title}:</p>
    {content}
  </div>
);

export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scbBalance: 0,
            kbankBalance: 0,
            truewalletBalance: 0,
            agentBalance: 0,
            agentFiverBalance: 0,
            agentPgBalance: 0,
            loading: false,
            bankAuto: [],
            isModalSCB: false,
            accountSCB: {},
            isModalKBank: false,
            accountKBank: {},
            accountPayment: {},
            accountPaymentBalance: 0,
        };
    }

    componentDidMount() {
        const user = JSON.parse(localStorage.getItem('user'))
        this.setState({ admin_type: user.user.type, admin_token: user.accessToken, admin_id: user.user.id, admin_name: user.user.name }, () => {
            // this.getAutoBank() // ข้อมูลรายชื่อธนารคาร auto
            // this.getAccSCBBalance() 
            // this.getAccTruewalletBalance()
            // this.getAccKBankBalance()
            this.getAccBalance()
            this.getAccPgBalance()
            // this.getAccFiverBalance()
        })
    }
    
    async getAccSCBBalance() {
      this.setState({ loading: true })
        fetch(`${URL}/api/v1/scb/transaction/detail`, {
          headers: {
            'Authorization': `Bearer ${this.state.admin_token}`,
          }
        })
          .then((response) => response.json())
          .then((json) => {
            if (json.status) {
              this.setState({ accountSCB: json.result, loading: false })
            }
          })
          .catch((error) => {
            console.log('getAccSCBBalance', error);
          });
      }
    
    async getAccSCBBalanceId(id) {
        fetch(`${URL}/api/v1/scb/transaction/detail/id/${id}`, {
          headers: {
            'Authorization': `Bearer ${this.state.admin_token}`,
          }
        })
          .then((response) => response.json())
          .then((json) => {
            if (json.status) {
              x0p('ยอดคงเหลือ', json.result.accountBalance, 'ok', false);
            } else {
              x0p('', json.message, 'error', false);
            }
          })
          .catch((error) => {
            console.log('getAccSCBBalance', error);
          });
    }

    async getAccKBankBalance(id) {
      this.setState({ loading: true })
        fetch(`${URL}/api/v1/kbank/kbiz/transaction/detail/id/${id}`, {
          headers: {
            'Authorization': `Bearer ${this.state.admin_token}`,
          }
        })
        .then((response) => response.json())
        .then((json) => {
          if (json.status) {
            this.setState({ accountKBank: json.result, loading: false })
          }
        })
        .catch((error) => {
            console.log('getAccKBankBalance', error);
        });
    }
    
    async getAccPaymentBalance() {
      this.setState({ loading: true })
      fetch(`${URL}/api/v1/payment/balance`, {
        headers: {
          'Authorization': `Bearer ${this.state.admin_token}`,
        }
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status) {
            console.log(json)
            this.setState({ accountPayment: json.result[0], accountPaymentBalance: json.balance, loading: false })
          }
        })
        .catch((error) => {
          console.log('getAccSCBBalance', error);
        });
    }

    async getAccTruewalletBalance() {
        fetch(`${URL}/api/v1/truewallet/balance`, {
          headers: {
            'Authorization': `Bearer ${this.state.admin_token}`,
          }
        })
        .then((response) => response.json())
        .then((json) => {
            if (json.status) {
              this.setState({ truewalletBalance: json.result.accountBalance })
            }
        })
        .catch((error) => {
            console.log('getAccTruewalletBalance', error);
        });
    }

    async getAccBalance() {
        fetch(`${URL}/api/v1/agent/check/wallet`, {
          headers: {
            'Authorization': `Bearer ${this.state.admin_token}`,
          }
        })
          .then((response) => response.json())
          .then((json) => {
            this.setState({ agentBalance: json.result })
          })
          .catch((error) => {
            console.log('getAccBalance', error);
          });
    }
    
    async getAccPgBalance() {
        fetch(`${URL}/api/v1/agent-pg/check/wallet`, {
          headers: {
            'Authorization': `Bearer ${this.state.admin_token}`,
          }
        })
          .then((response) => response.json())
          .then((json) => {
            this.setState({ agentPgBalance: json.result })
          })
          // .then(() => console.log(this.state.summaryMonth))
          .catch((error) => {
            console.log('getAccPgBalance', error);
          });
    }
    
    async getAccFiverBalance() {
        fetch(`${URL}/api/v1/agent-fiver/check/wallet`, {
          headers: {
            'Authorization': `Bearer ${this.state.admin_token}`,
          }
        })
          .then((response) => response.json())
          .then((json) => {
            this.setState({ agentFiverBalance: json.result })
          })
          // .then(() => console.log(this.state.summaryMonth))
          .catch((error) => {
            console.log('getAccFiverBalance', error);
          });
    }

    async getAutoBank() {
      fetch(`${URL}/api/v1/scb/account/receive`, {
        headers: {
          'Authorization': `Bearer ${this.state.admin_token}`,
        }
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status) {
            this.setState({ BankAuto: json.result })
          }
        })
        .catch((error) => {
          console.log('getAccSCBBalance', error);
        });
    }

    async getDataSCB() {
      // await this.getAutoBank()
      this.setState({ isModalSCB: true })
      await this.getAccSCBBalance()
    }

    async getDataKBank() {
      this.setState({ isModalKBank: true })
      await this.getAccKBankBalance(2)
    }

    async getDataPayment() {
      this.setState({ isModalPayment: true })
      await this.getAccPaymentBalance()
    }

    render() {
        return (
            <>
                <Row gutter={[16, 16]}>
                    <Col span={8}>
                        <Card loading={this.state.loading} style={{ maxHeight: 103.71 }}>
                            <Row className='justify-content-between'>
                                <Col>
                                    <Meta
                                        avatar={<Avatar src="/images/logo_bank/SCB.png" style={{ width: 50, height: 50 }} />}
                                        // title="Card title"
                                        description={<>
                                          <p>ยอดเงินในบัญชีธนาคาร SCB</p>
                                          <Button type='primary' ghost onClick={() => {
                                            this.getDataSCB()
                                            // this.setState({  })
                                          }}>
                                            ตรวจสอบยอด
                                          </Button>
                                        </>}
                                    />
                                </Col>
                                {/* <Col className='text-right'>
                                    <Tooltip placement="top" title='ตรวจสอบยอด'>
                                        <h3>
                                            <Button
                                                type="link"
                                                // icon={<ReloadOutlined />}
                                                size='large'
                                                loading={this.state.loading}
                                                // onClick={() => this.updateSCBBalance()}
                                            />
                                        </h3>
                                    </Tooltip>
                                </Col> */}
                            </Row>

                        </Card>
                    </Col>

                    <Col span={8}>
                        <Card loading={this.state.loading}>
                            <Meta
                                avatar={<Avatar src="/images/logo_bank/KBANK.png" style={{ width: 50, height: 50 }} />}
                                // title="Card title"
                                description={<>
                                  <p>ยอดเงินในบัญชีธนาคาร KBANK</p>
                                  <Button type='primary' ghost onClick={() => {
                                    this.getDataKBank()
                                  }}>
                                    ตรวจสอบยอด
                                  </Button>
                                </>}
                            />
                        </Card>
                    </Col>

                    <Col span={8}>
                        <Card loading={this.state.loading}>
                            <Meta
                                avatar={<Avatar src="/images/logo_bank/payment.png" style={{ width: 50, height: 50 }} />}
                                // title="Card title"
                                description={<>
                                  <p>ยอดเงินในบัญชีธนาคาร Payment</p>
                                  <Button type='primary' ghost onClick={() => {
                                    this.getDataPayment()
                                  }}>
                                    ตรวจสอบยอด
                                  </Button>
                                </>}
                            />
                        </Card>
                    </Col>

                    {/* <Col span={6}>
                        <Card>
                            <Meta
                                avatar={<Avatar src="/images/logo_bank/TWL.png" style={{ width: 50, height: 50 }} />}
                                // title="Card title"
                                description={<Statistic
                                    title="ยอดเงินใน True Wallet"
                                    value={this.state.truewalletBalance ? this.state.truewalletBalance : 'ไม่สามารถตรวจสอบได้'}
                                    valueStyle={{ color: this.state.truewalletBalance ? '#ED7F1F' : '#BC0104' }}
                                    suffix={this.state.truewalletBalance ? 'บาท' : ''}
                                />}
                            />
                        </Card>
                    </Col> */}
                </Row>
                <br/>
                <Row gutter={[16, 16]}>
                    <Col span={8}>
                        <Card>
                            <Meta
                                avatar={<Avatar src="/images/betflix-logo.png" style={{ width: 50, height: 50 }} />}
                                // title="Card title"
                                description={<Statistic
                                    title="ยอดเครดิตใน Betflix"
                                    value={this.state.agentBalance ? this.state.agentBalance : 'ไม่สามารถตรวจสอบได้'}
                                    valueStyle={{ color: this.state.agentBalance ? '#1A91FF' : '#BC0104' }}
                                    suffix={this.state.agentBalance ? 'บาท' : ''}
                                />}
                            />
                        </Card>
                    </Col>

                    <Col span={8}>
                        <Card>
                            <Meta
                                avatar={<Avatar src="/images/pg-logo.png" style={{ width: 50, height: 50 }} />}
                                // title="Card title"
                                description={<Statistic
                                    title="ยอดเครดิตใน PG"
                                    value={this.state.agentPgBalance ? this.state.agentPgBalance : 'ไม่สามารถตรวจสอบได้'}
                                    valueStyle={{ color: this.state.agentPgBalance ? '#1A91FF' : '#BC0104' }}
                                    suffix={this.state.agentPgBalance ? 'บาท' : ''}
                                />}
                            />
                        </Card>
                    </Col>

                    {/* <Col span={6}>
                        <Card>
                            <Meta
                                avatar={<Avatar src="/images/fiver-logo.png" style={{ width: 50, height: 50 }} />}
                                // title="Card title"
                                description={<Statistic
                                    title="ยอดเครดิตใน Fiver"
                                    value={this.state.agentFiverBalance ? this.state.agentFiverBalance : 'ไม่สามารถตรวจสอบได้'}
                                    valueStyle={{ color: this.state.agentFiverBalance ? '#1A91FF' : '#BC0104' }}
                                    suffix={this.state.agentFiverBalance ? 'บาท' : ''}
                                />}
                            />
                        </Card>
                    </Col> */}
                </Row>

                { this.state.isModalSCB ? 
                  <Modal title="บัญชีธนาคาร AUTO SCB" open={this.state.isModalSCB} footer={[]} onCancel={() => this.setState({ isModalSCB: false })}>
                    <Spin spinning={this.state.loading} tip="Loading...">
                      {/* {this.state.bankAuto.map((item, index) => ( */}
                        <>
                          <Image
                            width={50}
                            src="/images/logo_bank/SCB.png"
                          />
                          <Row>
                            <Col span={24}>
                              <DescriptionItem title="สถานะ" content={this.state.accountSCB.accountNo ? <Tag color="green">ปกติ</Tag> : <Tag color="red">ไม่สามารถตรวจสอบได้</Tag>} />
                            </Col>
                          </Row>
                          <Row>
                            <Col span={12}>
                              <DescriptionItem title="ธนาคาร" content="ไทยพาณิช" />
                            </Col>
                            <Col span={12}>
                              <DescriptionItem title="เลขบัญชี" content={this.state.accountSCB.accountNo} />
                            </Col>
                          </Row>
                          <Row>
                            <Col span={24}>
                              <DescriptionItem title="ชื่อบัญชี" content={this.state.accountSCB.accountName} />
                            </Col>
                          </Row>
                          <Row>
                            <Col span={24}>
                              <DescriptionItem title="ยอดเงินในบัญชี" content={<Tag>{this.state.accountSCB.accountBalance}</Tag>} />
                            </Col>
                          </Row>
                          <Divider />
                        </>
                      {/* ))} */}
                    </Spin>
                  </Modal>
                : <></>}

                { this.state.isModalKBank ?
                  <Modal title="บัญชีธนาคาร AUTO KBank" open={this.state.isModalKBank} footer={[]} onCancel={() => this.setState({ isModalKBank: false })}>
                    <Spin spinning={this.state.loading} tip="Loading...">
                        <>
                          <Image
                            width={50}
                            src="/images/logo_bank/KBANK.png"
                          />
                          <Row>
                            <Col span={24}>
                              <DescriptionItem title="สถานะ" content={this.state.accountKBank.accountNo ? <Tag color="green">ปกติ</Tag> : <Tag color="red">ไม่สามารถตรวจสอบได้</Tag>} />
                            </Col>
                          </Row>
                          <Row>
                            <Col span={12}>
                              <DescriptionItem title="ธนาคาร" content="กสิกรไทย" />
                            </Col>
                            <Col span={12}>
                              <DescriptionItem title="เลขบัญชี" content={this.state.accountKBank.accountNo} />
                            </Col>
                          </Row>
                          <Row>
                            <Col span={24}>
                              <DescriptionItem title="ชื่อบัญชี" content={this.state.accountKBank.accountName} />
                            </Col>
                          </Row>
                          <Row>
                            <Col span={24}>
                              <DescriptionItem title="ยอดเงินในบัญชี" content={<Tag>{this.state.accountKBank.accountBalance}</Tag>} />
                            </Col>
                          </Row>
                          <Divider />
                        </>
                    </Spin>
                  </Modal>
                  : <></>
                }

                { this.state.isModalPayment ?
                  <Modal title="บัญชีธนาคาร Payment" open={this.state.isModalPayment} footer={[]} onCancel={() => this.setState({ isModalPayment: false })}>
                    <Spin spinning={this.state.loading} tip="Loading...">
                        <>
                          <Image
                            width={50}
                            src="/images/logo_bank/payment.png"
                          />
                          <Row>
                            <Col span={12}>
                              <DescriptionItem title="ธนาคาร" content={this.state.accountPayment.bank_name} />
                            </Col>
                            <Col span={12}>
                              <DescriptionItem title="เลขบัญชี" content={this.state.accountPayment.account_no} />
                            </Col>
                          </Row>
                          <Row>
                            <Col span={24}>
                              <DescriptionItem title="ชื่อบัญชี" content={this.state.accountPayment.account_name} />
                            </Col>
                          </Row>
                          <Row>
                            <Col span={24}>
                              <DescriptionItem title="ยอดเงินในบัญชี" content={<Tag>{this.state.accountPaymentBalance}</Tag>} />
                            </Col>
                          </Row>
                          <Divider />
                        </>
                    </Spin>
                  </Modal>
                  : <></>
                }
            </>
        )
    }
}
