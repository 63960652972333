import React, { Component } from 'react';
import { Card, Row, Col, PageHeader, Divider, Typography, Switch, Form, Input, InputNumber, Button, Radio, Image, message, Steps, Modal, Table, Select, Checkbox } from 'antd';
import { URL, WEB_NAME } from '../../../Util/Config';
import { SaveOutlined, FileSearchOutlined, EditOutlined } from '@ant-design/icons';
import axios from 'axios';
import x0p from 'x0popup';

const { Step } = Steps;
const { Text, Title } = Typography;
const { Option } = Select;

const DescriptionItem = ({ title, content }) => (
    <div className="site-description-item-profile-wrapper">
        <p className="site-description-item-profile-p-label">{title}:</p>
        {content}
    </div>
);

export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            configGeneral: {},
            configTruewallet: {},
            configBankAuto: [
                {
                    bank_auto_account: "",
                    bank_auto_number: "",
                    bank_auto_status: "",
                    bank_auto_status_deposit: "",
                    bank_auto_status_withdraw: ""
                },
                {
                    bank_auto_account: "",
                    bank_auto_number: "",
                    bank_auto_status: "",
                    bank_auto_status_deposit: "",
                    bank_auto_status_withdraw: ""
                },
            ],
            visableModalAddBank: false,
            visableModalImageColor: false,
            bankList: [],
            FormAddBank: {},
            columnsBankReserve: [
                {
                    title: 'ลำดับ',
                    dataIndex: 'row_num',
                    render: (text, record) => (
                        <div>{text} <Text code><small>{record.bank_id}</small></Text></div>
                    )
                },
                {
                    title: 'ธนาคาร',
                    dataIndex: 'bank_abbrev_en',
                    render: (text, record) => (
                        <Image src={`/images/logo_bank/${text}.png`} fallback="/images/image-not.webp" width={30} style={{ backgroundColor: 'black' }} alt="My Happy SVG" />
                    )
                },
                {
                    title: 'ชื่อบัญชี',
                    dataIndex: 'config_bank_account',
                },
                {
                    title: 'เลขที่บัญชี',
                    dataIndex: 'config_bank_number',
                },
                {
                    title: '',
                    dataIndex: '',
                    width: 280,
                    render: (text, record) => (
                        <>
                            <Button danger onClick={() => this.postDelBank(record.config_bank_id)} icon={<FileSearchOutlined />}>ลบบัญชีนี้</Button>
                        </>
                    )
                }
            ],
            dataBankReserve: [],
            current: 0,
            stepLineChannel: false,
            admin_type: null,
            admin_token: null,
            DepositAuto: [],
            WithdrawAuto: [],
            TrueWalletDepositAuto: [],
            TrueWalletWithdrawAuto: [],
            BankAuto: [],
            BankKBizAuto: {}
        };
    }

    componentDidMount() {
        const user = JSON.parse(localStorage.getItem('user'))
        this.setState({ admin_type: user.user.type, admin_token: user.accessToken })

        this.getGeneralConfig()
        this.getTruewalletConfig()
        this.getAutoBank()
        this.getAutoBankKbiz()
        this.getDataBank()
        this.getBankReserve()
    }

    async getAutoBank() {
        fetch(`${URL}/api/v1/scb/account/receive`, {
          headers: {
            'Authorization': `Bearer ${this.state.admin_token}`,
          }
        })
          .then((response) => response.json())
          .then((json) => {
            if (json.status) {
              this.setState({ BankAuto: json.result })
            }
          })
          .catch((error) => {
            console.log('getAutoBank', error);
          });
    }

    async getAutoBankKbiz() {
        fetch(`${URL}/api/v1/kbank/account/receive`, {
            headers: {
              'Authorization': `Bearer ${this.state.admin_token}`,
            }
          })
            .then((response) => response.json())
            .then((json) => {
                // console.log(json)
              if (json.status) {
                this.setState({ BankKBizAuto: json.result })
              }
            })
            .catch((error) => {
              console.log('getAutoBankKbiz', error);
            });
    }

    // ตั้งค่าทั่วไป
    getGeneralConfig() {
        fetch(`${URL}/api/v1/config/general`)
            .then((response) => response.json())
            .then((json) => this.setState({
                configGeneral: json.result,
            }))
            // .then(() => console.log(this.state.configGeneral))
            .catch((error) => {
                console.log('getTranferConfig', error);
            });
    }

    // ข้อมูลทรูวอลเล็ต
    getTruewalletConfig() {
        fetch(`${URL}/api/v1/truewallet/detail`)
            .then((response) => response.json())
            .then((json) => {
                this.setState({ configTruewallet: json.result })
                if (json.result.truewallet_status_deposit === 'เปิด') {
                    this.state.TrueWalletDepositAuto.push(json.result.truewallet_status_deposit)
                }
                if (json.result.truewallet_status_withdraw === 'เปิด') {
                    this.state.TrueWalletWithdrawAuto.push(json.result.truewallet_status_withdraw)
                }
            })
            // .then(() => console.log(this.state.configTruewallet))
            .catch((error) => {
                console.log('getTruewalletConfig', error);
            });
    }

    // ธนาคาร AUTO
    // getBankAuto() {
    //     fetch(`${URL}/api/v1/config/bankauto`)
    //         .then((response) => response.json())
    //         .then((json) => {
    //             this.setState({ configBankAuto: json.result })
    //             // if (json.result[0].bank_auto_status_deposit === 'เปิด') {
    //             //     this.state.SCBDepositAuto.push(json.result[0].bank_auto_status_deposit)
    //             // }
    //             // if (json.result[0].bank_auto_status_withdraw === 'เปิด') {
    //             //     this.state.SCBWithdrawAuto.push(json.result[0].bank_auto_status_withdraw)
    //             // }

    //             // if (json.result[1].bank_auto_status_deposit === 'เปิด') {
    //             //     this.state.KBankDepositAuto.push(json.result[0].bank_auto_status_deposit)
    //             // }
    //             // if (json.result[1].bank_auto_status_withdraw === 'เปิด') {
    //             //     this.state.KBankWithdrawAuto.push(json.result[0].bank_auto_status_withdraw)
    //             // }
    //         })
    //         .catch((error) => {
    //             console.log('getTruewalletConfig', error);
    //         });
    // }

    // ธนาคารสำรอง
    getBankReserve() {
        fetch(`${URL}/api/v1/config/bankreserve/all`)
            .then((response) => response.json())
            .then((json) => this.setState({ dataBankReserve: json.result }))
            .catch((error) => {
                console.log('getTruewalletConfig', error);
            });
    }

    // รายชื่อธนาคาร
    getDataBank() {
        fetch(`${URL}/api/v1/bank/all`)
            .then((response) => response.json())
            .then((json) => this.setState({ bankList: json.result }))
            .catch((error) => {
                console.log('getTruewalletConfig', error);
            });
    }

    // รายชื่อธนาคาร
    selectBanklist() {
        let list = this.state.bankList.map((item, index) =>
            <Option value={item.bank_id} key={index}>{item.bank_name_th}</Option>
        );
        return (list)
    }

    // เพิ่มธนาคารสำรอง
    postAddBank() {
        const { bank_id, bank_number, bank_account } = this.state.FormAddBank

        if (!bank_id || bank_id === '' || !bank_number || bank_number === '' || !bank_account || bank_account === '') return message.error('กรุณาตรวจสอบข้อมูลอีกครั้ง')

        x0p({
            title: 'ยืนยัน!!',
            text: ``,
            icon: 'info',
            buttons: [
                {
                    type: 'cancel',
                    text: 'ยกเลิก',
                },
                {
                    type: 'info',
                    text: 'ยืนยัน',
                    showLoading: true
                }
            ]
        }).then(async (alert) => {
            if (alert.button === 'info') {
                const body = { bank_id, bank_number, bank_account }
                await axios.post(`${URL}/api/v1/config/bankreserve/add`, body, {
                    headers: {
                        'Authorization': `Bearer ${this.state.admin_token}`,
                    }
                })
                    .then(res => {
                        const data = res.data
                        if (data.status) {
                            this.getBankReserve()
                            this.setState({ visableModalAddBank: false })
                            x0p('สำเร็จ', null, 'ok', false);
                        } else {
                            console.log(data)
                            x0p('', data.message, 'error', false);
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        x0p('เกิดข้อผิดพลาด!!', null, 'error', false);
                    })
            }
        })
    }

    // ลบธนาคารสำรอง
    postDelBank(config_bank_id) {
        x0p({
            title: 'ยืนยัน!!',
            text: ``,
            icon: 'info',
            buttons: [
                {
                    type: 'cancel',
                    text: 'ยกเลิก',
                },
                {
                    type: 'info',
                    text: 'ยืนยัน',
                    showLoading: true
                }
            ]
        }).then(async (alert) => {
            if (alert.button === 'info') {
                const body = { config_bank_id }
                await axios.post(`${URL}/api/v1/config/bankreserve/delete`, body, {
                    headers: {
                        'Authorization': `Bearer ${this.state.admin_token}`,
                    }
                })
                    .then(res => {
                        const data = res.data
                        if (data.status) {
                            this.getBankReserve()
                            this.setState({ visableModalAddBank: false })
                            x0p('สำเร็จ', null, 'ok', false);
                        } else {
                            console.log(data)
                            x0p('', data.message, 'error', false);
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        x0p('เกิดข้อผิดพลาด!!', null, 'error', false);
                    })
            }
        })
    }

    // แก้ไขตั้งค่า ทั่วไป
    postUpdateGeneralConfig() {
        x0p({
            title: 'ยืนยันแก้ไขข้อมูล!!',
            text: `คุณต้องการแก้ไขข้อมูลตั้งค่าฝาก-ถอนใช่มั้ย??`,
            icon: 'info',
            buttons: [
                {
                    type: 'cancel',
                    text: 'ยกเลิก',
                },
                {
                    type: 'info',
                    text: 'ยืนยัน',
                    showLoading: true
                }
            ]
        }).then(async (alert) => {
            if (alert.button === 'info') {
                const { config_min_deposit, config_min_withdraw, config_max_withdraw_auto, config_turnover,
                    config_line_admin, config_line_accesstoken, config_withdraw_all, config_withdraw_amount } = this.state.configGeneral
                const body = {
                    min_deposit: config_min_deposit,
                    min_withdraw: config_min_withdraw,
                    max_withdraw_auto: config_max_withdraw_auto,
                    turnover: config_turnover,
                    withdraw_all: config_withdraw_all,
                    withdraw_amount: config_withdraw_amount,
                    
                    line_admin: config_line_admin,
                    line_accesstoken: config_line_accesstoken,
                }

                await axios.post(`${URL}/api/v1/config/back/update`, body, {
                    headers: {
                        'Authorization': `Bearer ${this.state.admin_token}`,
                    }
                })
                    .then(res => {
                        const data = res.data
                        if (data.status) {
                            this.getGeneralConfig()
                            x0p('สำเร็จ', null, 'ok', false);
                        } else {
                            x0p('', data.message, 'error', false);
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        x0p('เกิดข้อผิดพลาด!!', null, 'error', false);
                    })
            }
        })
    }

    // แก้ไขตั้งค่า Ture wallet
    async postUpdateTruewallet(switchTrue) {
        x0p({
            title: 'ยืนยันแก้ไขข้อมูล!!',
            text: `คุณต้องการแก้ไขข้อมูลตั้งค่าทรูวอลเล็ตมั้ย??`,
            icon: 'info',
            buttons: [
                {
                    type: 'cancel',
                    text: 'ยกเลิก',
                },
                {
                    type: 'info',
                    text: 'ยืนยัน',
                    showLoading: true
                }
            ]
        }).then(async (alert) => {
            if (alert.button === 'info') {
                const body = {
                    phone: this.state.configTruewallet.truewallet_phone,
                    name: this.state.configTruewallet.truewallet_account,
                    min_deposit: this.state.configTruewallet.truewallet_min_deposit,
                    token: this.state.configTruewallet.truewallet_token,
                    turnover: this.state.configTruewallet.truewallet_turnover,
                    status: switchTrue,
                    status_deposit: this.state.TrueWalletDepositAuto[0] === 'เปิด' ? 'เปิด' : 'ปิด',
                    status_withdraw: this.state.TrueWalletWithdrawAuto[0] === 'เปิด' ? 'เปิด' : 'ปิด',
                    channel: this.state.configTruewallet.truewallet_channel
                }
                await axios.post(`${URL}/api/v1/truewallet/update`, body, {
                    headers: {
                        'Authorization': `Bearer ${this.state.admin_token}`,
                    }
                })
                    .then(res => {
                        const data = res.data
                        if (data.status) {
                            this.setState({ configTruewallet: { ...this.state.configTruewallet, truewallet_status: switchTrue } })
                            this.getTruewalletConfig()
                            x0p('สำเร็จ', null, 'ok', false);
                        } else {
                            x0p('ไม่สามารถแก้ไขได้', null, 'error', false);
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        x0p('เกิดข้อผิดพลาด!!', null, 'error', false);
                    })
            }
        })
    }

    // แก้ไข Bank Auto 
    async postUpdateBankAuto(index, id) {
        x0p({
            title: 'ยืนยันแก้ไขข้อมูล!!',
            text: ``,
            icon: 'info',
            buttons: [
                {
                    type: 'cancel',
                    text: 'ยกเลิก',
                },
                {
                    type: 'info',
                    text: 'ยืนยัน',
                    showLoading: true
                }
            ]
        }).then(async (alert) => {
            if (alert.button === 'info') {
                const body = {
                    id: id,
                    auto_deposit: this.state.BankAuto[index].status_deposit,
                    auto_withdraw: this.state.BankAuto[index].status_withdraw,
                }
                
                localStorage.setItem('bankAuto', JSON.stringify(this.state.BankAuto))

                await axios.post(`${URL}/api/v1/config/bankauto/setting`, body, {
                    headers: {
                        'Authorization': `Bearer ${this.state.admin_token}`,
                    }
                })
                    .then(res => {
                        const data = res.data
                        if (data.status) {
                            this.getAutoBank()
                            x0p('สำเร็จ', null, 'ok', false);
                        } else {
                            x0p('ไม่สามารถแก้ไขได้', null, 'error', false);
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        x0p('เกิดข้อผิดพลาด!!', null, 'error', false);
                    })
            }
        })
    }

    async handleMarkComplete(id, type, status) {
        const newS = this.state.BankAuto.map((item) => {
          if (item.bank_auto_id === id) {
            if (type === 'deposit') {
                return {...item, status_deposit: status[0] === 'เปิด' ? 'เปิด' : 'ปิด'};
            } else if (type === 'withdraw') {
                return {...item, status_withdraw: status[0] === 'เปิด' ? 'เปิด' : 'ปิด'};
            }
          }
          return item;
        });

        this.setState({ BankAuto: newS })
    };

    async postEditKBiz() {
        const body = {
            account: this.state.BankKBizAuto.accountName,
            number: this.state.BankKBizAuto.accountNo,
            username: this.state.BankKBizAuto.username,
            password: this.state.BankKBizAuto.password,
            statusDeposit: this.state.BankKBizAuto.status_deposit ? this.state.BankKBizAuto.status_deposit : 'ปิด'
        }

        await axios.post(`${URL}/api/v1/kbank/kbiz/update`, body, {
            headers: {
                'Authorization': `Bearer ${this.state.admin_token}`,
            }
        })
            .then(res => {
                const data = res.data
                if (data.status) {
                    this.getAutoBankKbiz()
                    this.setState({ visableModalKBiz: false })
                    x0p('สำเร็จ', null, 'ok', false);
                } else {
                    x0p('ไม่สามารถแก้ไขได้', null, 'error', false);
                }
            })
            .catch(error => {
                console.log(error)
                x0p('เกิดข้อผิดพลาด!!', null, 'error', false);
            })
    }

    render() {
        const routes = [
            {
                breadcrumbName: WEB_NAME,
            },
            {
                breadcrumbName: 'ตั้งค่า',
            },
            {
                breadcrumbName: 'ตั้งค่าระบบหลังบ้าน',
            },
        ]

        const steps = [
            {
                title: 'Step1',
                content: 'First-content',
            },
            {
                title: 'Step2',
                content: 'Second-content',
            },
            {
                title: 'Step3',
                content: 'Last-content',
            },
            {
                title: 'Step4',
                content: 'Last-content',
            },
            {
                title: 'Step5',
                content: 'Last-content',
            },
        ];

        const optionsBankDeposit = [
            {
                label: 'ระบบฝาก',
                value: 'เปิด',
            },
        ]

        const optionsBankWithdraw = [
            {
                label: 'ระบบถอน',
                value: 'เปิด',
            },
        ]

        return (
            <>
                <PageHeader
                    className="site-page-header"
                    // title="ตั้งค่าระบบหลังบ้าน"
                    breadcrumb={{ routes }}
                // subTitle="This is a subtitle"
                />

                <Row gutter={[16, 16]}>
                    <Col span={21}>
                        <Divider orientation="left"><Title level={4}>ตั้งค่า ระบบหลังบ้าน</Title></Divider>
                    </Col>
                    <Col span={2} style={{ marginTop: 20 }}>
                        <Button type="primary" icon={<SaveOutlined />} onClick={() => this.postUpdateGeneralConfig()} disabled={this.state.admin_type === 'G' ? true : false}> บันทึก</Button>
                    </Col>
                </Row>

                <Card>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Divider orientation="left"><Title level={4}>ตั้งค่าฝาก-ถอน</Title></Divider>
                        </Col>
                    </Row>

                    <Form layout="vertical" size="large">
                        <Row gutter={[16, 16]}>
                            <Col span={6}>
                                <Form.Item label="ฝากขั้นต่ำ">
                                    <InputNumber min={1} max={1000} style={{ width: '100%' }} value={this.state.configGeneral.config_min_deposit} onChange={(value) => this.setState({ configGeneral: { ...this.state.configGeneral, config_min_deposit: value } })} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="ติดเทิร์นโอเวอร์/วัน (เมื่อฝากผ่านธนาคาร)">
                                    <InputNumber min={0} max={10000} style={{ width: '100%' }} value={this.state.configGeneral.config_turnover} onChange={(value) => this.setState({ configGeneral: { ...this.state.configGeneral, config_turnover: value } })} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={6}>
                                <Form.Item label="ถอนขั้นต่ำ">
                                    <InputNumber min={1} max={100000} style={{ width: '100%' }} value={this.state.configGeneral.config_min_withdraw} onChange={(value) => this.setState({ configGeneral: { ...this.state.configGeneral, config_min_withdraw: value } })} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="ถอนออโต้สูงสุด (รองรับแค่ SCB)">
                                    <InputNumber min={0} max={10000} style={{ width: '100%' }} value={this.state.configGeneral.config_max_withdraw_auto} onChange={(value) => this.setState({ configGeneral: { ...this.state.configGeneral, config_max_withdraw_auto: value } })} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="จำนวนครั้งถอน / วัน">
                                    <InputNumber min={1} max={10000} style={{ width: '100%' }} value={this.state.configGeneral.config_withdraw_amount} onChange={(value) => this.setState({ configGeneral: { ...this.state.configGeneral, config_withdraw_amount: value } })} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="บังคับถอนทั้งหมด / ครั้ง">
                                    <Radio.Group onChange={(e) => this.setState({ configGeneral: { ...this.state.configGeneral, config_withdraw_all: (e.target.value).toString() } })} value={parseInt(this.state.configGeneral.config_withdraw_all)}>
                                        <Radio value={0}>บังคับ</Radio>
                                        <Radio value={1}>ไม่บังคับ</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <br />
                    <br />

                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Divider orientation="left"><Title level={4}><Image src='/images/LINE_logo.png' width={40} /> ตั้งค่า Line</Title></Divider>
                        </Col>
                    </Row>

                    <Form layout="vertical" size="large">
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item label="URL Line ตอบกลับลูกค้า ">
                                    <Input value={this.state.configGeneral.config_line_admin} onChange={(e) => this.setState({ configGeneral: { ...this.state.configGeneral, config_line_admin: e.target.value } })} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={<>CHANNEL_ACCESS_TOKEN (Line ตอบกลับลูกค้า) &nbsp;&nbsp;<Text underline mark onClick={() => this.setState({ stepLineChannel: true })}>วิธีสร้าง token คลิก</Text></>}>
                                    <Input value={this.state.configGeneral.config_line_accesstoken} onChange={(e) => this.setState({ configGeneral: { ...this.state.configGeneral, config_line_accesstoken: e.target.value } })} />
                                </Form.Item>
                            </Col>
                        </Row>

                        {this.state.stepLineChannel ?
                            <>
                                <Steps current={this.state.current}>
                                    {steps.map((item) => (
                                        <Step key={item.title} title={item.title} />
                                    ))}
                                </Steps>
                                <div className='text-center'>
                                    <br />
                                    {this.state.current === 0 ?
                                        <>
                                            <p>หลังจากเข้าสู่ระบบ LINE Official Account Manager ไปที่เมนู Settings บริเวณมุมขวามือด้านบน แล้วคลิก Messaging API จากเมนูทางซ้ายมือ หลังจากนั้นคลิก Enable Messaging API</p>
                                            <Image
                                                width={1000}
                                                height={400}
                                                src="https://support.shoplineapp.com/hc/article_attachments/900006615026/screenshot-Connect_with_LINE-2.png"
                                            />
                                        </>
                                        : <></>
                                    }
                                    {this.state.current === 1 ?
                                        <>
                                            <p>เลือก New provider จากนั้นพิมพ์ shopline (ตัวภาษาอังกฤษแบบพิมพ์เล็ก) ในช่อง [Enter provider name] หากคุณมีการสร้าง provider ใน LINE Developers แล้วเมื่อตั้งค่าการเข้าสู่ระบบ LINE คุณสามารถเลือก provider ดังกล่าวได้ จากนั้นคลิก Agree แล้วคลิก Confirm เพื่อเปิดใช้งาน Messaging API</p>
                                            <Image
                                                width={1000}
                                                height={400}
                                                src="https://support.shoplineapp.com/hc/article_attachments/900006615066/screenshot-Connect_with_LINE-3.png"
                                            />
                                        </>
                                        : <></>
                                    }
                                    {this.state.current === 2 ?
                                        <>
                                            <p>หากเข้าหน้านี้ไม่ได้ให้เข้าที่เว้บไซต์ <a href='https://developers.line.biz/console/' target='_blank'>LINE Developers</a> เลือก provider ที่สร้างขึ้นมา</p>
                                            <Image
                                                width={1000}
                                                height={400}
                                                src="https://support.shoplineapp.com/hc/article_attachments/900007520103/screenshot-Connect_with_LINE-5.png"
                                            />
                                        </>
                                        : <></>
                                    }
                                    {this.state.current === 3 ?
                                        <>
                                            <p>เลือกที่แท็บ Messaging API</p>
                                            <Image
                                                width={1000}
                                                height={400}
                                                src="https://support.shoplineapp.com/hc/article_attachments/5357286664345/7.png"
                                            />
                                        </>
                                        : <></>
                                    }
                                    {this.state.current === 4 ?
                                        <>
                                            <p>เลื่อนลงไปข้างล่างจะเจอ Channel access token ให้กดที่ปุ่ม Issue แล้วคัดลอก token ที่สร้างมาใส่ในระบบ</p>
                                            <Image
                                                width={1000}
                                                height={400}
                                                src="https://support.shoplineapp.com/hc/article_attachments/5357009298201/3.png"
                                            />
                                        </>
                                        : <></>
                                    }

                                </div>
                                <div className="steps-action text-center">
                                    {this.state.current > 0 && (
                                        <Button
                                            style={{
                                                margin: '0 8px',
                                            }}
                                            onClick={() => this.setState({ current: this.state.current - 1 })}
                                        >
                                            ย้อนกลับ
                                        </Button>
                                    )}
                                    {this.state.current < steps.length - 1 && (
                                        <Button type="primary" onClick={() => this.setState({ current: this.state.current + 1 })}>
                                            ต่อไป
                                        </Button>
                                    )}
                                </div>
                            </>
                            : <></>
                        }

                        <br />
                        {/* <Row gutter={[16, 16]}>
                            <Col span={8}>
                                <Form.Item label="Bot ID">
                                    <Input value={this.state.configGeneral.config_line_botId} onChange={(e) => this.setState({ configGeneral: { ...this.state.configGeneral, config_line_botId: e.target.value } })} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Withdraw Chat ID (ห้องแจ้งถอน)">
                                    <Input value={this.state.configGeneral.config_line_chartId_withdraw} onChange={(e) => this.setState({ configGeneral: { ...this.state.configGeneral, config_line_chartId_withdraw: e.target.value } })} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <br/>
                        <Row gutter={[16, 16]}>
                            <Col span={8}>
                                <Form.Item label="Line liffId">
                                    <Input value={this.state.configGeneral.config_line_liffId} onChange={(e) => this.setState({ configGeneral: { ...this.state.configGeneral, config_line_liffId: e.target.value } })} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Line Endpoint URL">
                                    <Input value={this.state.configGeneral.config_line_endpoint} onChange={(e) => this.setState({ configGeneral: { ...this.state.configGeneral, config_line_endpoint: e.target.value } })} />
                                </Form.Item>
                            </Col>
                        </Row> */}
                    </Form>
                </Card>
                <br />
                <br />

                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Divider orientation="left"><Title level={4}>ตั้งค่าบัญชี</Title></Divider>
                    </Col>
                </Row>
                <Card>
                    <Row gutter={[16, 16]}>
                        {
                            this.state.BankAuto.map((item, index) =>
                                <Col span={12}>
                                    <Card>
                                        <div style={{ textAlign: 'right' }}>สถานะ: <Switch checkedChildren="เปิด" unCheckedChildren="ปิด" checked={item.bank_auto_status === 'เปิด' || item.bank_auto_status === 'เปิด' ? true : false} disabled /></div>
                                        <br />
                                        <Row>
                                            <Col span={4}>
                                                <Image
                                                    width={50}
                                                    src="/images/logo_bank/SCB.png"
                                                />
                                            </Col>
                                            <Col span={20}>
                                                <Row>
                                                    <Col span={12}>
                                                        <DescriptionItem title="ธนาคาร" content="ไทยพาณิช" />
                                                    </Col>
                                                    <Col span={12}>
                                                        <DescriptionItem title="เลขบัญชี" content={item.accountNo} />
                                                    </Col>
                                                    <Col span={24}>
                                                        <DescriptionItem title="ชื่อบัญชี" content={item.accountName} />
                                                    </Col>
                                                    <Col>
                                                        <Checkbox.Group options={optionsBankDeposit} value={item.status_deposit} onChange={(val) => this.handleMarkComplete(item.bank_auto_id, 'deposit', val)} />
                                                        <Checkbox.Group options={optionsBankWithdraw} value={item.status_withdraw} onChange={(val) => this.handleMarkComplete(item.bank_auto_id, 'withdraw', val)} />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row gutter={[16, 16]}>
                                            <Col span={20}>
                                            </Col>
                                            <Col span={2} style={{ marginTop: 20 }}>
                                                <Button type="primary" icon={<SaveOutlined />} onClick={() => this.postUpdateBankAuto(index, item.bank_auto_id)} disabled={this.state.admin_type === 'G' ? true : false}> บันทึก</Button>
                                            </Col>
                                        </Row>
                                        {/* <br /> */}
                                        {/* <Button type='primary' ghost block>แก้ไขบัญชี</Button> */}
                                    </Card>
                                </Col>
                            )
                        }
                        <Col span={12}>
                            <Card>
                                {/* <div style={{ textAlign: 'right' }}>สถานะ AUTO: <Switch checkedChildren="เปิด" unCheckedChildren="ปิด" checked={this.state.configBankAuto[1].bank_auto_status_deposit === 'เปิด' || this.state.configBankAuto[1].bank_auto_status_withdraw === 'เปิด' ? true : false} disabled /></div>
                                <br /> */}
                                <Row>
                                    <Col span={4}>
                                        <Image
                                            width={50}
                                            src="/images/logo_bank/KBANK.png"
                                        />
                                    </Col>
                                    <Col span={20}>
                                        <Row>
                                            <Col span={12}>
                                                <DescriptionItem title="ธนาคาร" content="กสิกรไทย" />
                                            </Col>
                                            <Col span={12}>
                                                <DescriptionItem title="เลขบัญชี" content={this.state.BankKBizAuto.accountNo} />
                                            </Col>
                                            <Col span={24}>
                                                <DescriptionItem title="ชื่อบัญชี" content={this.state.BankKBizAuto.accountName} />
                                            </Col>
                                            <Col span={12}>
                                                <DescriptionItem title="Username" content={this.state.BankKBizAuto.username} />
                                            </Col>
                                            <Col span={12}>
                                                <DescriptionItem title="Password" content={'******'} />
                                            </Col>
                                            <Col>
                                                <Checkbox.Group options={optionsBankDeposit} value={this.state.BankKBizAuto.status_deposit} onChange={(val) => this.setState({ DepositAuto: val })} disabled/>
                                                <Checkbox.Group options={optionsBankWithdraw} value={this.state.BankKBizAuto.status_withdraw} onChange={(val) => this.setState({ WithdrawAuto: val })} disabled />
                                            </Col>
                                        </Row>
                                        <br/>
                                        <Button type="primary" ghost block icon={<EditOutlined />} onClick={() => this.setState({ visableModalKBiz: true })} disabled={this.state.admin_type === 'G' ? true : false}> แก้ไข</Button>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>      

                    <br />
                    <br />
                    <Row gutter={[16, 16]}>
                        <Col span={21}>
                            <Divider orientation="left"><Title level={4}>บัญชีรับเงิน (สำรอง)</Title></Divider>
                        </Col>
                        <Col span={3} style={{ marginTop: 20 }}>
                            <Button
                                type="primary"
                                icon={<SaveOutlined />}
                                onClick={() => { this.setState({ visableModalAddBank: true }) }}
                                disabled={this.state.admin_type === 'G' ? true : false}
                            >
                                เพิ่มบัญชี
                            </Button>
                        </Col>
                    </Row>

                    <Card>
                        <Table columns={this.state.columnsBankReserve} dataSource={this.state.dataBankReserve} pagination={{ pageSize: 20 }} scroll={{ x: 1000 }} rowKey={record => record.row_num} />
                    </Card>

                    {/* เพิ่มบัญชีสำรอง */}
                    <Modal
                        title="เพิ่มบัญชีสำรอง"
                        open={this.state.visableModalAddBank}
                        onOk={() => this.postAddBank()}
                        onCancel={() => this.setState({ visableModalAddBank: false })}
                    >
                        <Form>
                            <Form.Item label="ธนาคาร" required>
                                <Select style={{ width: '100%' }} onChange={(val) => this.setState({ FormAddBank: { ...this.state.FormAddBank, bank_id: val } })} value={this.state.FormAddBank.bank_id}>
                                    {this.selectBanklist()}
                                </Select>
                            </Form.Item>
                            <Form.Item label="ชื่อบัญชี" required>
                                <Input style={{ width: '100%' }} size="large" defaultValue={this.state.FormAddBank.bank_account} stringMode onChange={(e) => this.setState({ FormAddBank: { ...this.state.FormAddBank, bank_account: e.target.value } })} />
                            </Form.Item>
                            <Form.Item label="เลขบัญชี" required>
                                <Input style={{ width: '100%' }} size="large" defaultValue={this.state.FormAddBank.bank_number} stringMode onChange={(e) => this.setState({ FormAddBank: { ...this.state.FormAddBank, bank_number: e.target.value } })} />
                            </Form.Item>
                        </Form>
                    </Modal>

                    <br />
                    <br />
                    {/* <Row gutter={[16, 16]}>
                        <Col span={22}> */}
                    <Divider orientation="left"><Title level={4}><Image src="/images/logo_bank/TWL.png" width={50} /> ทรูมันนี่ วอลเล็ต</Title></Divider>
                    {/* </Col>
                        <Col span={2} style={{ marginTop: 20 }}>
                            <Button type="primary" icon={<SaveOutlined />}> บันทึก</Button>
                        </Col>
                    </Row> */}
                    <Form layout="vertical" size="large">
                        <Row gutter={[16, 16]}>
                            <Col span={8}>
                                <Card>
                                    <div style={{ textAlign: 'right' }}>สถานะ: <Switch checkedChildren="เปิด" unCheckedChildren="ปิด" checked={this.state.configTruewallet.truewallet_status_deposit === 'เปิด' || this.state.configTruewallet.truewallet_status_withdraw === 'เปิด' ? true : false} onChange={(value) => this.setState(this.postUpdateTruewallet(value ? 'เปิด' : 'ปิด'))} disabled /></div>
                                    <Form.Item label={<>สถานะย่อย</>} required>
                                        <Checkbox.Group options={optionsBankDeposit} value={this.state.TrueWalletDepositAuto} onChange={(val) => this.setState({ TrueWalletDepositAuto: val })} />
                                        <Checkbox.Group options={optionsBankWithdraw} value={this.state.TrueWalletWithdrawAuto} onChange={(val) => this.setState({ TrueWalletWithdrawAuto: val })} disabled={this.state.configTruewallet.pin !== '' ? false : true} />
                                    </Form.Item>
                                    <Form.Item label={<>เบอร์รับเงินวอลเล็ต</>} required>
                                        <Input style={{ width: '100%' }} value={this.state.configTruewallet.truewallet_phone} onChange={(e) => this.setState({ configTruewallet: { ...this.state.configTruewallet, truewallet_phone: e.target.value } })} />
                                    </Form.Item>
                                    <Form.Item label={<>ชื่อบัญชี</>} required>
                                        <Input style={{ width: '100%' }} value={this.state.configTruewallet.truewallet_account} onChange={(e) => this.setState({ configTruewallet: { ...this.state.configTruewallet, truewallet_account: e.target.value } })} />
                                    </Form.Item>
                                    <Form.Item label={<>ยอดฝากขั้นต่ำ</>} required>
                                        <InputNumber style={{ width: '100%' }} value={this.state.configTruewallet.truewallet_min_deposit} onChange={(value) => this.setState({ configTruewallet: { ...this.state.configTruewallet, truewallet_min_deposit: value } })} />
                                    </Form.Item>
                                    <Form.Item label={<>เทิร์นโอเวอร์/วัน (เมื่อมีการฝากผ่านทรู)</>} required>
                                        <InputNumber style={{ width: '100%' }} value={this.state.configTruewallet.truewallet_turnover} onChange={(value) => this.setState({ configTruewallet: { ...this.state.configTruewallet, truewallet_turnover: value } })} />
                                    </Form.Item>
                                    <Form.Item label='รูปแบบรับเงิน' required>
                                        <Radio.Group onChange={(e) => this.setState({ configTruewallet: { ...this.state.configTruewallet, truewallet_channel: e.target.value } })} value={this.state.configTruewallet.truewallet_channel}>
                                            <Radio value={'VOUCHER'}>แบบรับซองอังเปา</Radio>
                                            <Radio value={'P2P'}>แบบโอนเงิน (สมัครเบอร์ไหนโอนเบอร์นั้น)</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    {this.state.configTruewallet.truewallet_channel === 'P2P' ? 
                                        <>
                                            <Form.Item label={<>Endpoint URL Webhook <span className='text-muted'>(คัดลอกไปวางที่ app truewallet)</span></>} required>
                                                <Input style={{ width: '100%' }} value={`${URL}/api/v1/truewallet/webhook`} disabled />
                                            </Form.Item>
                                            <Form.Item label={<>TOKEN</>} required>
                                                <Input style={{ width: '100%' }} value={this.state.configTruewallet.truewallet_token} onChange={(e) => this.setState({ configTruewallet: { ...this.state.configTruewallet, truewallet_token: e.target.value } })} />
                                            </Form.Item>
                                        </>
                                    : null }
                                    <Form.Item>
                                        <Button type="primary" block icon={<SaveOutlined />} onClick={() => this.postUpdateTruewallet(this.state.configTruewallet.truewallet_status)} disabled={this.state.admin_type === 'G' ? true : false}> บันทึก</Button>
                                    </Form.Item>
                                </Card>
                            </Col>
                            <Col span={16}>
                                <Card>
                                    <h4>วิธีติดตั้งทรูมันนี่ วอลเล็ต</h4>
                                    <p>1. เปิดแอพทรูมันนี่ <span className='text-danger'>(บัญชีที่เชื่อมต้องมียอดการรับเงินมากกว่า 100 รอบ และรอบล่ะมากกว่า 5 บาท)</span></p>
                                    <p>เปิดแอพทรู ไปที่เมนู ฉัน -> ช่วยเหลือ -> คำถามที่พบบ่อย ->โอนเงิน ->โอนเงินระหว่างทรู -> ค่าธรรมเนียม</p>
                                    <Image src='/images/truewallet/howto1-1.png' width={300} />
                                    <Image src='/images/truewallet/howto1-2.png' width={300} />
                                    <br />
                                    <br />
                                    <p>2. ก๊อปปี้ลิ้งค์ Endpoint URL Webhook มาวาง และติ๊กเครื่องหมายแล้วกดยืนยัน</p>
                                    <Image src='/images/truewallet/howto2.jpeg' width={300} />
                                    <br />
                                    <br />
                                    <p>3. คัดลอก Token มาใส่ที่ระบบ Auto</p>
                                    <Image src='/images/truewallet/howto3.png' width={300} />
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </Card>
                <br />
                <br />

                {/* <Row gutter={[16, 16]}>
                    <Col span={21}>
                        <Divider orientation="left"><Title level={4}>ตั้งค่า Agent</Title></Divider>
                    </Col>
                    <Col span={2} style={{ marginTop: 20 }}>
                        <Button type="primary" icon={<SaveOutlined />}> บันทึก</Button>
                    </Col>
                </Row>

                <Form layout="vertical" size="large">
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Card>
                                <Form.Item label="AGENT_X_API_BETFLIX">
                                    <Input value={this.state.configAgent} onChange={(value) => this.setState({ configLine: { ...this.state.configLine, name: value } })} />
                                </Form.Item>
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card>
                                <Form.Item label="AGENT_X_API_KEY">
                                    <Input value={this.state.configAgent} onChange={(value) => this.setState({ configLine: { ...this.state.configLine, name: value } })} />
                                </Form.Item>
                            </Card>
                        </Col>
                    </Row>
                </Form> */}

                {/* แก้ไข KBiz */}
                <Modal
                    title="แก้ไข KBiz"
                    open={this.state.visableModalKBiz}
                    onOk={() => this.postEditKBiz()}
                    onCancel={() => this.setState({ visableModalKBiz: false })}
                >
                    <Form>
                        <Form.Item label="เลขบัญชี" required>
                            <Input style={{ width: '100%' }} size="large" defaultValue={this.state.BankKBizAuto.accountNo} onChange={(e) => this.setState({ BankKBizAuto: { ...this.state.BankKBizAuto, accountNo: e.target.value } })} />
                        </Form.Item>
                        <Form.Item label="ชื่อบัญชี" required>
                            <Input style={{ width: '100%' }} size="large" defaultValue={this.state.BankKBizAuto.accountName} onChange={(e) => this.setState({ BankKBizAuto: { ...this.state.BankKBizAuto, accountName: e.target.value } })} />
                        </Form.Item>
                        <Form.Item label="Username" required>
                            <Input style={{ width: '100%' }} size="large" defaultValue={this.state.BankKBizAuto.username} onChange={(e) => this.setState({ BankKBizAuto: { ...this.state.BankKBizAuto, username: e.target.value } })} />
                        </Form.Item>
                        <Form.Item label="Password" extra={<span className='text-danger'>**หากไม่ต้องการแก้ไขให้เว้นว่างไว้</span>} required>
                            <Input style={{ width: '100%' }} size="large" defaultValue={''} onChange={(e) => this.setState({ BankKBizAuto: { ...this.state.BankKBizAuto, password: e.target.value } })} />
                        </Form.Item>
                        <Row>
                            <Col>
                                <Checkbox.Group options={optionsBankDeposit} defaultValue={this.state.BankKBizAuto.status_deposit} onChange={(val) => this.setState({ BankKBizAuto: { ...this.state.BankKBizAuto, status_deposit: val[0] } }) } />
                                <Checkbox.Group options={optionsBankWithdraw} value={this.state.BankKBizAuto.status_withdraw} disabled />
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </>
        );
    }
}