import React, { Component } from 'react';
import { Table, Card, Input, Button, Row, Col, Space, Tag, PageHeader, Typography, Image, Tabs, Form, DatePicker, Tooltip } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, StarFilled, QuestionCircleFilled } from '@ant-design/icons';
import { URL, WEB_NAME } from '../../../Util/Config';
import DateTime from 'node-datetime';
import moment from 'moment';
import axios from 'axios';

const { Text } = Typography;
const { TabPane } = Tabs;
const { Search } = Input;
const { RangePicker } = DatePicker;

const dateFormat = 'YYYY/MM/DD HH:mm:ss';
var dateStart = DateTime.create(`${new Date().toLocaleDateString('sv-SE')} 00:00:00`).format('Y/m/d H:M:S');
var dateEnd = DateTime.create(`${new Date().toLocaleDateString('sv-SE')} 23:59:59`).format('Y/m/d H:M:S');

const DescriptionItem = ({ title, content }) => (
  <div className="site-description-item-profile-wrapper m-0 text-light">
      <p className="site-description-item-profile-p-label m-0 text-light">{title}: {content}</p>
  </div>
);

export default class deposit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns_1: [
        {
          title: 'ลำดับ',
          dataIndex: 'row_num',
          render: (text, record) => (
            <div>{text} <Text code><small>{record.transaction_id}</small></Text></div>
          )
        },
        {
          title: 'ชื่อผู้ใช้งาน',
          dataIndex: 'title',
          render: (text, record) => (
            <p>{text} {record.user_tag === '1' ? <StarFilled style={{ color: 'orange' }} /> : ''}</p>
          )
        },
        {
          title: 'จำนวนเงิน',
          dataIndex: 'transaction_amount',
          align: 'right',
          render: (text, record) => (
            <Tag color='green'>
              {text} บาท
            </Tag>
          )
        },
        {
          title: 'เงินเข้าบัญชี (ของระบบ)',
          dataIndex: 'transaction_channel',
          align: 'center',
          render: (text, record) => (
            <Image src={`/images/logo_bank/${text}.png`} fallback="/images/image-not.webp" width={30} style={{ backgroundColor: 'black' }} alt="My Happy SVG" />
          )
        },
        {
          title: 'สถานะ',
          dataIndex: 'transaction_status',
          render: (text, record) => (
            text === 'ยกเลิก' ?
              <Tag color='red'>
                {text}
              </Tag>
              :
              <Tag color='green'>
                {text}
              </Tag>
          )
        },
        {
          title: 'หมายเหตุ',
          dataIndex: 'transaction_remark',
          render: (text, record) => (
            <>
              {text} {record.transaction_note ? <Tooltip placement="top" title={record.transaction_note}><QuestionCircleFilled /></Tooltip> : null}
            </>
          )
        },
        {
          title: 'วัน/เวลา โอนเงิน',
          dataIndex: 'transaction_datetime',
        },
        {
          title: 'ผู้อนุมัติรายการ',
          dataIndex: 'transaction_operator',
        }
      ],
      data_1: [],

      columns_2: [
        {
          title: 'ลำดับ',
          dataIndex: 'row_num',
          defaultSortOrder: 'descend',
          sorter: (a, b) => a.row_num - b.row_num,
          render: (text, record) => (
            <div>{text} <Text code><small>{record.transaction_id}</small></Text></div>
          )
        },
        {
          title: 'ชื่อผู้ใช้งาน',
          dataIndex: 'user_username',
          ...this.getColumnSearchProps('user_username'),
        },
        {
          title: 'ชื่อ-นามสกุล',
          dataIndex: 'user_name',
          ...this.getColumnSearchProps('user_name'),
        },
        {
          title: 'จำนวนเงิน',
          dataIndex: 'transaction_amount',
          align: 'right',
          render: (text, record) => (
            <Tag color='green'>
              {text} บาท
            </Tag>
          )
        },
        {
          title: 'ธนาคารลูกค้า',
          dataIndex: 'bank_abbrev_en',
          align: 'center',
          render: (text, record) => (
            record.transaction_status === 'สำเร็จ' ?
              <Image src={`/images/logo_bank/${text}.png`} fallback="/images/image-not.webp" width={30} style={{ backgroundColor: 'black' }} alt="My Happy SVG" />
              :
              <></>
          )
        },
        {
          title: 'เลขบัญชี',
          dataIndex: 'user_banknumber',
          ...this.getColumnSearchProps('user_banknumber'),
        },
        {
          title: 'เบอร์ตอดต่อ',
          dataIndex: 'user_phone',
          ...this.getColumnSearchProps('user_phone'),
        },
        {
          title: 'วัน/เวลาทำรายการ',
          dataIndex: 'transaction_datetime',
          ...this.getColumnSearchProps('transaction_datetime'),
        },
        {
          title: 'ผู้ทำรายการ',
          dataIndex: 'transaction_operator',
          ...this.getColumnSearchProps('transaction_operator'),
        }
      ],
      data_2: [],
      pagination: {
        pagination: {
          current: 1,
          pageSize: 20,
        }
      },
      loading_1: false,
      searchText: '',
      transfer: [
        {
            "amount": 0,
            "name": "กำไรรวม",
            "count": 0,
            "type": "กำไร",
        },
        {
            "amount": 0,
            "name": "ยอดฝากรวม",
            "count": 0,
            "type": "ฝาก"
        },
        {
            "amount": 0,
            "name": "แอดมิน",
            "count": 0,
            "type": "ฝาก"
        },
        {
            "amount": 0,
            "name": "SCB",
            "count": 0,
            "type": "ฝาก"
        },
        {
            "amount": 0,
            "name": "TWL",
            "count": 0,
            "type": "ฝาก"
        },
        {
            "amount": 0,
            "name": "KBank",
            "count": 0,
            "type": "ฝาก"
        },
        {
            "amount": 0,
            "name": "บัญชีสำรอง",
            "count": 0,
            "type": "ฝาก"
        },
        {
            "amount": 0,
            "name": "ยอดถอนรวม",
            "count": 0,
            "type": "ถอน"
        },
        {
            "amount": 0,
            "name": "SCB",
            "count": 0,
            "type": "ถอน"
        },
        {
            "amount": 0,
            "name": "TWL",
            "count": 0,
            "type": "ถอน"
        },
        {
            "amount": 0,
            "name": "KBank",
            "count": 0,
            "type": "ถอน"
        },
        {
            "amount": 0,
            "name": "แอดมิน",
            "count": 0,
            "type": "ถอน"
        },
        {
            "amount": 0,
            "name": "active",
            "count": 0,
            "type": "active"
        }
      ],
    };
  }

  componentDidMount() {
    this.setData(dateStart, dateEnd)
  }

  setData(startDate, endDate, params = {}) {
    dateStart = startDate
    dateEnd = endDate

    // var dt = DateTime.create(startDate);
    // startDate = dt.format('Y-m-d ');
    // dt = DateTime.create(endDate);
    // endDate = dt.format('Y-m-d');

    this.getDataTransactionAuto(startDate, endDate, params)
    this.getSummary(dateStart, dateEnd)
  }

  async getDataTransactionAuto(startDate, endDate, params) {
    if (params.pagination === undefined) {
      params = this.state.pagination
    }
    this.setState({ loading_1: true })
    var url = `${URL}/api/v1/transaction/deposit/auto/page/${params.pagination.current}`
    if (this.state.searchText !== '') {
      url = url + `/search/${this.state.searchText}`
    }
    let body = {
      start: startDate,
      end: endDate
    }

    await axios.post(url, body)
      .then(res => {
        const data = res.data
        if (data.status) {
          this.setState({
            data_1: data.result,
            loading_1: false,
            pagination: {
              pagination: {
                ...params.pagination,
                total: data.total,
              }
            },
          })
        } else console.log(data.message)
      })
      // .then(() => console.log(this.state.data))
      .catch((error) => {
        console.log('fetch data failed', error);
      });
  }

  async getSummary(startDate, endDate) {
    let body = {
      start: startDate,
      end: endDate
    }

    await axios.post(`${URL}/api/v1/transaction/summary`, body)
      .then(res => {
        const data = res.data
        if (data.status) {
          this.setState({
            transfer: data.result,
          })
        } else console.log(data.message)
      })
      // .then(() => console.log(this.state.data))
      .catch((error) => {
        console.log('fetch data failed', error);
      });
  }

  getDataTransactionManual() {
    fetch(`${URL}/api/v1/transaction/deposit/manual`)
      .then((response) => response.json())
      .then((json) => this.setState({ data_2: json.result }))
      // .then(() => console.log(this.state.data))
      .catch((error) => {
        console.log('fetch data failed', error);
      });
  }

  // ------------------------------------------------------------------ FILTER ----------------------------------------------------------- 
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  // ------------------------------------------------------------------ FILTER ----------------------------------------------------------- 

  handleTableChange(newPagination, filters, sorter) {
    console.log(newPagination)
    this.setData(dateStart, dateEnd, {
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: newPagination,
      ...filters,
    });
  };

  setTab(value) {
    if (value === '1') {
      this.setData(dateStart, dateEnd)
    } else if (value === '2') {
      this.getDataTransactionManual()
    }
  }

  onSearch() {
    this.setData(dateStart, dateEnd, {
      pagination: {
        current: 1,
        pageSize: 20,
      }
    })
  }

  render() {
    const routes = [
      {
        breadcrumbName: WEB_NAME,
      },
      {
        breadcrumbName: 'ประวัติการฝากเงิน',
      },
    ]
    return (
      <>
        <PageHeader
          className="site-page-header"
          title="ประวัติการฝากเงิน"
          breadcrumb={{ routes }}
        // subTitle="This is a subtitle"
        />

        <Row gutter={[16, 16]}>
          <Col span={24}>
              <Card   
                  className='text-center text-light' 
                  bodyStyle={{ background: '#198753' }} 
                  title={<>
                      <h2 className='m-1 text-muted'>฿ {(this.state.transfer[1].amount).toLocaleString()}</h2>
                      <p className='text-success'>ยอดฝากรวมทั้งหมด ({(this.state.transfer[1].count).toLocaleString()} รายการ)</p>
                  </>} 
                  bordered={false}
              >
                  <Row gutter={[0, 0]}>
                      <Col flex="1 1 200px" className='text-left'>
                          <DescriptionItem title="SCB" content={<>{(this.state.transfer[3].amount).toLocaleString()} <small>({(this.state.transfer[3].count).toLocaleString()} รายการ)</small></>} />
                          <DescriptionItem title="Truewallet" content={<>{(this.state.transfer[4].amount).toLocaleString()} <small>({this.state.transfer[4].count} รายการ)</small></>} />
                      </Col>
                      <Col flex="1 1 200px" className='text-left'>
                          <DescriptionItem title="KBank" content={<>{(this.state.transfer[5].amount).toLocaleString()} <small>({(this.state.transfer[5].count).toLocaleString()} รายการ)</small></>} />
                          <DescriptionItem title="แอดมิน" content={<>{(this.state.transfer[2].amount).toLocaleString()} <small>({(this.state.transfer[2].count).toLocaleString()} รายการ)</small></>} />
                      </Col>
                  </Row>
              </Card>
          </Col>
        </Row>
        <br />

        <Card>
          <Tabs type="card" onChange={(e) => this.setTab(e)}>
            <TabPane tab="ประวัติการฝาก (Auto)" key="1">
              {/* <Form layout="inline">
                <Form.Item label="ค้นหาประวัติธุรกรรมตามช่วงเวลา" style={{ width: '50%' }}>
                  <RangePicker
                    style={{ width: '100%' }}
                    // defaultValue={[moment(dateStart, dateFormat), moment(dateEnd, dateFormat)]}
                    format={dateFormat}
                    onChange={(e) => this.setTable(e[0]._d, e[1]._d)} />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" onClick={() => this.getData(undefined, undefined)}>
                    ค้นหาทั้งหมด
                  </Button>
                </Form.Item>
              </Form> */}
              <Row justify="end">
                <Col>
                  <Search placeholder="ค้นหาข้อมูล ชื่อ, ยูส, เบอร์ติดต่อ, เลขบัญชี" value={this.state.searchText} onChange={(e) => this.setState({ searchText: e.target.value })} style={{ width: 400 }} onSearch={() => this.onSearch()} />
                </Col>
              </Row>
              <br />
              <Row justify="end">
                <Col span={12}>
                  <Form layout='horizontal'>
                    <Form.Item label="ค้นหาประวัติธุรกรรมตามช่วงเวลา">
                      <RangePicker
                        style={{ width: '100%' }}
                        defaultValue={[moment(dateStart, dateFormat), moment(dateEnd, dateFormat)]}
                        format={dateFormat}
                        onChange={(e) => this.setData(new Date(e[0]._d).toLocaleString('sv-SE'), new Date(e[1]._d).toLocaleString('sv-SE'))} 
                        showTime={{ hideDisabledOptions: true }} />
                    </Form.Item>
                  </Form>
                </Col>
              </Row>

              <Table
                loading={this.state.loading_1}
                columns={this.state.columns_1}
                dataSource={this.state.data_1}
                pagination={this.state.pagination.pagination}
                scroll={{ x: 1800 }}
                rowKey={record => record.row_num}
                onChange={(newPagination, filters, sorter) => this.handleTableChange({ ...newPagination, pageSize: 20 }, filters, sorter)}
              />
            </TabPane>
            <TabPane tab="ประวัติการฝาก (Manual)" key="2">
              <Table columns={this.state.columns_2} dataSource={this.state.data_2} pagination={{ pageSize: 20 }} scroll={{ x: 1300 }} rowKey={record => record.row_num} />
            </TabPane>
          </Tabs>
        </Card>
      </>
    );
  }
}
