import React, { Component } from 'react';
import { Table, Card, Input, Button, Space, Tag, Spin, Drawer, Row, Col, Image, Typography, Divider, PageHeader, Select, Modal, Form, Tooltip, DatePicker, Statistic, InputNumber } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, FileSearchOutlined, QuestionCircleFilled } from '@ant-design/icons';
import axios from 'axios';
import x0p from 'x0popup';
import { URL, WEB_NAME } from '../../../Util/Config';
import Balance from '../dashboard/balance';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Text, Title } = Typography;

const DescriptionItem = ({ title, content }) => (
  <div className="site-description-item-profile-wrapper">
    <p className="site-description-item-profile-p-label">{title}:</p>
    {content}
  </div>
);

export default class notifyWithdraw extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerVisible: false,
      userProfile: {},
      userPromotion: {},
      amount: '',
      transaction_id: '',
      status: '',
      note: '',
      data: [],
      columns: [
        {
          title: 'ลำดับ',
          dataIndex: 'transaction_id',
          defaultSortOrder: 'descend',
          sorter: (a, b) => a.transaction_id - b.transaction_id,
          render: (text, record) => (
            <>
              {record.row_num}
              <Tag>
                {text}
              </Tag>
            </>
          )
        },
        {
          title: 'ชื่อผู้ใช้งาน',
          dataIndex: 'title',
          ...this.getColumnSearchProps('title'),
        },
        {
          title: 'จำนวนเงิน',
          dataIndex: 'transaction_amount',
          align: 'right',
          render: (text, record) => (
            <Tag color='red'>
              {text} บาท
            </Tag>
          )
        },
        {
          title: 'ธนาคารลูกค้า',
          dataIndex: 'bank_abbrev_en',
          render: (text, record) => (
            <Image src={`/images/logo_bank/${text}.png`} fallback="/images/image-not.webp" width={30} style={{ backgroundColor: 'black' }} alt="My Happy SVG" />
          )
        },
        {
          title: 'เลขบัญชี',
          dataIndex: 'user_banknumber',
          ...this.getColumnSearchProps('user_banknumber'),
        },
        {
          title: 'สถานะ',
          dataIndex: 'transaction_status',
          render: (text, record) => (
            <>
              {text === 'ผิดพลาด' ?
                <Tag color='red'>
                  {text}
                </Tag>
                :
                <Tag color='blue'>
                  {text}
                </Tag>
              }
              {record.transaction_note ? <Tooltip placement="top" title={record.transaction_note}><QuestionCircleFilled /></Tooltip> : null}
            </>
          )
        },
        {
          title: 'วัน/เวลา โอนเงิน',
          dataIndex: 'transaction_datetime',
        },
        {
          title: 'ตรวจสอบ',
          dataIndex: 'user_id',
          render: (text, record) => (
            this.state.admin_type === 'G' ? null :
              <Button type="primary" onClick={() => this.showProfile(record)} icon={<FileSearchOutlined />}>ตรวจสอบ</Button>
          )
        }
      ],
      scbBalance: undefined,
      kbankBalance: undefined,
      truewalletBalance: undefined,
      truewalletStatusWithdraw: undefined,
      scbStatusWithdraw: undefined,
      // agentBalance: undefined,
      admin_type: null,
      statusAll: [],
      channel: '',
      isModalSCBSelect: false,
      autoSCB: 1,
      maxWithdraw: 0,
      BankAuto: [],
      dataSummary: {},
      user_id: ''
    };
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem('user'))
    this.setState({ admin_type: user.user.type, admin_token: user.accessToken })
    this.getAutoBank()
    this.getData()

    setTimeout(() => {
      this.getAgentBalance()
      // this.getSCBBalance()
      // this.getTruewalletBalance()
      // this.getKBankBalance()
    }, 2000);
  }

  getData() {
    fetch(`${URL}/api/v1/transaction/error/withdraw`)
      .then((response) => response.json())
      .then((json) => this.setState({ data: json.result }))
      // .then(() => console.log(this.state.data))
      .catch((error) => {
        console.log('fetch data failed', error);
      });
  }

  async getAutoBank() {
    fetch(`${URL}/api/v1/scb/account/receive`, {
      headers: {
        'Authorization': `Bearer ${this.state.admin_token}`,
      }
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          this.setState({ BankAuto: json.result })
        }
      })
      .catch((error) => {
        console.log('getAccSCBBalance', error);
      });
  }

  // getSCBBalance() {
  //   const accountSCB = JSON.parse(localStorage.getItem('accountSCB'))
  //   if (accountSCB) {
  //     // console.log(accountSCB.accountBalance, accountSCB.status_withdraw)
  //     this.setState({ scbBalance: accountSCB.accountBalance, scbStatusWithdraw: accountSCB.status_withdraw })
  //   }
  // }

  getTruewalletBalance() {
    const accountTruewallet = JSON.parse(localStorage.getItem('accountTruewallet'))
    if (accountTruewallet) {
      this.setState({ truewalletBalance: accountTruewallet.accountBalance, truewalletStatusWithdraw: accountTruewallet.truewallet_status_withdraw })
    }
  }

  getKBankBalance() {
    const accountKBank = JSON.parse(localStorage.getItem('accountKBank'))
    if (accountKBank) {
      this.setState({ kbankBalance: accountKBank.availableBalance })
    }
  }

  getAgentBalance() {
    const accountAG = JSON.parse(localStorage.getItem('accountAG'))
    if (accountAG) {
      this.setState({ agentBalance: accountAG })
    }
  }

  // ------------------------------------------------------------------ START FILTER ----------------------------------------------------------- 
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  // ------------------------------------------------------------------ END FILTER ----------------------------------------------------------- 

  showProfile(value) {
    this.setState({
      drawerVisible: true,
      amount: parseInt(value.transaction_amount.replaceAll(',', '')),
      transaction_id: value.transaction_id,
      status: value.transaction_status,
      note: value.transaction_note,
      channel: value.transaction_channel,
      user_id: value.user_id
    })
    this.getPromotion(value.user_id)
    this.getDataTranscationSummary(value.user_id, null, null)
    // console.log(`${URL}/api/v1/user/id/${value.user_id}`)
    fetch(`${URL}/api/v1/user/id/${value.user_id}`)
      .then((response) => response.json())
      .then((json) => this.setState({ userProfile: json.result }))
      // .then(() => console.log(this.state.userProfile))
      .catch((error) => {
        console.log('fetch data failed', error);
      });
  }

  async transactionCancel() {
    const user = JSON.parse(localStorage.getItem('user'))
    x0p({
      title: 'แจ้งเตือน!!',
      text: 'คุณต้องการไม่อนุมัติการถอนเงินใช่หรือไม่??',
      icon: 'info',
      buttons: [
        {
          type: 'cancel',
          text: 'ยกเลิก',
        },
        {
          type: 'info',
          text: 'ยืนยัน',
          showLoading: true
        }
      ]
    }).then(async (alert) => {
      if (alert.button === 'info') {
        var body = { userID: this.state.userProfile.user_userId, transactionID: this.state.transaction_id, adminID: user.user.id } // userID Line
        await axios.post(`${URL}/api/v3/transfer/withdraw/cancel`, body, {
          headers: {
            'Authorization': `Bearer ${this.state.admin_token}`,
          }
        })
          .then(res => {
            const result = res.data
            if (result.status) {
              this.getData()
              this.setState({ drawerVisible: false })
              x0p('ไม่อนุมัติ สำเร็จ!!', null, 'ok', false);
              this.messageAlert(`ไม่อนุมัติการถอน ${this.state.amount} บาท`)
            } else {
              x0p(null, result.message, 'error', false);
            }
          })
          .catch(error => {
            console.log(error);
            x0p('การเชื่อมต่อผิดพลาด transactionCancel', null, 'error', false);
          })
      }
    });
  }

  // อนุมัติ (โอนเงิน SCB)
  async SCBtransactionSuccess(id) {
    this.setState({ isModalSCBSelect: false })
    const user = JSON.parse(localStorage.getItem('user'))
    x0p({
      title: 'ยืนยันถอน (โอนเงิน SCB)!!',
      text: 'คุณต้องการอนุมัติการถอนเงินใช่หรือไม่??',
      icon: 'info',
      buttons: [
        {
          type: 'cancel',
          text: 'ยกเลิก',
        },
        {
          type: 'info',
          text: 'ยืนยัน',
          showLoading: true
        }
      ]
    }).then(async (alert) => {
      if (alert.button === 'info') {
        var body = { transactionID: this.state.transaction_id, adminID: user.user.id } // , max_withdraw: this.state.maxWithdraw }
        await axios.post(`${URL}/api/v3/transfer/withdraw/success/scb/id/${id}`, body, {
          headers: {
            'Authorization': `Bearer ${this.state.admin_token}`,
          }
        })
          .then(res => {
            const result = res.data
            if (result.status) {
              this.getData()
              this.setState({ drawerVisible: false, isModalSCBSelect: false })
              x0p('ระบบทำรายการถอนเงินเสร็จสิ้น', null, 'ok', false);
              this.messageAlert(`ทำรายการถอนเงินสำเร็จ!!\nถอนเงิน ${this.state.amount} บาท`)
            } else if (result.message) {
              x0p(result.message, null, 'error', false);

            } else {
              console.log(result)
              x0p('เกิดข้อผิดพลาด', null, 'error', false);
            }
          })
          .catch(error => {
            console.log(error);
            x0p('การเชื่อมต่อผิดพลาด withdraw', null, 'error', false);
          })
      }
    });
  }

  // อนุมัติ (โอนเงิน Truewallet)
  async TruewalletTransactionSuccess() {
    x0p({
      title: 'รอตรวจสอบ',
      text: '',
      // animationType: 'slideDown',
      icon: 'info',
      buttons: [],
      // autoClose: 3000
    });

    var body = { "phone": this.state.userProfile.user_phone }
    await axios.post(`${URL}/api/v1/truewallet/check/account`, body, {
      headers: {
        'Authorization': `Bearer ${this.state.admin_token}`,
      }
    })
      .then(res => {
        const { result, status, message } = res.data
        if (!status) {
          x0p('', message, 'error', false);
          return
        }
        x0p({
          title: '',
          text: `โอนเงินให้ วอลเล็ต: ${result.mobile_number} ${result.recipient_name}`,
          icon: 'info',
          buttons: [
            {
              type: 'cancel',
              text: 'ยกเลิก',
            },
            {
              type: 'info',
              text: 'ยืนยัน',
              showLoading: true
            }
          ]
        }).then(async (alert) => {
          if (alert.button === 'info') {
            const user = JSON.parse(localStorage.getItem('user'))
            var body = {
              transactionID: this.state.transaction_id,
              adminID: user.user.id
            }
            await axios.post(`${URL}/api/v3/transfer/withdraw/success/truewallet`, body, {
              headers: {
                'Authorization': `Bearer ${this.state.admin_token}`,
              }
            })
              .then(res => {
                const { status, message } = res.data
                if (status) {
                  this.getData()
                  this.setState({ drawerVisible: false })
                  x0p('ระบบทำรายการถอนเงินเสร็จสิ้น', null, 'ok', false);
                  this.messageAlert(`ทำรายการถอนเงินสำเร็จ!!\nถอนเงิน ${this.state.amount} บาท`)
                } else {
                  x0p('', message, 'error', false);
                }
              })
              .catch(error => {
                console.log(error);
                x0p('การเชื่อมต่อผิดพลาด TruewalletTransactionSuccess (success)', null, 'error', false);
              })
          }
        });
      })
      .catch(error => {
        console.log(error);
        x0p('การเชื่อมต่อผิดพลาด TruewalletTransactionSuccess (check)', null, 'error', false);
      })
  }

  // อนุมัติ (โอนเงิน KBANK)
  async KBankTransactionSuccess() {
    const user = JSON.parse(localStorage.getItem('user'))
    x0p({
      title: 'ยืนยันถอน (โอนเงิน KBank)!!',
      text: 'คุณต้องการอนุมัติการถอนเงินใช่หรือไม่??',
      icon: 'info',
      buttons: [
        {
          type: 'cancel',
          text: 'ยกเลิก',
        },
        {
          type: 'info',
          text: 'ยืนยัน',
          showLoading: true
        }
      ]
    }).then(async (alert) => {
      if (alert.button === 'info') {
        var body = { transactionID: this.state.transaction_id, adminID: user.user.id }
        await axios.post(`${URL}/api/v3/transfer/withdraw/success/kbank`, body, {
          headers: {
            'Authorization': `Bearer ${this.state.admin_token}`,
          }
        })
          .then(res => {
            const result = res.data
            if (result.status) {
              this.getData()
              this.setState({ drawerVisible: false })
              x0p('ระบบทำรายการถอนเงินเสร็จสิ้น', null, 'ok', false);
              this.messageAlert(`ทำรายการถอนเงินสำเร็จ!!\nถอนเงิน ${this.state.amount} บาท`)
            } else if (result.message) {
              x0p(result.message, null, 'error', false);

            } else {
              console.log(result)
              x0p('เกิดข้อผิดพลาด', null, 'error', false);
            }
          })
          .catch(error => {
            console.log(error);
            x0p('การเชื่อมต่อผิดพลาด withdraw', null, 'error', false);
          })
      }
    });
  }

  // อนุมัติ (ไม่โอนเงิน)
  async transactionSuccessNot() {
    const user = JSON.parse(localStorage.getItem('user'))
    x0p({
      title: 'ยืนยันถอน (ไม่โอนเงิน)!!',
      text: 'คุณต้องการอนุมัติการถอนเงิน (ไม่โอนเงิน) ใช่หรือไม่??',
      icon: 'info',
      buttons: [
        {
          type: 'cancel',
          text: 'ยกเลิก',
        },
        {
          type: 'info',
          text: 'ยืนยัน',
          showLoading: true
        }
      ]
    }).then(async (alert) => {
      if (alert.button === 'info') {
        var body = { transactionID: this.state.transaction_id, adminID: user.user.id }
        await axios.post(`${URL}/api/v3/transfer/withdraw/success/nottranfer`, body, {
          headers: {
            'Authorization': `Bearer ${this.state.admin_token}`,
          }
        })
          .then(res => {
            const result = res.data
            if (result.status) {
              this.getData()
              this.setState({ drawerVisible: false })
              x0p('ระบบทำรายการถอนเงินเสร็จสิ้น', null, 'ok', false);
              this.messageAlert(`ทำรายการถอนเงินสำเร็จ!!\nถอนเงิน ${this.state.amount} บาท`)
            } else if (result.message) {
              x0p(result.message, null, 'error', false);

            } else {
              console.log(result)
              x0p('เกิดข้อผิดพลาด', null, 'error', false);
            }
          })
          .catch(error => {
            console.log(error);
            x0p('การเชื่อมต่อผิดพลาด withdraw', null, 'error', false);
          })
      }
    });
  }

  async getPromotion(user_id) {
    const body = {
      user_id: user_id
    }
    await axios.post(`${URL}/api/v1/promotion/by/user`, body)
      .then(res => {
        const data = res.data
        console.log('getPromotion --> ', data)
        if (data.status && data.result != null) {
          this.setState({ userPromotion: data.result })
        } else {
          this.setState({ userPromotion: null })
        }
      })
      .catch(error => {
        console.log(error)
        console.log('การเชื่อมต่อผิดพลาด getPromotion')
      })
  }

  async messageAlert(ms) {
    var body = {
      userID: this.state.userProfile.user_userId, // userID Line
      message: ms
    }
    await axios.post(`${URL}/api/v1/line/messages/push`, body)
      .then(res => {
        console.log()
      })
      .catch(error => {
        console.log(error);
        alert('การเชื่อมต่อผิดพลาด Post User')
      })
  }

  getDataTranscationSummary(user_id, start, end) {
    var url = `${URL}/api/v1/transaction/summary/user_id/${user_id}`
    if (start && end) {
        var date = new Date(start).toLocaleDateString('sv-SE')
        const startDate = new Date(`${date} 00:00:00`).toLocaleDateString('sv-SE')
        date = new Date(end).toLocaleDateString('sv-SE')
        const endDate = new Date(`${date} 23:59:59`).toLocaleDateString('sv-SE')

        url = `${URL}/api/v1/transaction/summary/user_id/${user_id}/start/${startDate}/end/${endDate}`
    }
    fetch(url)
        .then((response) => response.json())
        .then((json) => this.setState({ dataSummary: json.result }))
        // .then(() => console.log(this.state.data))
        .catch((error) => {
            console.log('fetch data failed', error);
        });
  };

  render() {
    const routes = [
      {
        breadcrumbName: WEB_NAME,
      },
      {
        breadcrumbName: 'แจ้งฝากผิดพลาด',
      },
      {
        breadcrumbName: 'รายการแจ้งถอนเครดิต',
      },
    ]

    return (
      <>
        <PageHeader
          className="site-page-header"
          title="รายการแจ้งถอนเครดิต"
          breadcrumb={{ routes }}
        // subTitle="This is a subtitle"
        />

        <Balance />
        <br />
        <Card>
          <Table dataSource={this.state.data} columns={this.state.columns} pagination={{ pageSize: 20 }} scroll={{ x: 800 }} />
        </Card>

        {/* PROFILE */}
        <Drawer
          title="รายละเอียด"
          placement="left"
          width={600}
          closable={false}
          onClose={() => this.setState({ drawerVisible: false })}
          open={this.state.drawerVisible}
        >
          <Spin spinning={this.state.userProfile.balance !== undefined ? false : true}>
            <Title level={5}>#{this.state.transaction_id} <Tag color={this.state.status === 'ผิดพลาด' ? 'red' : 'blue'}>{this.state.status}</Tag></Title>
            {this.state.status === 'ผิดพลาด' ?
              <Text type="danger" style={{ marginBottom: 20 }}>**หมายเหตุ : {this.state.note}</Text>
              : <></>
            }

            <Title level={5}>ข้อมูลสมาชิก</Title>
            <Row>
              <Col span={12}>
                <DescriptionItem title="ชื่อ-นามสกุล" content={this.state.userProfile.user_name} />
              </Col>
              <Col span={12}>
                <DescriptionItem title="username" content={this.state.userProfile.user_username} />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <DescriptionItem title="เบอร์ติดต่อ" content={this.state.userProfile.user_phone} />
              </Col>
            </Row>
            <Divider />
            <Title level={5}>บัญชีธนาคาร</Title>
            <Image
              width={50}
              src={`/images/logo_bank/${this.state.userProfile.bank_abbrev_en}.png`}
            />
            <Row>
              <Col span={12}>
                <DescriptionItem title="ธนาคาร" content={this.state.userProfile.bank_abbrev_th} />
              </Col>
              <Col span={12}>
                <DescriptionItem title="เลขบัญชี" content={this.state.userProfile.user_banknumber} />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <DescriptionItem title="ชื่อบัญชี" content={this.state.userProfile.user_name} />
              </Col>
            </Row>
            <Divider />

            <Title level={5}>การรับโปรโมชั่น</Title>
            {this.state.userPromotion.discount_name !== undefined ?
              <>
                <Row>
                  <Col span={12}>
                    <DescriptionItem title="โปรโมชั่นที่รับ" content={this.state.userPromotion.discount_name} />
                  </Col>
                  <Col span={12}>
                    <DescriptionItem title="สถานะ" content={this.state.userPromotion.log_discount_status} />
                  </Col>
                  <Col span={24}>
                    <DescriptionItem title="วันที่รับโปร" content={`${this.state.userPromotion.promotion_start_date}`} />
                  </Col>
                  {this.state.userPromotion.log_discount_status === 'ใช้งานแล้ว' ?
                    <>
                      <Col span={24}>
                        <DescriptionItem title="เครดิตฟรีที่ได้รับ" content={`${this.state.userPromotion.discount_net_amount} บาท`} />
                      </Col>
                      <Col span={12}>
                        <DescriptionItem title="ติดโปรบังคับ" content={`${this.state.userPromotion.discount_turnover_amount}`} />
                      </Col>
                      <Col span={12}>
                        <DescriptionItem title="ยอดเล่นสะสม" content={`${this.state.userPromotion.total_play}`} />
                      </Col>
                    </>
                    : <></>
                  }
                </Row>
              </>
              :
              <>
                <Row>
                  <Col span={24}>
                    <DescriptionItem title="โปรโมชั่นที่รับ" content={'ยังไม่ได้รับโปรโมชั่น'} />
                  </Col>
                </Row>
              </>
            }
            <Divider />

            <Title level={5}>ประวัติการเงิน</Title>
            <RangePicker format={'YYYY-MM-DD'} onChange={(value) => {
              this.getDataTranscationSummary(this.state.user_id, value[0]._d, value[1]._d)
            }} />
            <Button onClick={() => {
              this.getDataTranscationSummary(this.state.user_id, null, null)
            }}>ทั้งหมด</Button>

            <Row gutter={[16, 16]}>
              <Col span={8}>
                {/* <Card></Card> */}
                <Statistic
                  title="ยอดฝากรวม"
                  value={this.state.dataSummary.total_deposit}
                  valueStyle={{ color: '#3f8600' }}
                  prefix="+"
                />
              </Col>
              <Col span={8}>
                {/* <Card></Card> */}
                <Statistic
                  title="ยอดถอนรวม"
                  value={this.state.dataSummary.total_withdraw}
                  valueStyle={{ color: '#cf1322' }}
                  prefix="-"
                />
              </Col>
              <Col span={8}>
                {/* <Card></Card> */}
                <Statistic
                  title="รายได้สุทธิ"
                  prefix={this.state.dataSummary.income >= 0 ? '+' : ''}
                  value={this.state.dataSummary.income}
                  valueStyle={{ color: this.state.dataSummary.income >= 0 ? '#3f8600' : '#cf1322' }}
                />
              </Col>
            </Row>
            <Divider />

            <Title level={5}>ธุรกรรม</Title>
            <Spin spinning={this.state.userProfile.balance !== undefined ? false : true}>
              <Row>
                <Col span={12}>
                  <DescriptionItem title="เครดิตคงเหลือ" content={`${parseFloat(this.state.userProfile.balance).toFixed(0)} บาท`} />
                </Col>
                <Col span={12}>
                  <DescriptionItem title="จำนวนเงินที่ต้องการถอน" content={`${parseFloat(this.state.amount).toFixed(2)} บาท`} />
                </Col>
              </Row>
              <Divider />

              {/* <Spin spinning={this.state.agentBalance !== undefined ? false : true}> */}
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  {/* <Spin spinning={this.state.scbBalance !== undefined ? false : true}> */}
                    <Button type="primary" ghost block onClick={() => this.setState({ isModalSCBSelect: true, maxWithdraw: this.state.amount })} disabled={this.state.admin_type === 'G' ? true : false}><Image src="/images/logo_bank/SCB.png" width={20} preview={false} /> อนุมัติ (โอนเงิน SCB)</Button>
                  {/* </Spin> */}
                </Col>
                
                <Col span={12}>
                  <Button type="primary" ghost block onClick={() => this.KBankTransactionSuccess()} disabled><Image src="/images/logo_bank/KBANK.png" width={20} preview={false} /> อนุมัติ (โอนเงิน KBank)</Button>
                </Col>
                
                <Col span={12}>
                  <Spin spinning={this.state.truewalletBalance !== undefined ? false : true}>
                    <Button type="primary" ghost block onClick={() => this.TruewalletTransactionSuccess()} disabled={this.state.truewalletStatusWithdraw === 'เปิด' && this.state.channel.includes('TWL') && this.state.userProfile.user_phone ? false : true}><Image src="/images/logo_bank/TWL.png" width={20} preview={false} /> อนุมัติ (โอนเงิน True Wallet)</Button>
                  </Spin>
                </Col>
              </Row>
              <br />
              <br />
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Button type="primary" block onClick={() => this.transactionSuccessNot()} disabled={this.state.admin_type === 'G' ? true : false}>อนุมัติ (แบบไม่โอนเงิน)</Button>
                </Col>
                <Col span={12}>
                  <Button danger block onClick={() => this.transactionCancel()} disabled={this.state.admin_type === 'G' ? true : false}>ไม่อนุมัติ</Button>
                </Col>
              </Row>
              {/* </Spin> */}
            </Spin>
          </Spin>
        </Drawer>

        {/* SCB SELECT */}
        { this.state.isModalSCBSelect ?
          <Modal title="ยืนยันถอน (โอนเงิน SCB)!!" open={this.state.isModalSCBSelect} onOk={() => this.SCBtransactionSuccess(this.state.autoSCB)} onCancel={(e) => this.setState({ isModalSCBSelect: false })}>
            {/* <p>คุณต้องการอนุมัติการถอนเงินใช่หรือไม่??</p> */}

            <p>ยอดที่ต้องการถอน {parseFloat(this.state.amount).toFixed(2)} บาท</p>

            <Form.Item label="ระบุยอดที่ต้องการโอนเงิน" required>
              <InputNumber addonAfter="บาท" defaultValue={this.state.amount} max={this.state.amount} min={1} onChange={(val) => this.setState({ maxWithdraw: val })} disabled/>
            </Form.Item>

            <Form.Item label="SCB เลือกบัญชีโอน" required>
              <Select value={this.state.autoSCB} onChange={(value) => this.setState({ autoSCB: value })} style={{ width: '100%' }}>
                  {
                    this.state.BankAuto.map((item, index) =>
                      <Option key={index} value={item.bank_auto_id}>{item.accountName} ({item.accountNo})</Option>
                    )
                  }
              </Select>
            </Form.Item>
          </Modal>
          : <></>
        }
      </>
    );
  }
}
