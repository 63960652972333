import React, { Component } from 'react';
import { PageHeader, Row, Col, Card, Statistic } from 'antd';
import { URL, WEB_NAME } from '../../../../Util/Config';
import History from './history'
// import Affiliate from './affiliate'

export default class affiliate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            summary: {},
        }
    }

    componentDidMount() {
        this.getData()
    }

    getData() {
        fetch(`${URL}/api/v1/affiliate/summary`)
            .then((response) => response.json())
            // .then((json) => console.log(json))
            .then((json) => this.setState({ summary: json.result }))
            // .then(() => console.log(this.state.summary))
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    }

    render() {
        const routes = [
            {
                breadcrumbName: WEB_NAME,
            },
            {
                breadcrumbName: 'โปรโมชั่น',
            },
            {
                breadcrumbName: 'แนะนำเพื่อน',
            },
        ]
        return (
            <>
                <PageHeader
                    className="site-page-header"
                    title="แนะนำเพื่อน"
                    breadcrumb={{ routes }}
                />

                <Row gutter={[16, 16]}>
                    <Col span={6}>
                        <Card>
                            <Statistic
                                title="การแนะนำเพื่อน"
                                value={this.state.summary.total_count}
                                valueStyle={{ color: '#1990FF' }}
                                suffix="ครั้ง"
                            />
                        </Card>
                    </Col>
                    {/* <Col span={6}>
                        <Card>
                            <Statistic
                                title="ค่าตอบ(เครดิตที่แจก)ทั้งหมด"
                                value={this.state.summary.total_amount}
                                valueStyle={{ color: '#EF9900' }}
                                suffix="เครดิต"
                            />
                        </Card>
                    </Col> */}
                </Row>
                <br/>

                <History />
                {/* <Tabs type="card">
                    <Tabs.TabPane tab="แนะนำเพื่อน" key="item-1">
                        <Affiliate />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="ประวัติการแนะนำเพื่อน" key="item-2">
                        <History />
                    </Tabs.TabPane>
                </Tabs>; */}
            </>
        );
    }
}
