import React, { Component } from 'react';
import axios from 'axios';
import { Table, Card, Input, Tag, Button, Tooltip, Switch, Form, Col, Row, Typography, Modal, message, InputNumber, Upload, Select, DatePicker, Radio, Image, Space, Tabs, Collapse } from 'antd';
import { URL } from '../../../../Util/Config'
import { EditOutlined, PlusOutlined, SolutionOutlined, UploadOutlined, DeleteOutlined, QrcodeOutlined } from '@ant-design/icons';
import { formateDateTimeDMYT } from '../../../../Util/formatDate';
import { withRouter } from 'react-router'
import moment from 'moment';
import x0p from 'x0popup';
import Detail from './discount_use'
import CodePage from './code'

// import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
// import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
// import {
//   SortableContext,
//   arrayMove,
//   useSortable,
//   verticalListSortingStrategy,
// } from '@dnd-kit/sortable';
// import { CSS } from '@dnd-kit/utilities';

const { Panel } = Collapse;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Text } = Typography;

class discount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns1: [
        {
          title: '',
          dataIndex: 'discount_image',
          align: 'center',
          width: 80,
          render: (text, record) => (
            <>
              {text !== '' ?
                <Image src={text.includes('http') ? text : URL + '/public/images/promotion/' + text} width={30} />
                : <></>
              }
            </>
          ),
        },
        {
          title: 'โปรโมชั่น',
          dataIndex: 'discount_name',
          width: 300,
          render: (text, record) => (
            <Text style={{ width: 2 }}>
              {text}
            </Text>
          )
        },
        {
          title: 'จำนวนที่ได้รับ',
          dataIndex: 'discount_amount',
          align: 'right',
          width: 150,
          render: (text, record) => (
            <Tag color="green">{text}</Tag>
          ),
        },
        {
          title: 'ประเภท',
          dataIndex: 'discount_type',
          width: 120,
          render: (text, record) => (
            text === 'เปอร์เซ็นต์' ? <Tag color="purple">{text}</Tag> : <Tag color="geekblue">{text}</Tag>
          ),
        },
        {
          title: 'เทิร์น',
          dataIndex: 'discount_turnover',
          align: 'right',
          width: 150,
          render: (text, record) => (
            <><Tag color="volcano">{text} { record.discount_type === 'เปอร์เซ็นต์' ? '' : `(` + text * ( record.discount_min_deposit + record.discount_bonus_max ) + `)`}</Tag></>
          ),
        },
        {
          title: 'สถานะ',
          dataIndex: 'discount_status',
          width: 80,
          align: 'right',
          render: (text, record) => (
            record.discount_status === 'เปิด' ? <Button style={{ backgroundColor: '#76D7C4' }} shape="circle" disabled>เปิด</Button> : <Button style={{ backgroundColor: '#F1948A' }} shape="circle" disabled>ปิด</Button>
          ),
        },
        {
          title: 'เกมที่รองรับ',
          dataIndex: 'discount_type_game',
          width: 150,
          align: 'right',
          render: (text, record) => {
            if (text === '0') {
              return (<>ทั้งหมด</>)
            } else if (text === '1') {
              return (<>สล็อต</>)
            } else if (text === '2') {
              return (<>บาคาร่า</>)
            } else if (text === '3') {
              return (<>PG (ปรับแตก)</>)
            }
          },
        },
        {
          title: 'ช่วงเวลาที่รับได้',
          dataIndex: 'discount_time',
          width: 200,
          align: 'right',
          render: (text, record) => {
            if (!text || text === '') {
              return (<>ตลอดทั้งวัน</>)
            } else {
              return (<>{text}</>)
            }
          },
        },
        {
          title: 'เริ่มต้น-สิ้นสุด (วัน/เวลา)',
          dataIndex: 'discount_start_date',
          width: 200,
          render: (text, record) => (
            <p>{formateDateTimeDMYT(text) + " ถึง " + formateDateTimeDMYT(record.discount_end_date)}</p>
          ),
        },
        // {
        //   title: 'จำนวนครั้งที่รับ/ยกเลิก',
        //   dataIndex: 'amount_use',
        //   align: 'right',
        //   render: (text, record) => (
        //     <>
        //       <p><Tag color="cyan">รับโปรฯ {text} ครั้ง</Tag></p>
        //       <p><Tag color="red">ยกเลิกโปรฯ {record.amount_use_cancel} ครั้ง</Tag></p>
        //     </>
        //   ),
        // },
        // {
        //   title: 'ยอดทั้งหมด',
        //   dataIndex: 'total_deposit',
        //   align: 'right',
        //   render: (text, record) => (
        //     <>
        //       <p><Tag color="green">ฝากทั้งหมด: {text}</Tag></p>
        //       <p><Tag color="orange">แจกทั้งหมด: {record.total_bonus}</Tag></p>
        //       <p><Tag color="red">ถอนทั้งหมด: {record.total_withdraw}</Tag></p>
        //     </>
        //   ),
        // },
        {
          title: 'รายละเอียด',
          dataIndex: 'discount_id',
          width: 150,
          fixed: 'right',
          render: (text, record) => (
            <>
              <Tooltip title="รายการคูปอง"><Button style={{ marginRight: 5 }} type="primary" shape="circle" onClick={() => this.setState({ detail_discount_id: text, isModalCodePromotionVisible: true })} icon={<QrcodeOutlined />}></Button></Tooltip>
              {/* {record.amount_coupon > 1 ?
                <Tooltip title="รายการคูปอง"><Button style={{ marginRight: 5 }} type="primary" shape="circle" onClick={() => this.goToCouponPage(text)} icon={<FileSearchOutlined />}></Button></Tooltip>
                : <></>
              } */}
              <Tooltip title="ผู้รับโปรโมชั่น"><Button style={{ marginRight: 5 }} type="primary" shape="circle" onClick={() => this.setState({ detail_discount_id: text, isModalDetailPromotionVisible: true })} icon={<SolutionOutlined />}></Button></Tooltip>
              <Tooltip title="แก้ไขโปรโมชั่น"><Button style={{ marginRight: 5 }} type="primary" shape="circle" onClick={() => this.showDiscount(text)} icon={<EditOutlined />} disabled={this.state.admin_type === 'G' ? true : false}></Button></Tooltip>
            </>
          )
        }
      ],
      data1: [],
      columns2: [
        {
          title: '',
          dataIndex: 'discount_image',
          align: 'center',
          width: 80,
          render: (text, record) => (
            <>
              {text !== '' ?
                <Image src={text.includes('http') ? text : URL + '/public/images/promotion/' + text} width={30} />
                : <></>
              }
            </>
          ),
        },
        {
          title: 'โปรโมชั่น',
          dataIndex: 'discount_name',
          width: 300,
          render: (text, record) => (
            <Text style={{ width: 2 }}>
              {text}
            </Text>
          )
        },
        {
          title: 'จำนวนที่ได้รับ',
          dataIndex: 'discount_amount',
          align: 'right',
          width: 150,
          render: (text, record) => (
            <Tag color="green">{text}</Tag>
          ),
        },
        {
          title: 'ประเภท',
          dataIndex: 'discount_type',
          width: 120,
          render: (text, record) => (
            text === 'เปอร์เซ็นต์' ? <Tag color="purple">{text}</Tag> : <Tag color="geekblue">{text}</Tag>
          ),
        },
        {
          title: 'เทิร์น',
          dataIndex: 'discount_turnover',
          align: 'right',
          width: 150,
          render: (text, record) => (
            <><Tag color="volcano">{text} { record.discount_type === 'เปอร์เซ็นต์' ? '' : `(` + text * ( record.discount_min_deposit + record.discount_bonus_max ) + `)`}</Tag></>
          ),
        },
        {
          title: 'สถานะ',
          dataIndex: 'discount_status',
          width: 80,
          align: 'right',
          render: (text, record) => (
            record.discount_status === 'เปิด' ? <Button style={{ backgroundColor: '#76D7C4' }} shape="circle" disabled>เปิด</Button> : <Button style={{ backgroundColor: '#F1948A' }} shape="circle" disabled>ปิด</Button>
          ),
        },
        {
          title: 'เกมที่รองรับ',
          dataIndex: 'discount_type_game',
          width: 150,
          align: 'right',
          render: (text, record) => {
            if (text === '0') {
              return (<>ทั้งหมด</>)
            } else if (text === '1') {
              return (<>สล็อต</>)
            } else if (text === '2') {
              return (<>บาคาร่า</>)
            } else if (text === '3') {
              return (<>PG (ปรับแตก)</>)
            }
          },
        },
        {
          title: 'ช่วงเวลาที่รับได้',
          dataIndex: 'discount_time',
          width: 200,
          align: 'right',
          render: (text, record) => {
            if (!text || text === '') {
              return (<>ตลอดทั้งวัน</>)
            } else {
              return (<>{text}</>)
            }
          },
        },
        {
          title: 'เริ่มต้น-สิ้นสุด (วัน/เวลา)',
          dataIndex: 'discount_start_date',
          width: 200,
          render: (text, record) => (
            <p>{formateDateTimeDMYT(text) + " ถึง " + formateDateTimeDMYT(record.discount_end_date)}</p>
          ),
        },
        // {
        //   title: 'จำนวนครั้งที่รับ/ยกเลิก',
        //   dataIndex: 'amount_use',
        //   align: 'right',
        //   render: (text, record) => (
        //     <>
        //       <p><Tag color="cyan">รับโปรฯ {text} ครั้ง</Tag></p>
        //       <p><Tag color="red">ยกเลิกโปรฯ {record.amount_use_cancel} ครั้ง</Tag></p>
        //     </>
        //   ),
        // },
        // {
        //   title: 'ยอดทั้งหมด',
        //   dataIndex: 'total_deposit',
        //   align: 'right',
        //   render: (text, record) => (
        //     <>
        //       <p><Tag color="green">ฝากทั้งหมด: {text}</Tag></p>
        //       <p><Tag color="orange">แจกทั้งหมด: {record.total_bonus}</Tag></p>
        //       <p><Tag color="red">ถอนทั้งหมด: {record.total_withdraw}</Tag></p>
        //     </>
        //   ),
        // },
        {
          title: 'รายละเอียด',
          dataIndex: 'discount_id',
          fixed: 'right',
          width: 150,
          render: (text, record) => (
            <>
              <Tooltip title="แก้ไขโปรโมชั่น"><Button style={{ marginRight: 5 }} type="primary" shape="circle" onClick={() => this.showDiscount(text)} icon={<EditOutlined />}></Button></Tooltip>
              <Tooltip title="ผู้รับโปรโมชั่น"><Button style={{ marginRight: 5 }} type="primary" shape="circle" onClick={() => this.goToHistoryDiscount(text)} icon={<SolutionOutlined />}></Button></Tooltip>
              <Tooltip title="ลบโปรโมชั่น"><Button danger shape="circle" onClick={() => this.deleteDiscount(text)} icon={<DeleteOutlined />}></Button></Tooltip>
            </>
          )
        }
      ],
      data2: [],
      isModalCodePromotionVisible: false,
      isModalAddPromotionVisible: false,
      add_discount_name: '',
      add_discount_type: 'บาท',
      add_discount_amount: 1,
      add_discount_min_deposit: 0,
      add_discount_bonus_max: 1,
      add_discount_turnover: 1,
      add_discount_type_turn: '2',
      add_discount_type_game: '0',
      add_discount_start_date: '',
      add_discount_end_date: '',
      add_discount_condition: 0,
      add_discount_amount_coupon: 0,
      add_discount_max_withdraw: 1,
      add_discount_max_withdraw_format: 3,
      add_discount_level: 'พิเศษ',
      add_discount_games: ["Sport", "Casino", "M2", "Trade", "Card", "Poker", "Slot", "Fish"],
      add_discount_affiliate: 1,
      add_discount_affiliate_bonus: 0,
      add_discount_affiliate_bonus_max: 0,
      add_discount_affiliate_amount: 0,
      add_discount_image: '',
      add_discount_image_show: 1,
      add_discount_image_file: {},
      add_discount_deposit_collect: 0,
      add_discount_withdraw_collect: 0,
      add_discount_probefore: '',
      add_discount_s_time: 0,
      add_discount_time: '',

      isModalEditPromotionVisible: false,
      edit_discount_id: '',
      edit_discount_name: '',
      edit_discount_type: '',
      edit_discount_amount: '',
      edit_discount_min_deposit: '',
      edit_discount_bonus_max: '',
      edit_discount_turnover: '',
      edit_discount_type_turn: '',
      edit_discount_type_game: '',
      edit_discount_games: ["Sport", "Casino", "M2", "Trade", "Card", "Poker", "Slot", "Fish"],
      edit_discount_start_date: '',
      edit_discount_end_date: '',
      edit_discount_condition: '',
      edit_discount_amount_coupon: '',
      edit_discount_coupon_code: '',
      edit_discount_status: '',
      edit_discount_max_withdraw: '',
      edit_discount_max_withdraw_format: '',
      edit_discount_level: '',
      edit_discount_affiliate: '',
      edit_discount_affiliate_amount: 0,
      edit_discount_affiliate_bonus: '',
      edit_discount_affiliate_bonus_max: '',
      edit_discount_image: '',
      edit_discount_image_show: 1,
      edit_discount_image_file: {},
      edit_discount_deposit_collect: 0,
      edit_discount_withdraw_collect: 0,
      edit_discount_probefore: '',
      edit_discount_s_time: 0,
      edit_discount_time: '',

      isModalDetailPromotionVisible: false,
      detail_discount_id: null,
      admin_type: null,
      admin_token: null,

      defaultFileList: [],
      promotionList: [],


      // columnsTest: [
      //   {
      //     title: 'Sort',
      //     dataIndex: 'sort',
      //     width: 30,
      //     className: 'drag-visible',
      //   },
      //   {
      //     title: 'Name',
      //     dataIndex: 'name',
      //     className: 'drag-visible',
      //   },
      //   {
      //     title: 'Age',
      //     dataIndex: 'age',
      //   },
      //   {
      //     title: 'Address',
      //     dataIndex: 'address',
      //   },
      // ],
      // dataTest: [
      //   {
      //     key: '1',
      //     name: 'John Brown',
      //     age: 32,
      //     address: 'New York No. 1 Lake Park',
      //     index: 0,
      //   },
      //   {
      //     key: '2',
      //     name: 'Jim Green',
      //     age: 42,
      //     address: 'London No. 1 Lake Park',
      //     index: 1,
      //   },
      //   {
      //     key: '3',
      //     name: 'Joe Black',
      //     age: 32,
      //     address: 'Sidney No. 1 Lake Park',
      //     index: 2,
      //   },
      // ]
    }
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem('user'))
    this.setState({ admin_type: user.user.type, admin_token: user.accessToken })

    this.getDataOpen()
    this.getDataClose()
  }

  getDataOpen() {
    fetch(`${URL}/api/v1/discount/open`)
      .then((response) => response.json())
      .then((json) => this.setState({ data1: json.result }))
      // .then(() => console.log(this.state.data1))
      .catch((error) => {
        console.log('fetch data failed', error);
      });
  }

  getDataClose() {
    fetch(`${URL}/api/v1/discount/close`)
      .then((response) => response.json())
      .then((json) => this.setState({ data2: json.result }))
      // .then(() => console.log(this.state.data2))
      .catch((error) => {
        console.log('fetch data failed', error);
      });
  }

  disabledDate(current) {
    return current && current <= moment().subtract(1, 'days'); //วันก่อนหน้า
  }

  goToCouponPage(id_discount) {
    // console.log(this.props.history)
    this.props.history.push(`/adminManage/promotion/coupon/by/discount/${id_discount}`)
  }

  goToHistoryDiscount(id_discount) {
    this.props.history.push(`/adminManage/promotion/discount/use/${id_discount}`)
  }

  async addDiscount() {
    const state = this.state;
    if (state.add_discount_name !== '' && parseInt(state.add_discount_amount) &&
      state.add_discount_min_deposit !== '' && parseInt(state.add_discount_turnover) &&
      state.add_discount_start_date !== '' && state.add_discount_end_date !== '') {

      // if (state.add_discount_type === 'บาท' && state.add_discount_level === 'พิเศษ' && 
      //     parseInt(state.add_discount_max_withdraw) > parseInt(state.add_discount_turnover))
      //   message.error('ยอดถอนสูงสุดต้องน้อยกว่าจำนวนเทิร์นโอเวอร์')

      if (state.add_discount_type === 'เปอร์เซ็นต์' && state.add_discount_min_deposit < 50) {
        message.error('หากเลือกเครดิตเปอร์เซ็นต์ ฝากขั้นต่ำอย่างน้อย 50')

      } else {
        // ถอนสูงสุด 
        var max_withdraw = state.add_discount_max_withdraw

        if (state.edit_discount_type === 'เปอร์เซ็นต์') {
          if (state.add_discount_max_withdraw_format === 1) {
            max_withdraw = 1
          }
        }
        
        let level = 'พิเศษ'
        if (state.add_discount_max_withdraw_format === 0) {
          level = 'ธรรมดา'
          max_withdraw = 0
        }
        
        const formData = new FormData();
        formData.append('name', state.add_discount_name);
        formData.append('type', state.add_discount_type);
        formData.append('amount', state.add_discount_amount);
        formData.append('min_deposit', state.add_discount_min_deposit);
        formData.append('bonus_max', state.add_discount_bonus_max);
        formData.append('turnover', state.add_discount_turnover);
        formData.append('type_turn', state.add_discount_type_turn);
        formData.append('type_game', state.add_discount_type_game);
        formData.append('games', state.add_discount_games);
        formData.append('start_date', state.add_discount_start_date.toLocaleString('sv-SE'));
        formData.append('end_date', state.add_discount_end_date.toLocaleString('sv-SE'));
        formData.append('condition', state.add_discount_condition);
        formData.append('amount_coupon', state.add_discount_amount_coupon);
        formData.append('max_withdraw', max_withdraw);
        formData.append('level', level);
        formData.append('affiliate_amount', state.add_discount_affiliate_amount);
        formData.append('affiliate', state.add_discount_affiliate);
        formData.append('affiliate_bonus', state.add_discount_affiliate_bonus);
        formData.append('affiliate_bonus_max', state.add_discount_affiliate_bonus_max);
        formData.append("image", state.add_discount_image_file);
        formData.append("image_show", state.add_discount_image_show);

        formData.append("time", state.add_discount_s_time === 0 ? '' : state.add_discount_time)
        formData.append("deposit_collect", state.add_discount_deposit_collect);
        formData.append("withdraw_collect", state.add_discount_withdraw_collect);
        formData.append("probefore", state.add_discount_probefore);

        const res = await fetch(`${URL}/api/v2/discount/add`, {
          method: "POST",
          body: formData,
          headers: {
            'Authorization': `Bearer ${this.state.admin_token}`,
          }
        }).then((res) => res.json());

        if (res.status) {
          this.getDataOpen()
          this.getDataClose()
          message.success('เพิ่มโปรโมชั่นสำเร็จ')
          this.setState({ isModalAddPromotionVisible: false })
        } else {
          message.error('ไม่สามารถเพิ่มโปรโมชั่นได้!!')
        }

      }

    } else {
      message.error('กรุณาตรวจสอบข้อมูล!!')
    }
  }

  async showDiscount(id) {
    await this.GetPromotionList()
    await axios.get(`${URL}/api/v1/discount/id/${id}`)
      .then(res => {
        const data = res.data
        if (data.status) {
          this.setState({ defaultFileList: [] })
          if (data.result.discount_image !== '') {
            this.setState({ defaultFileList: [{
                uid: '1',
                name: data.result.discount_image,
                status: 'done',
                url: data.result.discount_image.includes('http') ? data.result.discount_image : URL + '/public/images/promotion/' + data.result.discount_image
              }]
            })
          }

          let format = 3
          
          if (data.result.discount_level === 'ธรรมดา') {
            format = 0
          }
          
          if (data.result.discount_type === 'เปอร์เซ็นต์') {
            if (data.result.discount_max_withdraw >= 11) {
              format = 3
            } else if (data.result.discount_max_withdraw >= 2) {
              format = 2
            } else {
              format = 1
            }
          } 

          if (data.result.discount_time !== '' && data.result.discount_time !== null) {
            this.setState({ edit_discount_s_time: 1 })
          } else {
            this.setState({ edit_discount_s_time: 0 })
          }

          this.setState({
            edit_discount_id: data.result.discount_id,
            edit_discount_name: data.result.discount_name,
            edit_discount_type: data.result.discount_type,
            edit_discount_amount: data.result.discount_amount,
            edit_discount_min_deposit: data.result.discount_min_deposit,
            edit_discount_max_withdraw: data.result.discount_max_withdraw,
            edit_discount_max_withdraw_format: format,
            edit_discount_level: data.result.discount_level,
            edit_discount_bonus_max: data.result.discount_bonus_max,
            edit_discount_turnover: data.result.discount_turnover,
            edit_discount_type_turn: data.result.discount_type_turn,
            edit_discount_type_game: data.result.discount_type_game,
            edit_discount_games: data.result.discount_games,
            edit_discount_start_date: data.result.discount_start_date,
            edit_discount_end_date: data.result.discount_end_date,
            edit_discount_condition: data.result.discount_condition,
            edit_discount_amount_coupon: data.result.coupon_limit,
            coupon_limit: data.result.coupon_limit > 0 ? 1 : 0,
            edit_discount_coupon_code: data.result.coupon_code,
            edit_discount_status: data.result.discount_status,
            edit_discount_affiliate: data.result.discount_affiliate,
            edit_discount_affiliate_bonus: data.result.discount_affiliate_bonus,
            edit_discount_affiliate_bonus_max: data.result.discount_affiliate_bonus_max,
            edit_discount_image: data.result.discount_image,
            edit_discount_image_show: data.result.discount_image ? 1 : 2,

            edit_discount_affiliate_amount: data.result.discount_affiliate_amount,
            edit_discount_deposit_collect: data.result.discount_deposit_collect,
            edit_discount_withdraw_collect: data.result.discount_withdraw_collect,
            edit_discount_probefore: data.result.discount_probefore,
            edit_discount_time: data.result.discount_time,

            isModalEditPromotionVisible: true,
          }, () => {
            console.log(this.state.edit_discount_time)
          })
          
        } else {
          console.log('เกิดข้อผิดพลาด showDiscount')
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  async editDiscount() {
    const state = this.state;
    if (state.edit_discount_name !== '' && parseInt(state.edit_discount_amount) &&
      state.edit_discount_min_deposit !== '' && parseInt(state.edit_discount_turnover) &&
      state.edit_discount_start_date !== '' && state.edit_discount_end_date !== '') {

      if (state.edit_discount_type === 'เปอร์เซ็นต์' && state.edit_discount_min_deposit < 50) {
        message.error('หากเลือกเครดิตเปอร์เซ็นต์ ฝากขั้นต่ำอย่างน้อย 50')

      } else {
        // ถอนสูงสุด 
        var max_withdraw = state.edit_discount_max_withdraw

        if (state.edit_discount_type === 'เปอร์เซ็นต์') {
          if (state.edit_discount_max_withdraw_format === 1) {
            max_withdraw = 1
          }
        }

        let level = 'พิเศษ'
        if (state.edit_discount_max_withdraw_format === 0) {
          level = 'ธรรมดา'
        }

        const formData = new FormData();
        formData.append('id', state.edit_discount_id);
        formData.append('name', state.edit_discount_name);
        formData.append('type', state.edit_discount_type);
        formData.append('amount', state.edit_discount_amount);
        formData.append('min_deposit', state.edit_discount_min_deposit);
        formData.append('bonus_max', state.edit_discount_bonus_max);
        formData.append('turnover', state.edit_discount_turnover);
        formData.append('type_turn', state.edit_discount_type_turn);
        formData.append('type_game', state.edit_discount_type_game);
        formData.append('games', state.edit_discount_games);
        formData.append('start_date', state.edit_discount_start_date.toLocaleString('sv-SE'));
        formData.append('end_date', state.edit_discount_end_date.toLocaleString('sv-SE'));
        formData.append('status', state.edit_discount_status);
        formData.append('max_withdraw', max_withdraw);
        formData.append('level', level);
        formData.append('affiliate_amount', state.edit_discount_affiliate_amount);
        formData.append('affiliate', state.edit_discount_affiliate);
        formData.append('affiliate_bonus', state.edit_discount_affiliate_bonus);
        formData.append('affiliate_bonus_max', state.edit_discount_affiliate_bonus_max);
        formData.append('condition', state.edit_discount_condition);
        formData.append('coupon_limit', state.edit_discount_amount_coupon);
        formData.append("image_show", state.edit_discount_image_show);

        formData.append("time", state.edit_discount_s_time === 0 ? '' : state.edit_discount_time)
        formData.append("deposit_collect", state.edit_discount_deposit_collect);
        formData.append("withdraw_collect", state.edit_discount_withdraw_collect);
        formData.append("probefore", state.edit_discount_probefore);

        if (state.edit_discount_image_show === 1) {
          formData.append("image", state.edit_discount_image_file);
        }

        const res = await fetch(`${URL}/api/v2/discount/edit`, {
          method: "POST",
          body: formData,
          headers: {
            'Authorization': `Bearer ${this.state.admin_token}`,
          }
        }).then((res) => res.json());

        if (res.status) {
          this.getDataOpen()
          this.getDataClose()
          message.success('แก้ไขข้อมูลโปรโมชั่นสำเร็จ')
          this.setState({ isModalEditPromotionVisible: false })
        } else {
          message.error('ไม่สามารถแก้ไขโปรโมชั่นได้')
        }

      }
    } else {
      message.error('กรุณาตรวจสอบข้อมูล!!')
    }
  }

  async deleteDiscount(id) {
    x0p({
      title: 'ลบโปรโมชั่น!!',
      text: `คุณต้องการลบโปรโมชั่นใช่มั้ย??`,
      icon: 'info',
      buttons: [
        {
          type: 'cancel',
          text: 'ยกเลิก',
        },
        {
          type: 'info',
          text: 'ยืนยัน',
          showLoading: true
        }
      ]
    }).then(async (alert) => {
      if (alert.button === 'info') {
        const body = {
          discount_id: id
        }
        await axios.post(`${URL}/api/v1/discount/delete`, body)
          .then(res => {
            const data = res.data
            if (data.status) {
              this.getDataOpen()
              this.getDataClose()
              x0p('ลบข้อมูลสำเร็จ', null, 'ok', false);
            } else {
              // console.log(data)
              x0p('', data.message, 'error', false);
            }
          })
          .catch(error => {
            console.log(error)
            message.error('เกิดข้อผิดพลาด!!')
          })
      }
    })
  }

  async OnChangeAddTypeTurn(val) {
    this.setState({ add_discount_type_turn: val })
    if (val === '0' || val === '1') {
      this.setState({ add_discount_games: ['Slot', 'Fish', 'Casino', 'PG'] })
    } else {
      this.setState({ add_discount_games: ['Sport', 'Slot', 'Fish', 'Casino', 'Card', 'Poker', 'M2', 'Trade', 'PG'] })
    }
  }

  async OnChangeAddTypeGame(val) {
    this.setState({ add_discount_type_game: val })
    if (val === '3') { // PG ปรับแตก
      this.setState({ add_discount_games: ['PG'] })
    } else if (val === '0') {
      this.setState({ add_discount_games: ['Slot', 'Fish', 'Casino', 'PG'] })
    } else if (val === '1') {
      this.setState({ add_discount_games: ['Slot', 'Fish', 'PG'] })
    } else if (val === '2') {
      this.setState({ add_discount_games: ['Casino'] })
    }
  }

  async OnChangeEditTypeTurn(val) {
    this.setState({ edit_discount_type_turn: val })
    if (val === '0' || val === '1') {
      this.setState({ edit_discount_games: ['Slot', 'Fish', 'Casino', 'PG'] })
    } else {
      this.setState({ edit_discount_games: ['Sport', 'Slot', 'Fish', 'Casino', 'Card', 'Poker', 'M2', 'Trade', 'PG'] })
    }
  }

  async OnChangeEditTypeGame(val) {
    this.setState({ edit_discount_type_game: val })
    if (val === '3') { // PG ปรับแตก
      this.setState({ edit_discount_games: ['PG'] })
    } else if (val === '0') {
      this.setState({ edit_discount_games: ['Slot', 'Fish', 'Casino', 'PG'] })
    } else if (val === '1') {
      this.setState({ edit_discount_games: ['Slot', 'Fish', 'PG'] })
    } else if (val === '2') {
      this.setState({ edit_discount_games: ['Casino'] })
    }
  }

  // uploadImageAdd(file) {
  //   if (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg') {
  //       this.setState({ formAdd: { add_discount_image: file } })
  //       console.log(file)
  //   } else {
  //       message.error(`${file.name} ไม่รองรับประเภทไฟล์นี้`)
  //   }
  // }

  // uploadImageEdit(file) {
  //   if (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg') {
  //       this.setState({ formEdit: { edit_discount_image: file } })
  //       console.log(file)
  //   } else {
  //       message.error(`${file.name} ไม่รองรับประเภทไฟล์นี้`)
  //   }
  // }

  // รายการโปรโมชั่น
  async GetPromotionList() {
    fetch(`${URL}/api/v1/discount/all`)
      .then((response) => response.json())
      .then((json) => {
        this.setState({ promotionList: json.result })
      })
      // .then(() => console.log(this.state.data2))
      .catch((error) => {
        console.log('fetch data failed', error);
      });
  }
  
  PromotionList() {
    const promotionList = this.state.promotionList
    let list = promotionList.map((item, index) =>
      <Option key={index} value={item.discount_id.toLocaleString()}>{item.discount_name}</Option>
    );
    return (list)
  }

  // onDragEnd = ({ active, over }) => {
  //   if (active.id !== over?.id) {
  //       const activeIndex = this.state.dataTest.findIndex((i) => i.key === active.id);
  //       const overIndex = this.state.dataTest.findIndex((i) => i.key === over?.id);
  //       this.setState({ dataTest: arrayMove(this.state.dataTest, activeIndex, overIndex) }) 
  //   }
  // };

  render() {
    // const RowDrag = (props) => {
    //   const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    //     id: props['data-row-key'],
    //   });
    //   const style = {
    //     ...props.style,
    //     transform: CSS.Transform.toString(
    //       transform && {
    //         ...transform,
    //         scaleY: 1,
    //       },
    //     ),
    //     transition,
    //     cursor: 'move',
    //     ...(isDragging
    //       ? {
    //           position: 'relative',
    //           zIndex: 9999,
    //         }
    //       : {}),
    //   };
    //   return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
    // };
    
    return (
      <>
        <Card>
          <Row style={{ marginBottom: 20 }}>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Button 
                type="primary" 
                icon={<PlusOutlined />} 
                onClick={() => { 
                    this.GetPromotionList()
                    this.setState({ isModalAddPromotionVisible: true })
                }}
                disabled={this.state.admin_type === 'G' ? true : false}
              >เพิ่มโปรโมชั่น</Button>
            </Col>
          </Row>

          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="เปิดใช้งานอยู่" key="1">
              <Table columns={this.state.columns1} dataSource={this.state.data1} pagination={{ pageSize: 25 }} scroll={{ x: 1000 }} rowKey={record => record.discount_name} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="ปิดใช้งาน" key="2">
              <Table columns={this.state.columns2} dataSource={this.state.data2} pagination={{ pageSize: 25 }} scroll={{ x: 1000 }} rowKey={record => record.discount_name} />
            </Tabs.TabPane>
          </Tabs>
        </Card>

        {/* <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={(e) => this.onDragEnd(e)}>
          <SortableContext
            // rowKey array
            items={this.state.dataTest.map((i) => i.key)}
            strategy={verticalListSortingStrategy}
          >
            <Table
              components={{
                body: {
                  row: RowDrag,
                },
              }}
              rowKey="key"
              columns={this.state.columnsTest}
              dataSource={this.state.dataTest}
            />
          </SortableContext>
        </DndContext> */}

        {/* เพิ่มโปรโมชั่น */}
        <Modal title="เพิ่มโปรโมชั่น" style={{ top: 20 }} open={this.state.isModalAddPromotionVisible} footer={[]} maskClosable={false} onCancel={() => this.setState({ isModalAddPromotionVisible: false })}>
          <Form name="formAdd" layout="vertical" onFinish={() => this.addDiscount()}>
            <Form.Item label="ชื่อโปรโมชั่น" name="name" style={{ width: '100%' }} rules={[{ required: true, message: 'กรุณากรอกชื่อโปรโมชั่น!' }]}>
              <Input placeholder="" onChange={(e) => this.setState({ add_discount_name: e.target.value })} />
            </Form.Item>
            <Form.Item label="ประเภท" required>
              <Select defaultValue="บาท" style={{ width: '100%' }} onChange={(val) => this.setState({ add_discount_type: val })}>
                <Option value="บาท">บาท</Option>
                <Option value="เปอร์เซ็นต์">เปอร์เซ็นต์</Option>
              </Select>
            </Form.Item>
            <Form.Item label={<>ฝากขั้นต่ำ <Tag color="blue">บาท</Tag> {this.state.add_discount_type === 'บาท' ? <Tag color="red">0 คือไม่มีขั้นต่ำ</Tag> : ''} </>} name="min_deposit" extra={this.state.add_discount_type === 'เปอร์เซ็นต์' ? 'ฝากขั้นต่ำอย่างน้อย 50' : ''} rules={[{ required: true, message: 'กรุณากรอกยอดฝากขั้นต่ำ!' }]}>
              <InputNumber min={0} style={{ width: '100%' }} onChange={(val) => this.setState({ add_discount_min_deposit: val })} value={this.state.add_discount_min_deposit} addonAfter='บาท' />
            </Form.Item>
            <Form.Item label={<>โบนัส {this.state.add_discount_type === 'บาท' ? <Tag color="blue">เครดิต</Tag> : <Tag color="purple">เปอร์เซ็นต์</Tag>} </>} name="bonus" tooltip={this.state.add_discount_type === 'เปอร์เซ็นต์' ? 'โบนัสที่ได้รับ = (ยอดฝากแรก * โบนัส / 100)' : ''} rules={[{ required: true, message: 'กรุณากรอกโบนัสที่จะได้รับ!' }]}>
              <InputNumber style={{ width: '100%' }} onChange={(val) => this.setState({ add_discount_amount: val })} value={this.state.add_discount_amount} addonAfter={this.state.add_discount_type === 'บาท' ? 'เครดิต' : '%'} />
            </Form.Item>
            <Form.Item label={<>โบนัสสูงสุด <Tag color="blue">เครดิต</Tag></>}>
              <InputNumber min={1} style={{ width: '100%' }} onChange={(val) => this.setState({ add_discount_bonus_max: val })} value={this.state.add_discount_type === 'บาท' ? this.state.add_discount_amount : this.state.add_discount_bonus_max} disabled={this.state.add_discount_type === 'บาท' ? true : false} suffix='บาท' />
            </Form.Item>
            <Form.Item label="รูปแบบเทิร์น" required>
              <Select defaultValue="เทิร์นเครดิต" style={{ width: '100%' }} onChange={(val) => this.OnChangeAddTypeTurn(val)}>
                <Option value="0">ทั้งเทิร์นเล่นสะสม และ เทิร์นเครดิต</Option>
                <Option value="1">เทิร์นเล่นสะสม</Option>
                <Option value="2">เทิร์นเครดิต</Option>
              </Select>
            </Form.Item>
            <Form.Item label="กำหนดเทิร์น" tooltip={this.state.add_discount_type === 'บาท' ? '(ยอดฝากขั้นต่ำ + จำนวนที่ได้รับ) * จำนวนเทิร์น = เทิร์นโอเวอร์' : '(ยอดฝากแรก + โบนัส) * จำนวนเทิร์น  = เทิร์นโอเวอร์'} required>
              <InputNumber min={0} style={{ width: this.state.add_discount_type === 'บาท' ? '50%' : '100%' }} onChange={(val) => this.setState({ add_discount_turnover: val })} value={this.state.add_discount_turnover} />
              {this.state.add_discount_type === 'บาท' ?
                <Input style={{ width: '50%' }} disabled value={(parseInt(this.state.add_discount_min_deposit) + parseInt(this.state.add_discount_amount)) * parseInt(this.state.add_discount_turnover)} suffix="เทิร์นโอเวอร์" />
                : ''}
            </Form.Item>
            <Form.Item
              label="ประเภทเกมที่รองรับ"
              extra={<>ประเภทเกมจะแบ่งตามค่ายสามารถตรวจสอบได้ที่ <a href='/adminManage/howto#typegame' target='_blank'>คลิก</a></>}
              required>
              <Select defaultValue="ทั้งหมด" style={{ width: '100%' }} onChange={(val) => this.OnChangeAddTypeGame(val)}>
                <Option value="0">ทั้งหมด</Option>
                <Option value="1">สล็อต</Option>
                <Option value="2">บาคาร่า</Option>
                <Option value="3">PG (ปรับแตก)</Option>
              </Select>
            </Form.Item>
            {this.state.add_discount_type === 'เปอร์เซ็นต์' ?
              <>
                <Form.Item label="ยอดถอนสูงสุด" required>
                  <Radio.Group onChange={(e) => this.setState({ add_discount_max_withdraw_format: e.target.value })} value={this.state.add_discount_max_withdraw_format}>
                    <Space direction="vertical">
                      <Radio value={1}>กำหนดอัตโนมัติ <Tag color="magenta">(ยอดฝากแรก * จำนวนเทิร์น) = ยอดถอนสูงสุด</Tag></Radio>
                      <Radio value={2}>จำนวนเท่าของยอดฝาก <Tag color="lime">2-10 เท่า</Tag></Radio>
                      <Radio value={3}>ระบุเอง <Tag color="lime">11-999,999 เครดิต</Tag></Radio>
                      <Radio value={0}><Tag color="magenta">ไม่จำกัด</Tag></Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
                {this.state.add_discount_max_withdraw_format > 1 ?
                  <Form.Item name="max_withdraw" required rules={[{ required: true, message: 'กรุณากรอกยอดถอนสูงสุด!' }]}>
                    <InputNumber
                      min={this.state.add_discount_max_withdraw_format === 2 ? 2 : 11}
                      max={this.state.add_discount_max_withdraw_format === 2 ? 10 : 9999999}
                      style={{ width: '100%' }}
                      onChange={(val) => this.setState({ add_discount_max_withdraw: val })}
                      value={this.state.add_discount_max_withdraw}
                    />
                  </Form.Item>
                  : <></>}
              </>
              :
              <>
                <Form.Item label="ยอดถอนสูงสุด" required>
                  <Radio.Group onChange={(e) => this.setState({ add_discount_max_withdraw_format: e.target.value })} value={this.state.add_discount_max_withdraw_format}>
                    <Space direction="vertical">
                      <Radio value={3}>ระบุเอง <Tag color="lime">1-999,999 เครดิต</Tag></Radio>
                      <Radio value={0}><Tag color="magenta">ไม่จำกัด</Tag></Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
                {this.state.add_discount_max_withdraw_format !== 0 ?
                  <Form.Item name="max_withdraw" required rules={[{ required: true, message: 'กรุณากรอกยอดถอนสูงสุด!' }]}>
                    <InputNumber min={1} max={9999999} style={{ width: '100%' }}
                      onChange={(val) => this.setState({ add_discount_max_withdraw: val })}
                      value={this.state.add_discount_max_withdraw}
                    />
                  </Form.Item>
                : <></>}
              </> 
            }
            <hr />

            <Form.Item label="เงื่อนไข">
              <Row gutter={[16, 0]}>
                <Col span={24}>
                  <Form.Item>
                    <Radio.Group onChange={(e) => this.setState({ add_discount_condition: e.target.value })} value={this.state.add_discount_condition}>
                      <Radio value={0}>ใช้ได้ครั้งเดียว /คน</Radio>
                      <Radio value={1}>ใช้ได้วันล่ะครั้ง /คน</Radio>
                      <Radio value={2}>ใช้ได้วันหลายครั้ง /คน</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item label="เริ่มต้น-สิ้นสุด (วัน/เวลา)" name="date" rules={[{ required: true, message: 'กรุณาระบุช่วงจัดโปรโมชั่น!' }]}>
                    <RangePicker
                      format="YYYY-MM-DD HH:mm:ss"
                      disabledDate={(e) => this.disabledDate(e)}
                      onChange={(e) => this.setState({ add_discount_start_date: e[0]._d, add_discount_end_date: e[1]._d })}
                      showTime={{
                        hideDisabledOptions: true,
                        defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
                      }}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item label="ช่วงเวลารับโปรฯ">
                    <Radio.Group onChange={(e) => this.setState({ add_discount_s_time: e.target.value })} value={this.state.add_discount_s_time}>
                      <Radio value={0}>ตลอดทั้งวัน</Radio>
                      <Radio value={1}>ระบุ</Radio>
                    </Radio.Group>
                  </Form.Item>
                  
                  {this.state.add_discount_s_time === 1 ?
                    <Form.Item >
                      <RangePicker
                        picker="time"
                        onChange={(e) => {
                          this.setState({ add_discount_time: `${new Date(e[0]._d).toLocaleTimeString('sv-SE')}-${new Date(e[1]._d).toLocaleTimeString('sv-SE')}` })
                        }}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  : <></>}
                </Col>

                <Col span={24}>
                  <Collapse defaultActiveKey={['0']} onChange={(e) => console.log(e)}>
                    <Panel header="เงื่อนไขเพิ่มเติม" key="1">
                      <Row gutter={[16, 0]}>
                        <Col span={12}>
                          <Form.Item label="ยอดฝากสะสม">
                            <InputNumber min={0} defaultValue={0} style={{ width: '100%' }} onChange={(val) => this.setState({ add_discount_deposit_collect: val })} />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item label="ยอดถอนสะสม">
                            <InputNumber min={0} defaultValue={0} style={{ width: '100%' }} onChange={(val) => this.setState({ add_discount_withdraw_collect: val })} />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item label="โปรโมชั่นแนะนำเพื่อน">
                            <Radio.Group onChange={(e) => this.setState({ add_discount_affiliate: e.target.value })} value={this.state.add_discount_affiliate}>
                              <Radio value={0}>รองรับ</Radio>
                              <Radio value={1}>ไม่รองรับ</Radio>
                            </Radio.Group>
                          </Form.Item>

                          {this.state.add_discount_affiliate === 0 ?
                            <Form.Item label="โบนัส" required extra={this.state.add_discount_type === 'เปอร์เซ็นต์' ? 'จำนวนโบนัสเป็นเปอร์เซ็นต์ของยอดฝาก' : ''}>
                              <InputNumber
                                addonAfter={this.state.add_discount_type === 'เปอร์เซ็นต์' ? '%' : 'บาท'}
                                value={this.state.add_discount_affiliate_bonus}
                                style={{ width: this.state.add_discount_type === 'เปอร์เซ็นต์' ? '40%' : '100%' }}
                                onChange={(value) => this.setState({ add_discount_affiliate_bonus: value })}
                              />
                              {this.state.add_discount_type === 'เปอร์เซ็นต์' ?
                                <InputNumber addonBefore="สูงสุด" addonAfter='บาท' value={this.state.add_discount_affiliate_bonus_max} style={{ width: '60%' }} onChange={(value) => this.setState({ add_discount_affiliate_bonus_max: value })}/>
                                : null
                              }
                            </Form.Item>
                            : null
                          }
                        </Col>
                        <Col span={12}>
                          <Form.Item label="จำนวนเพื่อนที่แนะนำ (ขั้นต่ำ)">
                            <InputNumber min={0} defaultValue={0} style={{ width: '100%' }} onChange={(val) => this.setState({ add_discount_affiliate_amount: val })} />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item label="โปรฯก่อนหน้าที่ต้องรับ (โปรฯขั้นบันได)">
                            <Select defaultValue="" style={{ width: '100%' }} onChange={(val) => this.setState({ add_discount_probefore: val })}>
                              <Option key={99999} value=''>ไม่ระบุ</Option>
                              {this.PromotionList()}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Panel>
                  </Collapse>
                </Col>
              </Row>
            </Form.Item>

            <hr />

            <Form.Item label="แสดงหน้าเว็บ">
              <Radio.Group onChange={(e) => this.setState({ add_discount_image_show: e.target.value })} value={this.state.add_discount_image_show}>
                <Radio value={1}>แสดง</Radio>
                <Radio value={2}>ไม่แสดง</Radio>
              </Radio.Group>
            </Form.Item>

            {this.state.add_discount_image_show === 1 ?
              <>
                <Form.Item
                  label="รูปโปรโมชั่น"
                  style={{ width: '100%' }}
                  extra="อัตราส่วนที่แนะนำ 1.11:1 (ไม่เกิน 1200*1200)"
                  name="image"
                  rules={[{ required: true, message: 'กรุณากรอกลิ้ง URL รูปภาพโปรโมชั่น!' }, { type: 'url', warningOnly: true }]}
                >
                  <Space
                    direction="vertical"
                    style={{
                      width: '100%',
                    }}
                    size="large"
                  >
                    <Upload
                      listType="picture"
                      maxCount={1}
                      accept='.png,.jpg,.jpeg,.webp,.gif'
                      beforeUpload={(file) => {
                        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp' || file.type === 'image/gif';
                        if (!isJpgOrPng) {
                          return x0p('', 'You can only upload JPG/PNG file!', 'error', false);
                        }
                        const isLt1M = file.size / 1024 / 1024 < 1;
                        if (!isLt1M) {
                          return x0p('', 'Image must smaller than 1MB!', 'error', false);
                        }
                        
                        this.setState({ add_discount_image_file: file })
                        return false
                      }}
                    >
                      <Button icon={<UploadOutlined />}>Upload</Button>
                    </Upload>
                  </Space>
                </Form.Item>
              </>
              : <></>
            }

            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                เพิ่มโปรโมชั่น
              </Button>
            </Form.Item>
          </Form>
        </Modal>

        {/* แก้ไขโปรโมชั่น */}
        {this.state.isModalEditPromotionVisible ?
          <Modal
            title="แก้ไขโปรโมชั่น" style={{ top: 20 }}
            open={this.state.isModalEditPromotionVisible}
            footer={[]}
            maskClosable={false}
            onCancel={() => this.setState({
              isModalEditPromotionVisible: false,
            })}>
            <Row>
              <Col span={16}>
                {/* <Title level={2}><Paragraph copyable>{this.state.edit_discount_coupon_code}</Paragraph></Title> */}
              </Col>
              <Col span={8} style={{ textAlign: 'right' }}>
                สถานะ : <Switch checkedChildren="เปิด" unCheckedChildren="ปิด" checked={this.state.edit_discount_status === 'เปิด' ? true : false} onChange={(val) => this.setState({ edit_discount_status: val ? 'เปิด' : 'ปิด' })} />
              </Col>
            </Row>

            <Form layout="vertical" onFinish={() => this.editDiscount()}>
              <Form.Item label="ชื่อโปรโมชั่น" required>
                <Input value={this.state.edit_discount_name} style={{ width: '100%' }} onChange={(e) => this.setState({ edit_discount_name: e.target.value })} />
              </Form.Item>
              <Form.Item label="ประเภท" required>
                <Select value={this.state.edit_discount_type} style={{ width: '100%' }} onChange={(val) => this.setState({ edit_discount_type: val })} disabled>
                  <Option value="บาท">บาท</Option>
                  <Option value="เปอร์เซ็นต์">เปอร์เซ็นต์</Option>
                </Select>
              </Form.Item>
              <Form.Item label={<>ฝากขั้นต่ำ <Tag color="blue">บาท</Tag> {this.state.edit_discount_type === 'บาท' ? <Tag color="red">0 คือไม่มีขั้นต่ำ</Tag> : ''} </>} extra={this.state.edit_discount_type === 'เปอร์เซ็นต์' ? 'ฝากขั้นต่ำอย่างน้อย 50' : ''} required>
                <InputNumber min={0} value={this.state.edit_discount_min_deposit} style={{ width: '100%' }} onChange={(val) => this.setState({ edit_discount_min_deposit: val })} addonAfter='บาท' />
              </Form.Item>
              <Form.Item label={<>โบนัสที่ได้รับ {this.state.edit_discount_type === 'บาท' ? <Tag color="blue">เครดิต</Tag> : <Tag color="purple">เปอร์เซ็นต์</Tag>}</>} tooltip={this.state.edit_discount_type === 'เปอร์เซ็นต์' ? 'โบนัสที่ได้รับ = (ยอดฝากแรก * โบนัส / 100)' : ''} required>
                <InputNumber value={this.state.edit_discount_amount} style={{ width: '100%' }} onChange={(val) => this.setState({ edit_discount_amount: val })} addonAfter={this.state.edit_discount_type === 'บาท' ? 'เครดิต' : '%'} />
              </Form.Item>
              <Form.Item label={<>โบนัสสูงสุด <Tag color="blue">เครดิต</Tag></>}>
                <InputNumber min={1} style={{ width: '100%' }} onChange={(val) => this.setState({ edit_discount_bonus_max: val })} value={this.state.edit_discount_type === 'บาท' ? this.state.edit_discount_amount : this.state.edit_discount_bonus_max} disabled={this.state.edit_discount_type === 'บาท' ? true : false} />
              </Form.Item>
              <Form.Item label="รูปแบบเทิร์น" required>
                <Select value={this.state.edit_discount_type_turn} style={{ width: '100%' }} onChange={(val) => this.OnChangeEditTypeTurn(val)}>
                  <Option value="0">ทั้งเทิร์นเล่นสะสม และ เทิร์นเครดิต</Option>
                  <Option value="1">เทิร์นเล่นสะสม</Option>
                  <Option value="2">เทิร์นเครดิต</Option>
                </Select>
              </Form.Item>
              <Form.Item label="กำหนดเทิร์น" tooltip={this.state.edit_discount_type === 'บาท' ? '(ยอดฝากขั้นต่ำ + จำนวนที่ได้รับ) * จำนวนเทิร์น = เทิร์นโอเวอร์' : '(ยอดฝากแรก + โบนัส) * จำนวนเทิร์น  = เทิร์นโอเวอร์'} required>
                <InputNumber min={1} value={this.state.edit_discount_turnover} style={{ width: '50%' }} onChange={(val) => this.setState({ edit_discount_turnover: val })} />
                {this.state.edit_discount_type === 'บาท' ?
                  <Input style={{ width: '50%' }} disabled value={(parseInt(this.state.edit_discount_min_deposit) + parseInt(this.state.edit_discount_amount)) * parseInt(this.state.edit_discount_turnover)} suffix="เทิร์นโอเวอร์" />
                  : <></>
                }
              </Form.Item>
              <Form.Item
                label="ประเภทเกมที่รองรับ"
                extra={<>ประเภทเกมจะแบ่งตามค่ายสามารถตรวจสอบได้ที่ <a href='/adminManage/howto#typegame' target='_blank'>คลิก</a></>}
                required>
                <Select value={this.state.edit_discount_type_game} style={{ width: '100%' }} onChange={(val) => this.OnChangeEditTypeGame(val)}>
                  <Option value="0">ทั้งหมด</Option>
                  <Option value="1">สล็อต</Option>
                  <Option value="2">บาคาร่า</Option>
                  <Option value="3">PG (ปรับแตก)</Option>
                </Select>
              </Form.Item>

              {this.state.edit_discount_type === 'เปอร์เซ็นต์' ?
                <>
                  <Form.Item label="ยอดถอนสูงสุด" required>
                    <Radio.Group onChange={(e) => this.setState({ edit_discount_max_withdraw_format: e.target.value })} value={this.state.edit_discount_max_withdraw_format}>
                      <Space direction="vertical">
                        <Radio value={1}>กำหนดอัตโนมัติ <Tag color="magenta">(ยอดฝากแรก * จำนวนเทิร์น) = ยอดถอนสูงสุด</Tag></Radio>
                        <Radio value={2}>จำนวนเท่าของยอดฝาก <Tag color="lime">2-10 เท่า</Tag></Radio>
                        <Radio value={3}>ระบุเอง <Tag color="lime">11-999,999 เครดิต</Tag></Radio>
                        <Radio value={0}><Tag color="magenta">ไม่จำกัด</Tag></Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                  {this.state.edit_discount_max_withdraw_format > 1 ?
                    <Form.Item rules={[{ required: true, message: 'กรุณากรอกยอดถอนสูงสุด!' }]}>
                      <InputNumber
                        min={this.state.edit_discount_max_withdraw_format === 2 ? 2 : 11}
                        max={this.state.edit_discount_max_withdraw_format === 2 ? 10 : 9999999}
                        style={{ width: '100%' }}
                        onChange={(val) => this.setState({ edit_discount_max_withdraw: val })}
                        value={this.state.edit_discount_max_withdraw}
                      />
                    </Form.Item>
                    : <></>}
                </>
                :
                <>
                  <Form.Item label="ยอดถอนสูงสุด" required>
                    <Radio.Group onChange={(e) => this.setState({ edit_discount_max_withdraw_format: e.target.value })} value={this.state.edit_discount_max_withdraw_format}>
                      <Space direction="vertical">
                        <Radio value={3}>ระบุเอง <Tag color="lime">1-999,999 เครดิต</Tag></Radio>
                        <Radio value={0}><Tag color="magenta">ไม่จำกัด</Tag></Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                  {this.state.edit_discount_max_withdraw_format !== 0 ?
                    <Form.Item label="ยอดถอนสูงสุด" required rules={[{ required: true, message: 'กรุณากรอกยอดถอนสูงสุด!' }]}>
                      <InputNumber min={1} max={9999999} style={{ width: '100%' }}
                        onChange={(val) => this.setState({ edit_discount_max_withdraw: val })}
                        value={this.state.edit_discount_max_withdraw}
                      />
                    </Form.Item>
                  : <></>}
                </>
              }
              
              <hr />

              <Form.Item label="เงื่อนไข">
                <Row gutter={[16, 0]}>
                  <Col span={24}>
                    <Form.Item>
                      <Radio.Group onChange={(e) => this.setState({ edit_discount_condition: e.target.value })} value={this.state.edit_discount_condition}>
                        <Radio value={0}>ใช้ได้ครั้งเดียว /คน</Radio>
                        <Radio value={1}>ใช้ได้วันละครั้ง /คน</Radio>
                        <Radio value={2}>ใช้ได้วันหลายครั้ง /คน</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item label="เริ่มต้น-สิ้นสุด (วัน/เวลา)" required>
                      <RangePicker
                        format="DD-MM-YYYY HH:mm:ss"
                        disabledDate={(e) => this.disabledDate(e)}
                        onChange={(e) => this.setState({ edit_discount_start_date: e[0]._d, edit_discount_end_date: e[1]._d })}
                        value={[moment(this.state.edit_discount_start_date ? formateDateTimeDMYT(this.state.edit_discount_start_date) : '20-02-2020 00:00:00', 'DD-MM-YYYY HH:mm:ss'), moment(this.state.edit_discount_end_date ? formateDateTimeDMYT(this.state.edit_discount_end_date) : '20-02-2020 00:00:00', 'DD-MM-YYYY HH:mm:ss')]}
                        showTime={{ hideDisabledOptions: true }}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item label="ช่วงเวลารับโปรฯ">
                      <Radio.Group onChange={(e) => this.setState({ edit_discount_s_time: e.target.value })} value={this.state.edit_discount_s_time}>
                        <Radio value={0}>ตลอดทั้งวัน</Radio>
                        <Radio value={1}>ระบุ</Radio>
                      </Radio.Group>
                    </Form.Item>
                    
                    {this.state.edit_discount_s_time === 1 ?
                      <Form.Item >
                        <RangePicker
                          picker="time"
                          defaultValue={[moment(this.state.edit_discount_time === '' ? new Date('2023-02-02 00:00:00') : new Date('2023-02-02 ' + this.state.edit_discount_time.split('-')[0]), "HH:ii:ss"), moment(this.state.edit_discount_time === '' ? new Date('2023-02-02 23:59:59') : new Date('2023-02-02 ' + this.state.edit_discount_time.split('-')[1]), "HH:ii:ss")]}
                          onChange={(e) => {
                            this.setState({ edit_discount_time: `${new Date(e[0]._d).toLocaleTimeString('sv-SE')}-${new Date(e[1]._d).toLocaleTimeString('sv-SE')}` })
                          }}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    : <></>}
                  </Col>
                  
                  <Col span={24}>
                    <Collapse defaultActiveKey={['0']}>
                      <Panel header="เงื่อนไขเพิ่มเติม" key="1">
                        <Row gutter={[16, 0]}>
                          <Col span={12}>
                            <Form.Item label="ยอดฝากสะสม">
                              <InputNumber min={0} value={this.state.edit_discount_deposit_collect} style={{ width: '100%' }} onChange={(val) => this.setState({ edit_discount_deposit_collect: val })} />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item label="ยอดถอนสะสม">
                              <InputNumber min={0} value={this.state.edit_discount_withdraw_collect} style={{ width: '100%' }} onChange={(val) => this.setState({ edit_discount_withdraw_collect: val })} />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item label="โปรโมชั่นแนะนำเพื่อน">
                              <Radio.Group onChange={(e) => this.setState({ edit_discount_affiliate: e.target.value })} value={this.state.edit_discount_affiliate}>
                                <Radio value={0}>รองรับ</Radio>
                                <Radio value={1}>ไม่รองรับ</Radio>
                              </Radio.Group>
                            </Form.Item>
                            {this.state.edit_discount_affiliate === 0 ?
                              <Form.Item label="โบนัส" required extra={this.state.edit_discount_type === 'เปอร์เซ็นต์' ? 'จำนวนโบนัสเป็นเปอร์เซ็นต์ของยอดฝาก' : ''}>
                                <InputNumber
                                  addonAfter={this.state.edit_discount_type === 'เปอร์เซ็นต์' ? '%' : 'บาท'}
                                  value={this.state.edit_discount_affiliate_bonus}
                                  style={{ width: this.state.edit_discount_type === 'เปอร์เซ็นต์' ? '40%' : '100%' }}
                                  onChange={(value) => this.setState({ edit_discount_affiliate_bonus: value })}
                                />
                                {this.state.edit_discount_type === 'เปอร์เซ็นต์' ?
                                  <InputNumber addonBefore="สูงสุด" addonAfter='บาท' value={this.state.edit_discount_affiliate_bonus_max} style={{ width: '60%' }} onChange={(value) => this.setState({ edit_discount_affiliate_bonus_max: value })}/>
                                  : null
                                }
                              </Form.Item>
                              : null
                            }
                          </Col>
                          <Col span={12}>
                            <Form.Item label="จำนวนเพื่อนที่แนะนำ (ขั้นต่ำ)">
                              <InputNumber min={0} value={this.state.edit_discount_affiliate_amount} style={{ width: '100%' }} onChange={(val) => this.setState({ edit_discount_affiliate_amount: val })} />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item label="โปรก่อนหน้าที่ต้องรับ (โปรฯขั้นบันได)">
                              <Select value={this.state.edit_discount_probefore} style={{ width: '100%' }} onChange={(val) => this.setState({ edit_discount_probefore: val })}>
                                <Option key={99999} value=''>ไม่ระบุ</Option>
                                {this.PromotionList()}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Panel>
                    </Collapse>
                  </Col>
                </Row>
              </Form.Item>

              <hr />

              <Form.Item label="แสดงหน้าเว็บ">
                <Radio.Group onChange={(e) => this.setState({ edit_discount_image_show: e.target.value })} value={this.state.edit_discount_image_show}>
                  <Radio value={1}>แสดง</Radio>
                  <Radio value={2}>ไม่แสดง</Radio>
                </Radio.Group>
              </Form.Item>

              {this.state.edit_discount_image_show === 1 ?
                <>
                  <Form.Item
                    label="รูปโปรโมชั่น xsx"
                    style={{ width: '100%' }}
                    extra="อัตราส่วนที่แนะนำ 1.11:1"
                    required
                  >
                    <Space
                      direction="vertical"
                      style={{
                        width: '100%',
                      }}
                      size="large"
                    >
                      <Upload
                        listType="picture"
                        maxCount={1}
                        accept='.png,.jpg,.jpeg,.webp,.gif'
                        defaultFileList={this.state.defaultFileList}
                        beforeUpload={(file) => {
                          const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp' || file.type === 'image/gif';
                          if (!isJpgOrPng) {
                            return x0p('', 'You can only upload JPG/PNG file!', 'error', false);
                          }
                          const isLt1M = file.size / 1024 / 1024 < 1;
                          if (!isLt1M) {
                            return x0p('', 'Image must smaller than 1MB!', 'error', false);
                          }
                          
                          this.setState({ edit_discount_image_file: file })
                          return false
                        }}
                      >
                        <Button icon={<UploadOutlined />}>Upload</Button>
                      </Upload>
                    </Space>
                  </Form.Item>
                </>
                : <></>
              }
              <br />
              <br />
              <Form.Item>
                <Button type="primary" htmlType="submit" block>
                  แก้ไขโปรโมชั่น
                </Button>
              </Form.Item>
            </Form>
          </Modal>
          : null
        }

        {/* รายละเอียด */}
        {this.state.isModalDetailPromotionVisible ?
          <Modal
            title="รายละเอียด"
            open={this.state.isModalDetailPromotionVisible}
            style={{ top: 20, minWidth: 1050 }}
            footer={null}
            onCancel={() => this.setState({
              isModalDetailPromotionVisible: false,
            })}>
            <Detail discount_id={this.state.detail_discount_id} />
          </Modal>
          : null
        }

        {/* รายการโค้ดโปรโมชั่น */}
        {this.state.isModalCodePromotionVisible ?
          <Modal
            title="รายการโค้ดโปรโมชั่น"
            open={this.state.isModalCodePromotionVisible}
            style={{ top: 20, minWidth: 800 }}
            footer={null}
            onCancel={() => this.setState({
              isModalCodePromotionVisible: false,
            })}>
            <CodePage discount_id={this.state.detail_discount_id} />
          </Modal>
          : null
        }
      </>
    );
  }
}

export default withRouter(discount);
