import React, { Component } from 'react';
import { Table, Card, Button, PageHeader, Row, Col, Switch, Tag, Select, InputNumber, Input, Space, Statistic, DatePicker, Typography, Image, Upload } from 'antd';
import { SaveOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { URL, WEB_NAME } from '../../../Util/Config'
import axios from 'axios';
import x0p from 'x0popup';
import DateTime from 'node-datetime';
import moment from 'moment';

const { Title, Text } = Typography;
const { Option } = Select;

const { RangePicker } = DatePicker;

const dateFormat = 'YYYY/MM/DD';
var dateOffset30 = DateTime.create();
dateOffset30.offsetInDays(-30)
var dateStart = dateOffset30.format('Y/m/d');
var dateEnd = DateTime.create().format('Y/m/d');

const DescriptionItem = ({ title, content }) => (
  <div className="site-description-item-profile-wrapper">
    <p className="site-description-item-profile-p-label">{title}:</p>
    {content}
  </div>
);

export default class wheel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          title: 'ช่องหมายเลข',
          dataIndex: 'wheel_id',
          align: 'center',
          render: (text, record, index) => (
            <>
              ช่องที่ {text}
            </>
          )
        },
        {
          title: 'ประเภท รางวัล',
          dataIndex: 'wheel_type',
          align: 'center',
          render: (text, record, index) => (
            <>
              <Select
                defaultValue={text}
                onChange={(val) => this.updateWheelType(record.wheel_id, val)}
                style={{ width: 300 }}
              >
                <Option value="เครดิต">เครดิต</Option>
                <Option value="เพชร">เพชร</Option>
                <Option value="ไม่ระบุ">ไม่ระบุ</Option>
              </Select>
            </>
          )
        },
        {
          title: 'จำนวน รางวัล',
          dataIndex: 'wheel_amount',
          align: 'center',
          render: (text, record, index) => (
            <>
              <InputNumber
                // min={this.state.data[index].wheel_type === 'ไม่ระบุ' ? 0 : 1}
                // disabled={this.state.data[index].wheel_type === 'ไม่ระบุ' ? true : false}
                defaultValue={text}
                style={{ width: 300 }}
                onChange={(val) => {
                  this.setState(prevState => ({
                    data: this.state.data.map(
                      obj => (obj.wheel_id === record.wheel_id ? Object.assign(obj, { wheel_amount: val }) : obj)
                    )
                  }))
                  this.setState({ record: record })
                }}
              />
            </>

          )
        },
        {
          title: 'เปอร์เซ็นต์ ถูกรางวัล',
          dataIndex: 'wheel_percent',
          align: 'center',
          render: (text, record, index) => (
            <>
              <InputNumber
                defaultValue={text}
                formatter={value => `${value}%`}
                parser={value => value.replace('%', '')}
                style={{ width: 300 }}
                onChange={(val) => {
                  this.setState(prevState => ({
                    data: this.state.data.map(
                      obj => (obj.wheel_id === (index + 1) ? Object.assign(obj, { wheel_percent: val }) : obj)
                    )
                  }))
                  this.setState(prevState => ({
                    total_precent: this.state.data.map(item => item.wheel_percent).reduce((prev, next) => prev + next)
                  }))
                  this.setState({ record: record })
                }}
              />
            </>

          )
        },
      ],
      data: [],
      amount: 0,
      status: 'ปิด',
      total_precent: 0,
      image_wheel: '',
      columns2: [
        {
          title: 'ลำดับ',
          dataIndex: 'row_num',
          sorter: (a, b) => a.row_num - b.row_num,
        },
        {
          title: 'ผู้รับรางวัล',
          dataIndex: 'title',
          ...this.getColumnSearchProps('title'),
        },
        {
          title: 'ประเภทรางวัล',
          dataIndex: 'log_wheel_type',
          filters: [
            {
              text: 'เครดิต',
              value: 'เครดิต',
            },
            {
              text: 'เพชร',
              value: 'เพชร',
            },
            {
              text: 'ไม่ระบุ',
              value: 'ไม่ระบุ',
            },
          ],
          onFilter: (value, record) => record.log_wheel_type.indexOf(value) === 0,
          render: (text, record) => (
            text === 'เครดิต' ?
              <Tag color='gold'>{text}</Tag>
              :
              text === 'เพชร' ?
                <Tag color='cyan'>{text}</Tag>
                :
                <Tag color='red'>{text}</Tag>
          )
        },
        {
          title: 'จำนวนที่ได้รับ',
          dataIndex: 'log_wheel_amount',
          align: 'right',
          sorter: (a, b) => a.log_wheel_amount - b.log_wheel_amount,
          render: (text, record) => (
            <Tag color='green'>{text}</Tag>
          )
        },
        {
          title: 'วัน/เวลาที่รับรางวัล',
          dataIndex: 'created_at',
          ...this.getColumnSearchProps('created_at'),
        }
      ],
      data2: [],
      detail: [],
      summary: [],
      admin_type: null,
      admin_token: null
    };
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem('user'))
    this.setState({ admin_type: user.user.type, admin_token: user.accessToken })

    this.getData()
    this.getDataStatus()
    this.setTable(dateStart, dateEnd)
    this.getDataLogSummary()
  }

  setTable(startDate, endDate) {
    // console.log(startDate, endDate)
    var dt = DateTime.create(startDate);
    startDate = dt.format('Y-m-d');
    dt = DateTime.create(endDate);
    endDate = dt.format('Y-m-d');

    this.getDataLog(startDate, endDate)
  }

  getData() {
    fetch(`${URL}/api/v1/wheel/all`)
      .then((response) => response.json())
      .then((json) => this.setState({ data: json.result }))
      .then(() => {
        this.setState(prevState => ({
          total_precent: this.state.data.map(item => item.wheel_percent).reduce((prev, next) => prev + next)
        }))
      })
      // .then(() => console.log(this.state.data))
      .catch((error) => {
        console.log('fetch log failed', error);
      });
  }

  getDataStatus() {
    fetch(`${URL}/api/v1/wheel/event`)
      .then((response) => response.json())
      .then((json) => this.setState({ status: json.result.event_status, amount: json.result.event_amount, image_wheel: json.result.event_image }))
      .then(() => console.log(this.state.status, this.state.amount, this.state.image_wheel))
      .catch((error) => {
        console.log('fetch log failed', error);
      });
  }

  getDataLogSummary() {
    fetch(`${URL}/api/v1/wheel/log/summary`)
      .then((response) => response.json())
      .then((json) => this.setState({ summary: json.result }))
      .then(() => console.log(this.state.summary))
      .catch((error) => {
        console.log('fetch log failed', error);
      });
  }

  getDataLog(startDate, endDate) {
    fetch(`${URL}/api/v1/wheel/log/start/${startDate}/end/${endDate}`)
      .then((response) => response.json())
      .then((json) => this.setState({ data2: json.result.list, detail: json.result.summary }))
      .then(() => console.log(this.state.detail))
      .catch((error) => {
        console.log('fetch log failed', error);
      });
  }

  // ------------------------------------------------------------------ FILTER ----------------------------------------------------------- 
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  // ------------------------------------------------------------------ FILTER ----------------------------------------------------------- 


  async updateStatus(status) {
    x0p({
      title: 'ยืนยัน!!',
      text: `คุณต้องการ "${status === true ? 'เปิด' : 'ปิด'}" ใช้งานกงล้อใช่มั้ย??`,
      icon: 'info',
      buttons: [
        {
          type: 'cancel',
          text: 'ยกเลิก',
        },
        {
          type: 'info',
          text: 'ยืนยัน',
          showLoading: true
        }
      ]
    }).then(async (alert) => {
      if (alert.button === 'info') {
        const body = {
          status: status === true ? 'เปิด' : 'ปิด'
        }
        await axios.post(`${URL}/api/v1/wheel/update/status`, body, {
          headers: {
            'Authorization': `Bearer ${this.state.admin_token}`,
          }
        })
          .then(res => {
            const data = res.data
            if (data.status) {
              this.setState({ status: status === true ? 'เปิด' : 'ปิด' })
              x0p('สำเร็จ', null, 'ok', false);
            } else {
              x0p('', data.message, 'error', false);
            }
          })
          .catch(error => {
            console.log(error)
            x0p('เกิดข้อผิดพลาด!!', null, 'error', false);
          })
      }
    })
  }

  updateWheel() {
    const { data, amount } = this.state
    const sumPercent = data.map(item => item.wheel_percent).reduce((prev, next) => prev + next);

    if (sumPercent !== 100) {
      x0p('', 'กรุณาตรวจสอบเปอร์เซนทั้งหมด รวมกันแล้วต้องเท่ากับ 100', 'warning', false);

    } else {
      x0p({
        title: `ยืนยัน`,
        icon: 'info',
        buttons: [
          {
            type: 'cancel',
            text: 'ยกเลิก',
          },
          {
            type: 'info',
            text: 'ยืนยัน',
            showLoading: true
          }
        ]
      }).then(async (alert) => {
        if (alert.button === 'info') {
          const body = {
            wheel_data: data,
            wheel_amount: amount
          }
          await axios.post(URL + "/api/v1/wheel/update/all", body, {
            headers: {
              'Authorization': `Bearer ${this.state.admin_token}`,
            }
          })
            .then(res => {
              var data = res.data
              if (data.status) {
                this.getData()
                x0p('สำเร็จ!!', null, 'ok', false);
              } else {
                x0p('', data.message, 'error', false);
              }
            })
            .catch(error => {
              console.log(error)
              x0p('การเชื่อมต่อผิดพลาด updateWheel', null, 'error', false);
            })
        }
      })
    }


  }

  async updateWheelImage(image) {
    const formData = new FormData();
    formData.append('image', image);

    const res = await fetch(`${URL}/api/v1/wheel/update/image`, {
          method: "POST",
          body: formData,
          headers: {
            'Authorization': `Bearer ${this.state.admin_token}`,
          }
    }).then((res) => res.json())

    if (res.status) {
      this.getDataStatus()
      x0p('ทำรายการสำเร็จ!!', null, 'ok', false);
    } else {
      console.log(res)
      x0p('', res.message, 'error', false);
    }
  }

  updateWheelType(index, value) {
    this.setState(prevState => ({
      data: this.state.data.map(
        obj => (obj.wheel_id === (index) ? Object.assign(obj, { wheel_type: value }) : obj)
      )
    }))

    if (value === 'ไม่ระบุ') {
      this.setState(prevState => ({
        data: this.state.data.map(
          obj => (obj.wheel_id === (index) ? Object.assign(obj, { wheel_amount: 0 }) : obj)
        )
      }))
    }

    console.log(this.state.data[index - 1])
  }

  setCardSummary() {
    let list = this.state.summary.map((item, index) =>
      <Col flex="1 1 250px">
        <Card>
          <Statistic
            title={item.unit === 'ไม่ระบุ' ? `${item.unit}ทั้งหมด` : `${item.unit}ที่แจกทั้งหมด`}
            value={item.amount}
            valueStyle={{ color: '#531CAA' }}
            suffix={item.unit === 'ไม่ระบุ' ? 'ครั้ง' : item.unit}
          />
        </Card>
      </Col>
    );
    return (list)
  }

  setDetail() {
    let list = this.state.detail.map((item, index) =>
      <Col span={8}>
        <DescriptionItem title={item.unit} content={<><Text style={{ color: '#EF9900' }}>{item.amount}</Text> {item.unit === 'ไม่ระบุ' ? 'ครั้ง' : item.unit}</>} />
      </Col>
    );
    return (list)
  }

  render() {
    const routes = [
      {
        breadcrumbName: WEB_NAME,
      },
      {
        breadcrumbName: 'กิจกรรมเสริม',
      },
      {
        breadcrumbName: 'กงล้อ',
      },
    ]
    return (
      <>
        <PageHeader
          className="site-page-header"
          title="กงล้อ"
          breadcrumb={{ routes }}
        // subTitle="This is a subtitle"
        />
        <Card>
          <Row className='justify-content-between' style={{ marginBottom: 20 }}>
            <Col>
              <h6>เงื่อนไขการเล่น</h6>
              <p>ลูกค้าสามารถหมุนกงล้อ โดยแลก <InputNumber
                value={this.state.amount}
                min={0}
                formatter={value => `${value}`}
                onChange={(value) => this.setState({ amount: value })}
              /> เพชร ต่อ 1 ครั้ง</p>
              <p><strong>ประเภทรางวัล</strong> <Tag color="gold">เครดิต</Tag>= ยอดเงินในระบบเกมหรือเครดิต (ใช้ในการเล่นเกมได้), <Tag color="cyan">เพชร</Tag>= แต้มหรือเพชร เป็นยอดเงินสมมุติ (ใช้เล่นเกมไม่ได้)</p>
            </Col>
            <Col style={{ textAlign: 'right', marginTop: 15 }}>
              สถานะการใช้งาน :  <Switch checkedChildren="เปิด" unCheckedChildren="ปิด" checked={this.state.status === 'เปิด' ? true : false} onChange={(e) => this.updateStatus(e)} disabled={this.state.admin_type === 'G' ? true : false} />
            </Col>
          </Row>
          <Row style={{ marginBottom: 20 }}>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Button type="primary" icon={<SaveOutlined />} onClick={() => this.updateWheel()} disabled={this.state.admin_type === 'G' ? true : false}>บันทึก</Button>
            </Col>
          </Row>
          <Table columns={this.state.columns} dataSource={this.state.data} pagination={false} scroll={{ x: 1000 }} rowKey={record => record.log_id} />
          <br />
          <div style={{ textAlign: 'right', marginRight: 100 }}>
            <p>รวมเปอร์เซนทั้งหมด = <Tag color="purple"><span>{this.state.total_precent}</span>%</Tag></p>
          </div>

          <Title level={4}>กงล้อนำโชค</Title>
          <Row className='text-center'>
            <Col span={12}>
              <Title level={5}>รูปตัวอย่าง</Title>
              <Image
                width={300}
                height={300}
                src="/images/wheel/WheelEx.png"
              />
              <p className='text-danger'>**รูปมีขนาด 300 * 300 px เท่านั้น</p>
              <p className=''>รูปกงล้อเป็นส่วนด้านในที่ใช้หมุนไม่จำเป็นต้องใส่ขอบ</p>
              <p className=''>รูปแต่ละช่องต้องตรงกับข้อมูลที่ใส่ในตารางด้านบน</p>
              <p className=''>แนะนำให้เอารูปตัวอย่างไปแก้ไข</p>
            </Col>
            <Col span={12}>
              <Title level={5}>กงล้อที่ใช้อยู่</Title>
              <Image
                width={300}
                height={300}
                src={this.state.image_wheel.includes('http') ? this.state.image_wheel : URL + '/public/images/wheel/' + this.state.image_wheel}
              />
              <br/>
              <br/>
              <Space
                direction="vertical"
                style={{
                  width: '100%',
                }}
                size="large"
              >
                <Upload
                  // listType="picture"
                  maxCount={1}
                  accept='.png,.jpg,.jpeg,.webp,.gif'
                  // defaultFileList={this.state.defaultFileList}
                  beforeUpload={(file) => {
                    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp' || file.type === 'image/gif';
                    if (!isJpgOrPng) {
                      return x0p('', 'You can only upload JPG/PNG file!', 'error', false);
                    }
                    const isLt1M = file.size / 1024 / 1024 < 1;
                    if (!isLt1M) {
                      return x0p('', 'Image must smaller than 1MB!', 'error', false);
                    }

                    this.updateWheelImage(file)
                    return false
                  }}
                >
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
              </Space>
            </Col>
          </Row>
        </Card>

        <br />
        <Row gutter={[16, 16]}>
          <Col flex="1 1 250px">
            <Card>
              <Statistic
                title="จำนวนที่รับรางวัลทั้งหมด"
                value={this.state.data2.length}
                valueStyle={{ color: '#531CAA' }}
                suffix="ครั้ง"
              />
            </Card>
          </Col>
          {this.setCardSummary()}
        </Row>
        <br />
        <Card>
          <h5>ประวัติการรับรางวัล</h5>
          <RangePicker
            style={{ width: '100%', marginBottom: 10, marginTop: 10 }}
            defaultValue={[moment(dateStart, dateFormat), moment(dateEnd, dateFormat)]}
            format={dateFormat}
            onChange={(e) => this.setTable(e[0]._d, e[1]._d)} />
          <br />
          <Row>
            {this.setDetail()}
          </Row>
          <br />
          <Table columns={this.state.columns2} dataSource={this.state.data2} pagination={{ pageSize: 20 }} scroll={{ x: 1000 }} rowKey={record => record.row_num} />
        </Card>
      </>
    );
  }
}
