import React, { Component } from 'react';
import { Table, Card, Button, Tag, Input, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { URL } from '../../../../Util/Config';

export default class recommend extends Component {
  constructor(props) {
    super(props);
    this.state = {
        columns: [
            {
              title: 'ลำดับ',
              dataIndex: 'row_num',
              defaultSortOrder: 'descend',
              sorter: (a, b) => a.row_num - b.row_num,
            },
            {
              title: 'username (ผู้สมัคร)',
              dataIndex: 'user_username',
            },
            {
              title: 'ชื่อผู้สมัคร',
              dataIndex: 'user_name',
            },
            {
              title: 'username (ผุ้แนะนำ)',
              dataIndex: 'advisor_username',
            },
            {
              title: 'ชื่อผู้แนะนำ',
              dataIndex: 'advisor_name',
            },
            {
              title: 'วัน/เวลา ที่สมัครสมาชิก',
              dataIndex: 'created_at',
            }
        ],
        data: [],
        columns2: [
          {
            title: 'ลำดับ',
            dataIndex: 'row_num',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.row_num - b.row_num,
          },
          {
            title: 'username (ผู้แนะนำ)',
            dataIndex: 'user_username',
            ...this.getColumnSearchProps('user_username'),
          },
          {
            title: 'username (ผู้ถูกแนะนำ)',
            dataIndex: 'user_advisor',
            ...this.getColumnSearchProps('user_username'),
          },
          {
            title: 'ค่าตอบแทนการแนะนำที่ได้รับ',
            dataIndex: 'log_affiliate_amount',
            align: 'right',
            render: (text, record) => (
                <Tag color='green'>{text} บาท</Tag>
            )
          },
          {
            title: 'วัน/เวลา ที่ได้รับค่าตอบแทน',
            dataIndex: 'created_at',
          },
        ],
        data2: [],
    };
  }

  componentDidMount(){
    this.getData()
    this.getData2()
  }

  // ------------------------------------------------------------------ START FILTER ----------------------------------------------------------- 
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  // ------------------------------------------------------------------ END FILTER ----------------------------------------------------------- 

  getData(){
    fetch(`${URL}/api/v1/log/recommend/all`)
      .then((response) => response.json())
      .then((json) => this.setState({ data: json.result }))
      .then(() => console.log(this.state.data))
      .catch((error) => {
        console.log('fetch data failed', error);
      });
  }

  getData2(){
    fetch(`${URL}/api/v1/log/affiliate/all`)
      .then((response) => response.json())
      .then((json) => this.setState({ data2: json.result }))
      // .then(() => console.log(this.state.data))
      .catch((error) => {
        console.log('fetch data failed', error);
      });
  }

  render() {
    return (
      <>
        <Card>
          {/* <Tabs defaultActiveKey="1">
              <TabPane tab="ประวัติการแนะนำเพื่อน" key="1"> */}
                <Table columns={this.state.columns} dataSource={this.state.data} pagination={{ pageSize: 25 }} scroll={{ x: 1000 }} rowKey={record => record.user_id}/>
              {/* </TabPane>
              <TabPane tab="ประวัติการได้รับค่าตอบแทนการแนะนำเพื่อน" key="2">
                <Text type="danger">**เงื่อนไขการแนะนำเพื่อน : สมาชิก (ผู้ถูกแนะนำ) ต้องรับโปรโมชั่นที่รองรับแนะนำเพื่อนก่อน สมาชิก (ผู้แนะนำ) จึงสามารถได้รับเครดิตฟรี</Text>
                <Table columns={this.state.columns2} dataSource={this.state.data2} pagination={{ pageSize: 20 }} scroll={{ x: 1000 }} rowKey={record => record.log_affiliate_id}/>
              </TabPane>
          </Tabs> */}
        </Card>
      </>
    );
  }
}
