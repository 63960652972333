import React, { Component } from 'react';
import { Table, Button, Tag, Alert, Select, Modal, message, Form, Tooltip } from 'antd';
import { FileSearchOutlined, QuestionCircleFilled } from '@ant-design/icons';
import axios from 'axios';
import x0p from 'x0popup';
import { URL } from '../../../../Util/Config';

const { Option } = Select;

export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userList: [],
            user: null,
            record: {},
            columns: [
                {
                    title: 'ลำดับ',
                    dataIndex: 'transaction_id',
                    render: (text, record) => (
                        <>
                            {record.row_num}
                            <Tag>
                                {text}
                            </Tag>
                        </>
                    )
                },
                {
                    title: 'วัน/เวลาที่ทำรายการ',
                    dataIndex: 'transaction_datetime',
                    width: 280,
                },
                {
                    title: 'ข้อสังเกต',
                    dataIndex: 'transaction_remark',
                    width: 350,
                    render: (text, record) => (
                        <>
                            {text} {record.transaction_note ? <Tooltip placement="top" title={record.transaction_note}><QuestionCircleFilled /></Tooltip> : null}
                        </>
                    )
                },
                {
                    title: 'จำนวนเงิน',
                    dataIndex: 'transaction_amount',
                    align: 'right',
                    render: (text, record) => (
                        <Tag color='green'>
                            {text} บาท
                        </Tag>
                    )
                },
                {
                    title: 'สถานะ',
                    dataIndex: 'transaction_status',
                    render: (text, record) => (
                        <Tag color='blue'>
                            {text}
                        </Tag>
                    )
                },
                {
                    title: 'สถานะสมาชิก',
                    dataIndex: 'status_member',
                    render: (text, record) => (
                        <>
                            {text === 'เป็นสมาชิก' ?
                                <Tag color='gold'>
                                    {text}
                                </Tag>
                                :
                                <Tag color='red'>
                                    {text}
                                </Tag>
                            }
                        </>
                    )
                },
                {
                    title: 'ยืนยันการรายฝาก',
                    dataIndex: '',
                    width: 280,
                    render: (text, record) => (
                        this.state.admin_type === 'G' ? null :
                            <>
                                <Button
                                    type="primary"
                                    style={{ marginRight: 5 }}
                                    onClick={() => {
                                        this.setState({ isModalVisible: true, user: null })
                                        this.setListUser(record)
                                    }}
                                    icon={<FileSearchOutlined />}
                                    disabled={this.state.admin_type === 'G' ? true : false}
                                >
                                    ยืนยันฝาก
                                </Button>
                                {/* {record.status_member === 'ไม่เป็นสมาชิก' ?  */}
                                <Button danger onClick={() => this.depositCancel(record)} icon={<FileSearchOutlined />} disabled={this.state.admin_type === 'G' ? true : false}>ยกเลิกฝาก</Button>
                                {/* : <></>
                        } */}
                            </>
                    )
                }
            ],
            data: [],
            admin_type: null
        };
    }

    componentDidMount() {
        const user = JSON.parse(localStorage.getItem('user'))
        if (!user) return this.props.history.push("/adminManage/login");
        this.setState({ admin_type: user.user.type, admin_token: user.accessToken, admin_name: user.user.name }, () => {
            this.getData()
        })
    }

    async getData() {
        await axios.get(URL + "/api/v2/transfer/deposit/wait", {
            headers: {
                'Authorization': `Bearer ${this.state.admin_token}`,
            }
        })
            .then(res => {
                var data = res.data
                if (data.status) {
                    this.setState({ data: data.result })
                }
            })
            .catch(error => {
                console.log(error)
                message.error('การเชื่อมต่อผิดพลาด!!');
            })
    }

    async setListUser(record) {
        this.setState({ record, userList: [], user: '' })
        const { status_member, transaction_remark } = record
        if (status_member === 'เป็นสมาชิก') {
            this.getUserFindByRemark(transaction_remark)

        } else {
            this.getUserUsernameAll()
        }
    }

    selectUserlist() {
        let list = this.state.userList.map((item, index) =>
            <Option value={item.user_userId + "," + item.user_username + "," + item.user_name + "," + item.user_id} key={index}>{item.user_username} {item.user_name} {item.user_phone}</Option>
        );
        return (list)
    }

    async getUserFindByRemark(remark) {
        var config = {
            method: 'post',
            url: `${URL}/api/v1/user/username/remark`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: { remark }
        };

        axios(config)
            .then(res => {
                var data = res.data
                // console.log(data.result)
                if (data.status) {
                    this.setState({ userList: data.result })
                }
            })
            .catch(error => {
                console.log(error)
                message.error('การเชื่อมต่อผิดพลาด!!');
            })
    }

    async getUserUsernameAll() {
        await axios.get(URL + "/api/v1/user/username/all")
            .then(res => {
                var data = res.data
                if (data.status) {
                    this.setState({ userList: data.result })
                }
            })
            .catch(error => {
                console.log(error)
                message.error('การเชื่อมต่อผิดพลาด!!');
            })
    }

    async depositSubmit() {
        const { user, record } = this.state

        if (!user) {
            message.error('กรุณาเลือกสมาชิกที่ทำรายการ!!');

        } else {
            const { transaction_amount, transaction_id } = record
            const user_userID = user.split(',')[0]
            const user_username = user.split(',')[1]
            const user_name = user.split(',')[2]
            const user_id = user.split(',')[3]

            x0p({
                title: 'ยืนยัน!!',
                text: `ฝากเครดิตให้ ${user_username} (${user_name}) \nจำนวน ${transaction_amount} บาท`,
                icon: 'info',
                buttons: [
                    {
                        type: 'cancel',
                        text: 'ยกเลิก',
                    },
                    {
                        type: 'info',
                        text: 'ยืนยัน',
                        showLoading: true
                    }
                ]
            }).then(async (alert) => {
                if (alert.button === 'info') {
                    const body = {
                        transaction_id: transaction_id,
                        user_id: user_id,
                    }
                    await axios.post(URL + "/api/v2/transfer/deposit/wait/submit", body, {
                        headers: {
                            'Authorization': `Bearer ${this.state.admin_token}`,
                        }
                    })
                        .then(res => {
                            const data = res.data
                            // console.log(data)
                            if (data.status) {
                                this.getData()
                                this.setState({ isModalVisible: false })
                                this.messageAlert(user_userID, `ทำรายการฝากเงินสำเร็จ!!\nจำนวน ${transaction_amount} บาท`)
                                x0p('ทำรายการฝากเงินสำเร็จ!!', null, 'ok', false);
                            } else {
                                x0p('ไม่สามารถทำรายการได้!!', data.message, 'error', false);
                            }
                        })
                        .catch(error => {
                            console.log(error)
                            x0p('การเชื่อมต่อผิดพลาด deposit/submit!!', null, 'error', false);
                        })
                }
            })
        }
    }

    async depositCancel(value) {
        const { transaction_id, transaction_amount, transaction_remark } = value
        x0p({
            title: 'ยืนยัน!!',
            text: `คุณต้องการ "ยกเลิก" การฝากเครดิตให้สมาชิกใช่มั้ย??`,
            icon: 'info',
            buttons: [
                {
                    type: 'cancel',
                    text: 'ยกเลิก',
                },
                {
                    type: 'info',
                    text: 'ยืนยัน',
                    showLoading: true
                }
            ]
        }).then(async (alert) => {
            if (alert.button === 'info') {
                const body = {
                    transaction_id: transaction_id,
                    transaction_note: `(รายการ รอยืนยัน) ${this.state.admin_name} ไม่ยืนยันการฝากจำนวน ${transaction_amount} บาท รายการ ${transaction_remark}`,
                }
                await axios.post(URL + "/api/v2/transfer/deposit/wait/cancel", body, {
                    headers: {
                        'Authorization': `Bearer ${this.state.admin_token}`,
                    }
                })
                    .then(res => {
                        const data = res.data
                        if (data.status) {
                            this.getData()
                            this.setState({ isModalVisible: false })
                            x0p('ทำรายยกเลิกการฝากสำเร็จ!!', null, 'ok', false);
                        } else {
                            x0p('เกิดข้อผิดพลาด!!', data.message, 'error', false);
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        x0p('การเชื่อมต่อผิดพลาด!! deposit/wait/cancel', null, 'error', false);
                    })
            }
        })
    }

    async messageAlert(user_userID, ms) {
        var body = {
            userID: user_userID, // userID Line
            message: ms
        }
        await axios.post(`${URL}/api/v1/line/messages/push`, body)
            .then(res => {
                console.log('ส่งข้อความให้ลูกค้าแล้ว')
            })
            .catch(error => {
                console.log(error);
                alert('การเชื่อมต่อผิดพลาด Post User')
            })
    }

    // ตรวจสอบการฝาก
    async checkDeposit() {
        x0p({
            title: 'ระบบกำลังตรวจสอบ',
            text: '',
            animationType: 'slideDown',
            icon: 'info',
            buttons: [],
        });
        await axios.get(URL + "/api/v1/transfer/deposit/admin/check")
            .then(res => {
                const data = res.data
                if (data.status) {
                    this.getData()
                    x0p('', data.message, 'ok');
                } else {
                    x0p('', data.message, 'error');
                }
            })
            .catch(error => {
                console.log(error)
                x0p('การเชื่อมต่อผิดพลาด!!', 'checkDeposit', 'error');
            })
    }

    render() {
        return (
            <>
                {/* <Row style={{ marginBottom: 20 }}>
                    <Col span={24} style={{ textAlign: 'right' }}>
                        <Button type="primary" icon={<RetweetOutlined />} onClick={() => this.checkDeposit()}>ตรวจสอบการฝาก</Button>
                    </Col>
                </Row> */}
                <Table columns={this.state.columns} dataSource={this.state.data} pagination={{ pageSize: 20 }} scroll={{ x: 1400 }} rowKey={record => record.row_num} />

                {/* ยืนยันทำรายการฝาก */}
                <Modal title="ยืนยันทำรายการฝาก" open={this.state.isModalVisible} onOk={() => this.depositSubmit()} onCancel={() => this.setState({ isModalVisible: false })}>
                    <Alert
                        message="ระบุบัญชีสมาชิก!!"
                        description={`โอนเงินให้สมาชิกจำนวน ${this.state.record.transaction_amount} บาท`}
                        type="warning"
                        style={{ marginBottom: 20 }}
                    />
                    <Form.Item extra="สามารถค้นหาได้โดยการพิมพ์ ยูส, ชื่อ หรือเบอร์">
                        <Select
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="กรุณาเลือกบัญชีสมาชิก"
                            optionFilterProp="children"
                            value={this.state.user}
                            filterOption={(input, option) => option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                            filterSort={(optionA, optionB) => optionA.children.toString().toLowerCase().localeCompare(optionB.children.toString().toLowerCase())}
                            onChange={(e) => this.setState({ user: e })}
                        >
                            {this.selectUserlist()}
                        </Select>
                    </Form.Item>

                </Modal>
            </>
        );
    }
}
