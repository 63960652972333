import React, { Component } from 'react';
import { Table, Card, Button, Row, Col, Input, Space, Statistic, DatePicker, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { URL } from '../../../Util/Config'
import Highlighter from 'react-highlight-words';
// import x0p from 'x0popup';
// import axios from 'axios';
import DateTime from 'node-datetime';
import moment from 'moment';

const { Text } = Typography;
const { RangePicker } = DatePicker;

const dateFormat = 'YYYY/MM/DD';
var dateOffset30 = DateTime.create();
dateOffset30.offsetInDays(-30)
var dateStart = dateOffset30.format('Y/m/d');
var dateEnd = DateTime.create().format('Y/m/d');

export default class deposit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    title: 'ลับดับ',
                    dataIndex: 'row_num',
                    defaultSortOrder: 'descend',
                    sorter: (a, b) => a.row_num - b.row_num,
                },
                {
                    title: 'สมาชิกผู้ทำรายการ',
                    dataIndex: 'title',
                    ...this.getColumnSearchProps('title'),
                },
                {
                    title: 'จำนวนที่แลกเปลี่ยน',
                    dataIndex: 'log_bonus_amount',
                    render: (text, record) => (
                        <div>โบนัส<Text code><small>{record.log_bonus_amount}</small></Text> -> <Text code><small>{record.log_bonus_credit}</small></Text>เครดิต</div>
                    )
                },
                {
                    title: 'วัน/เวลาที่ทำรายการ',
                    dataIndex: 'created_at',
                }
            ],
            data: [],
            summary: {
                count: 0,
                bonus_amount: 0,
                bonus_credit: 0
            }
        }
    }

    componentDidMount() {
        const user = JSON.parse(localStorage.getItem('user'))
        this.setState({ admin_type: user.user.type, admin_token: user.accessToken })

        this.setTable(dateStart, dateEnd)
    }

    setTable(startDate, endDate) {
        // console.log(startDate, endDate)
        var dt = DateTime.create(startDate);
        startDate = dt.format('Y-m-d');
        dt = DateTime.create(endDate);
        endDate = dt.format('Y-m-d');

        this.getDataLog(startDate, endDate)
    }

    getDataLog(startDate, endDate) {
        fetch(`${URL}/api/v1/bonus/log/start/${startDate}/end/${endDate}`)
            .then((response) => response.json())
            .then((json) => {
                console.log(json)
                this.setState({ data: json.result.list, summary: json.result.summary })
            })
            // .then((json) => console.log(json.result))
            .catch((error) => {
                console.log('fetch log failed', error);
            });
    }

    // ------------------------------------------------------------------ FILTER ----------------------------------------------------------- 
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            this.setState({
                                searchText: selectedKeys[0],
                                searchedColumn: dataIndex,
                            });
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    // ------------------------------------------------------------------ FILTER ----------------------------------------------------------- 

    render() {
        return (
            <>
                <Card>
                    <h5>ประวัติการรับรางวัล</h5>
                    <RangePicker
                        style={{ width: '100%', marginBottom: 10, marginTop: 10 }}
                        defaultValue={[moment(dateStart, dateFormat), moment(dateEnd, dateFormat)]}
                        format={dateFormat}
                        onChange={(e) => this.setTable(e[0]._d, e[1]._d)} />
                    <br />
                    <Row gutter={[16, 16]}>
                        <Col flex="1 1 250px">
                            <Card>
                                <Statistic
                                    title={'จำนวนทั้งหมด'}
                                    value={this.state.summary.count}
                                    valueStyle={{ color: '#531CAA' }}
                                    suffix={'ครั้ง'}
                                />
                            </Card>
                        </Col>
                        <Col flex="1 1 250px">
                            <Card>
                                <Statistic
                                    title={'แจกโบนัส->เครดิตทั้งหมด'}
                                    value={`${this.state.summary.bonus_amount} / ${this.state.summary.bonus_credit}`}
                                    valueStyle={{ color: '#531CAA' }}
                                />
                            </Card>
                        </Col>
                    </Row>
                    <br />
                    <Table columns={this.state.columns} dataSource={this.state.data} pagination={{ pageSize: 20 }} scroll={{ x: 1000 }} rowKey={record => record.row_num} />
                </Card>
            </>
        )
    }
}