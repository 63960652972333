import React, { Component } from 'react';
import { Table, Card, Input, Tag, Button, Form, Col, Row, Modal, message, PageHeader, Checkbox, Typography, Tabs, Tooltip, Switch, Radio } from 'antd';
import { PlusOutlined, EditOutlined, AuditOutlined, DollarCircleOutlined, SketchOutlined, ShopOutlined, FileSearchOutlined } from '@ant-design/icons';
import axios from 'axios';
import { URL, WEB_NAME } from '../../../Util/Config'
import x0p from 'x0popup';
import GOOGLE2FA from './google2fa';
import LogCredit from './history/credit'
import LogPoint from './history/point'
import LogPromotion from './history/promotion'
import LogMember from './history/member'
import LogOther from './history/other'

// const { Option } = Select;
const { Title } = Typography;

export default class user extends Component {
  constructor(props) {
    super(props);
    this.state = {
      admin_username_add: '',
      admin_password_add: '',
      admin_fullname_add: '',
      admin_rule_add: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
      admin_type_add: 'S',
      admin_id_edit: '',
      admin_username_edit: '',
      admin_password_edit: '',
      admin_fullname_edit: '',
      admin_rule_edit: [],
      admin_type_edit: '',
      isModalAddVisible: false,
      isModalEditVisible: false,
      isModalVisibleHistory: false,
      columns: [
        {
          title: 'ลำดับ',
          dataIndex: 'row_num',
        },
        {
          title: 'username',
          dataIndex: 'admin_username',
        },
        {
          title: 'ชื่อแอดมิน',
          dataIndex: 'admin_fullname',
          width: 150,
        },
        {
          title: 'สถานะ',
          dataIndex: 'admin_status',
          render: (text, record) => (
            text === '0' ? <Button style={{ backgroundColor: '#76D7C4' }} shape="circle" disabled>ปกติ</Button> : <Button style={{ backgroundColor: '#F1948A' }} shape="circle" disabled>ระงับ</Button>
          ),
        },
        {
          title: 'ประเภท',
          dataIndex: 'admin_type',
          render: (text, record) => (
            text === 'G' ?
              <Tooltip placement="top" title={'เติมเครดิตให้สมาชิกไม่ได้'}>
                <Tag color="cyan">แอดมินทั่วไป</Tag>
              </Tooltip>
              : text === 'S' ?
                <Tooltip placement="top" title={'เติมเครดิตให้สมาชิกได้'}>
                  <Tag color="geekblue">แอดมินระดับสูง</Tag>
                </Tooltip>
                :
                <Tag color="red">ผู้จัดการระบบ</Tag>
          )
        },
        {
          title: 'วัน/เวลาที่เข้าสู่ระบบล่าสุด',
          dataIndex: 'updated_at',
          render: (text, record) => (
            <p>{text}</p>
          )
        },
        {
          title: '',
          dataIndex: '',
          width: 200,
          render: (text, record) => (
            this.state.admin_type === 'M' ?
              <>
                <div><Button style={{ marginRight: 10 }} onClick={() => this.setState({ isModalVisibleHistory: true, admin_id: record.admin_id })}><AuditOutlined /> ประวัติการทำรายการ</Button></div>
                <br />
                {record.admin_type !== 'M' ?
                  <Button style={{ marginRight: 10 }} onClick={() => this.setState({ isModalEditVisible: true, admin_id_edit: record.admin_id, admin_status_edit: record.admin_status, admin_username_edit: record.admin_username, admin_fullname_edit: record.admin_fullname, admin_type_edit: record.admin_type, admin_rule_edit: record.admin_rule.split(',') })}><EditOutlined /> แก้ไข</Button>
                  : null
                }
                {/* <Button danger type="link" onClick={() => this.removeAdmin(record)}><DeleteOutlined /> ระงับ</Button> */}
              </>
              :
              <></>
          )
        },
      ],
      data: [],
      admin_type: '',
      admin_token: null,
      admin_id: '',
      ruleCheckbox: [
        { label: 'ยืนยันถอน', value: '1' },
        { label: 'ยืนยันฝาก', value: '2' },
        { label: 'ประวัติทุกอย่าง', value: '3' },
        { label: 'รายชื่อสมาชิก', value: '4' },
        { label: 'โปรโมชั่น', value: '5' },
        { label: 'กิจกรรมเสริม', value: '6' },
        { label: 'ประกาศ', value: '7' },
        { label: 'ตั้งค่าหน้าเว็บ', value: '8' },
        { label: 'รายชื่อ Admin', value: '9' },
        { label: 'สรุปรายได้', value: '11' },
        { label: 'พันธมิตร', value: '12' },
        { label: 'เกม', value: '13' }
      ]
    };
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem('user'))
    this.setState({ admin_type: user.user.type, admin_token: user.accessToken }, () => {
      this.getData()
    })
  }

  getData() {
    fetch(`${URL}/api/v1/admin/all`, {
      headers: {
        'Authorization': `Bearer ${this.state.admin_token}`,
      }
    })
      .then((response) => response.json())
      .then((json) => this.setState({ data: json.result }))
      // .then(() => console.log(this.state.data))
      .catch((error) => {
        console.log('fetch data failed', error);
      });
  }

  async addAdmin() {
    const { admin_fullname_add, admin_username_add, admin_password_add, admin_type_add, admin_rule_add } = this.state

    if (admin_fullname_add !== '' && admin_username_add !== '' && admin_password_add !== '' && admin_type_add !== '' && admin_password_add.length >= 6) {
      x0p({
        title: 'ยืนยันการเพิ่มแอดมิน!!',
        icon: 'info',
        buttons: [
          {
            type: 'cancel',
            text: 'ยกเลิก',
          },
          {
            type: 'info',
            text: 'ยืนยัน',
            showLoading: true
          }
        ]
      }).then(async (alert) => {
        if (alert.button === 'info') {
          const body = {
            username: admin_username_add,
            password: admin_password_add,
            name: admin_fullname_add,
            type: admin_type_add,
            rule: admin_rule_add.toString()
          }
          // console.log(body)
          await axios.post(URL + "/api/v1/admin/add", body, {
            headers: {
              'Authorization': `Bearer ${this.state.admin_token}`,
            }
          })
            .then(res => {
              // console.log(res.data)
              var data = res.data
              if (data.status) {
                this.getData()
                x0p('ทำรายการสำเร็จ!!', null, 'ok', false);
              } else {
                x0p('', data.message, 'error', false);
              }
            })
            .catch(error => {
              console.log(error)
              x0p('การเชื่อมต่อผิดพลาด addAdmin', null, 'error', false);
            })
        }
      })
    } else {
      message.error('กรุณาตรวจสอบข้อมูลก่อนกดยืนยัน');
    }
  }

  async updateAdmin() {
    const { admin_id_edit, admin_status_edit, admin_fullname_edit, admin_password_edit, admin_rule_edit, admin_type_edit } = this.state

    if (admin_fullname_edit !== '') {
      x0p({
        title: 'ยืนยันการเปลี่ยนแปลงข้อมูล!!',
        icon: 'info',
        buttons: [
          {
            type: 'cancel',
            text: 'ยกเลิก',
          },
          {
            type: 'info',
            text: 'ยืนยัน',
            showLoading: true
          }
        ]
      }).then(async (alert) => {
        if (alert.button === 'info') {
          const body = {
            admin_id: admin_id_edit,
            status: admin_status_edit,
            new_password: admin_password_edit,
            fullname: admin_fullname_edit,
            rule: admin_rule_edit.toString(),
            type: admin_type_edit
          }
          await axios.post(URL + "/api/v1/admin/update", body, {
            headers: {
              'Authorization': `Bearer ${this.state.admin_token}`,
            }
          })
            .then(res => {
              var data = res.data
              // console.log(data)
              if (data.status) {
                this.getData()
                x0p('ทำรายการสำเร็จ!!', null, 'ok', false);
                this.setState({ isModalEditVisible: false })
              } else {
                x0p('', data.message, 'error', false);
              }
            })
            .catch(error => {
              console.log(error)
              x0p('การเชื่อมต่อผิดพลาด updateAdmin', null, 'error', false);
            })
        }
      })
    } else {
      message.error('กรุณาตรวจสอบข้อมูลก่อนกดยืนยัน');
    }
  }

  async removeAdmin(value) {
    x0p({
      title: `ยืนยันการลบแอดมิน ${value.admin_username}`,
      icon: 'info',
      buttons: [
        {
          type: 'cancel',
          text: 'ยกเลิก',
        },
        {
          type: 'info',
          text: 'ยืนยัน',
          showLoading: true
        }
      ]
    }).then(async (alert) => {
      if (alert.button === 'info') {
        fetch(`${URL}/api/v1/admin/remove/${value.admin_id}`, {
          headers: {
            'Authorization': `Bearer ${this.state.admin_token}`,
          }
        })
          .then((response) => response.json())
          .then((json) => {
            if (json.status) {
              this.getData()
              x0p('ทำรายการสำเร็จ!!', null, 'ok', false);

            } else {
              console.log(json)
              x0p('การเชื่อมต่อผิดพลาด removeAdmin', null, 'error', false);
            }
          })
          .catch((error) => {
            console.log('fetch data failed', error);
            x0p('การเชื่อมต่อผิดพลาด', null, 'error', false);
          });
      }
    })
  }

  render() {
    const routes = [
      {
        breadcrumbName: WEB_NAME,
      },
      {
        breadcrumbName: 'บันทึก STAFF',
      },
      {
        breadcrumbName: 'รายการแอดมินทั้งหมด',
      },
    ]
    return (
      <>
        <PageHeader
          className="site-page-header"
          title="รายการแอดมินทั้งหมด"
          breadcrumb={{ routes }}
        // subTitle="This is a subtitle"
        />

        <Card>
          {this.state.admin_type === 'M' ?
            <Row style={{ marginBottom: 20 }}>
              <Col span={24} style={{ textAlign: 'right' }}>
                <Button type="primary" icon={<PlusOutlined />} onClick={() => this.setState({ isModalAddVisible: true })}>เพิ่มแอดมิน</Button>
              </Col>
            </Row>
            : <></>
          }
          <Table columns={this.state.columns} dataSource={this.state.data} pagination={{ pageSize: 5 }} scroll={{ x: 1000 }} rowKey={record => record.row_num} />
        </Card>

        {/* Modal เพิ่มแอดมิน */}
        <Modal title="เพิ่มแอดมิน" open={this.state.isModalAddVisible} onOk={() => this.addAdmin()} onCancel={() => this.setState({ isModalAddVisible: false })}>
          <Form layout="vertical">
            <Form.Item label="username" required>
              <Input onChange={(e) => this.setState({ admin_username_add: e.target.value })} />
            </Form.Item>
            <Form.Item label="password" required help={'รหัสต้องมากกว่า 6 ตัวอักษร'}>
              <Input.Password onChange={(e) => this.setState({ admin_password_add: e.target.value })} />
            </Form.Item>
            <br />
            <Form.Item label="ชื่อแอดมิน" required>
              <Input onChange={(e) => this.setState({ admin_fullname_add: e.target.value })} />
            </Form.Item>
            <Form.Item label="การอนุญาติ" required>
              <Radio.Group onChange={(e) => this.setState({ admin_type_add: e.target.value })} value={this.state.admin_type_add}>
                <Radio value={'S'}>แก้ไขได้ (เติมเครดิต อนุมัติ ฝาก-ถอน)</Radio>
                <Radio value={'G'}>ดูอย่างเดียว</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="เมนูที่เข้าถึง">
              <Checkbox.Group value={this.state.admin_rule_add} options={this.state.ruleCheckbox} onChange={(value) => this.setState({ admin_rule_add: value })} />
            </Form.Item>
          </Form>
        </Modal>

        {/* Modal แก้ไขแอดมิน */}
        <Modal title="แก้ไขแอดมิน" open={this.state.isModalEditVisible} onOk={() => this.updateAdmin()} onCancel={() => this.setState({ isModalEditVisible: false })}>
          <Form layout="vertical">
            <Form.Item label="สถานะ">
              <Switch checkedChildren="เปิด" unCheckedChildren="ระงับ" checked={this.state.admin_status_edit === '0' ? true : false} onChange={(val) => this.setState({ admin_status_edit: val ? '0' : '1' })} />
            </Form.Item>
            <Form.Item label="username" required>
              <Input value={this.state.admin_username_edit} disabled />
            </Form.Item>
            <Form.Item label="password" extra="หากไม่ต้องการเปลี่ยนให้ใส่ค่าว่าง" required>
              <Input.Password
                value={this.state.admin_password_edit}
                onChange={(e) => this.setState({ admin_password_edit: e.target.value })}
                placeholder="แก้ไขรหัสผ่าน"
              />
            </Form.Item>
            <Form.Item label="ชื่อแอดมิน" required>
              <Input value={this.state.admin_fullname_edit} onChange={(e) => this.setState({ admin_fullname_edit: e.target.value })} />
            </Form.Item>
            <Form.Item label="การอนุญาติ" required>
              <Radio.Group onChange={(e) => this.setState({ admin_type_edit: e.target.value })} value={this.state.admin_type_edit}>
                <Radio value={'S'}>แก้ไขได้ (เติมเครดิต อนุมัติ ฝาก-ถอน)</Radio>
                <Radio value={'G'}>ดูอย่างเดียว</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="เมนูที่เข้าถึง">
              <Checkbox.Group value={this.state.admin_rule_edit} options={this.state.ruleCheckbox} onChange={(value) => this.setState({ admin_rule_edit: value })} />
            </Form.Item>
          </Form>
        </Modal>

        {/* Modal ประวัติการทำรายการ */}
        {this.state.isModalVisibleHistory ?
          <Modal
            title={<><Title level={5}><AuditOutlined /> ประวัติการทำรายการ</Title></>}
            style={{ top: 20, minWidth: 1050 }}
            open={this.state.isModalVisibleHistory}
            footer={null}
            onCancel={() => this.setState({ isModalVisibleHistory: false })}
          >
            <Tabs type="card">
              <Tabs.TabPane tab={<><DollarCircleOutlined />ประวัติการเงิน</>} key="1">
                <LogCredit admin_id={this.state.admin_id} />
              </Tabs.TabPane>
              <Tabs.TabPane tab={<><SketchOutlined />ประวัติแจกเพชร</>} key="2">
                <LogPoint admin_id={this.state.admin_id} />
              </Tabs.TabPane>
              <Tabs.TabPane tab={<><EditOutlined />แก้ไขข้อมูลสมาชิก</>} key="3">
                <LogMember admin_id={this.state.admin_id} />
              </Tabs.TabPane>
              <Tabs.TabPane tab={<><ShopOutlined />ประวัติการจัดการโปรฯ และการแจกโปรฯ</>} key="4">
                <LogPromotion admin_id={this.state.admin_id} />
              </Tabs.TabPane>
              <Tabs.TabPane tab={<><FileSearchOutlined />อื่นๆ</>} key="5">
                <LogOther admin_id={this.state.admin_id} />
              </Tabs.TabPane>
            </Tabs>
          </Modal>
          : null
        }

        <br />

        {this.state.admin_type === 'M' ?
          <GOOGLE2FA />
          : null
        }
      </>
    );
  }
}
