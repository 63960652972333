import React, { Component } from 'react'
import { Tabs, PageHeader } from 'antd';
import { WEB_NAME } from '../../../../Util/Config'
import Turnover from './turnover';

export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    render() {
        const routes = [
            {
                breadcrumbName: WEB_NAME,
            },
            {
                breadcrumbName: 'โปรโมชั่น',
            },
            {
                breadcrumbName: 'คืนยอดเล่นสะสม',
            },
        ]
        return (
            <>
                <PageHeader
                    className="site-page-header"
                    title="คืนยอดเล่นสะสม"
                    breadcrumb={{ routes }}
                />

                <Tabs defaultActiveKey="1" type="card">
                    <Tabs.TabPane tab="คืนยอดเล่นสะสม" key="1">
                        <Turnover />
                    </Tabs.TabPane>
                </Tabs>
            </>
        )
    }
}
